var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) if (e.indexOf(p[i]) < 0)
            t[p[i]] = s[p[i]];
    return t;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spread = (this && this.__spread) || function () {
    for (var ar = [], i = 0; i < arguments.length; i++) ar = ar.concat(__read(arguments[i]));
    return ar;
};
import * as React from 'react';
import { MDCSliderFoundation } from '@material/slider';
import { componentFactory, FoundationComponent, debounce } from '@rmwc/base';
var SliderRoot = componentFactory({
    displayName: 'SliderRoot',
    classNames: function (props) { return [
        'mdc-slider',
        {
            'mdc-slider--discrete': props.discrete,
            'mdc-slider--display-markers': props.displayMarkers && props.discrete
        }
    ]; },
    consumeProps: ['discrete', 'displayMarkers']
});
var SliderTrack = /** @class */ (function (_super) {
    __extends(SliderTrack, _super);
    function SliderTrack() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    SliderTrack.prototype.shouldComponentUpdate = function () {
        return false;
    };
    SliderTrack.prototype.render = function () {
        return React.createElement("div", { ref: this.props.elementRef, className: "mdc-slider__track" });
    };
    SliderTrack.displayName = 'SliderTrack';
    return SliderTrack;
}(React.Component));
var SliderTrackMarkerContainer = /** @class */ (function (_super) {
    __extends(SliderTrackMarkerContainer, _super);
    function SliderTrackMarkerContainer() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    SliderTrackMarkerContainer.prototype.render = function () {
        return (React.createElement("div", { className: "mdc-slider__track-marker-container" }, __spread(Array(this.props.markersCount)).map(function (v, i) { return (React.createElement("div", { className: "mdc-slider__track-marker", key: i })); })));
    };
    SliderTrackMarkerContainer.displayName = 'SliderTrackMarkerContainer';
    return SliderTrackMarkerContainer;
}(React.PureComponent));
var SliderPin = /** @class */ (function (_super) {
    __extends(SliderPin, _super);
    function SliderPin() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    SliderPin.prototype.render = function () {
        var value = this.props.value;
        return (React.createElement("div", { className: "mdc-slider__pin" },
            React.createElement("span", { className: "mdc-slider__pin-value-marker" }, value)));
    };
    SliderPin.displayName = 'SliderPin';
    return SliderPin;
}(React.PureComponent));
var SliderThumb = /** @class */ (function (_super) {
    __extends(SliderThumb, _super);
    function SliderThumb() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    SliderThumb.prototype.shouldComponentUpdate = function () {
        return false;
    };
    SliderThumb.prototype.render = function () {
        return (React.createElement("svg", { className: "mdc-slider__thumb", width: "21", height: "21" },
            React.createElement("circle", { cx: "10.5", cy: "10.5", r: "7.875" })));
    };
    SliderThumb.displayName = 'SliderThumb';
    return SliderThumb;
}(React.Component));
var SliderFocusRing = /** @class */ (function (_super) {
    __extends(SliderFocusRing, _super);
    function SliderFocusRing() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    SliderFocusRing.prototype.shouldComponentUpdate = function () {
        return false;
    };
    SliderFocusRing.prototype.render = function () {
        return React.createElement("div", { className: "mdc-slider__focus-ring" });
    };
    SliderFocusRing.displayName = 'SliderFocusRing';
    return SliderFocusRing;
}(React.Component));
/** A Slider component. */
var Slider = /** @class */ (function (_super) {
    __extends(Slider, _super);
    function Slider() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.state = {
            trackMarkersCount: 0,
            pinContainerStyle: {}
        };
        _this.root = _this.createElement('root');
        _this.thumbContainer = _this.createElement('thumbContainer');
        _this.sliderPin = _this.createElement('sliderPin');
        _this.track = null;
        return _this;
    }
    Slider.prototype.componentDidMount = function () {
        _super.prototype.componentDidMount.call(this);
        // Fixes an issue where synthetic events were being
        // accessed in the Foundation and causing an error
        var existinghandleDown_ = this.foundation.handleDown_.bind(this.foundation);
        this.foundation.handleDown_ = function (evt) {
            evt.persist();
            existinghandleDown_(evt);
        };
    };
    Object.defineProperty(Slider.prototype, "value", {
        /** @return {number} */
        get: function () {
            return this.foundation.getValue();
        },
        /** @param {number} value */
        set: function (value) {
            this.foundation.setValue(value);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Slider.prototype, "min", {
        /** @return {number} */
        get: function () {
            return this.foundation.getMin();
        },
        /** @param {number} min */
        set: function (min) {
            this.foundation.setMin(min);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Slider.prototype, "max", {
        /** @return {number} */
        get: function () {
            return this.foundation.getMax();
        },
        /** @param {number} max */
        set: function (max) {
            this.foundation.setMax(max);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Slider.prototype, "step", {
        /** @return {number} */
        get: function () {
            return this.foundation.getStep();
        },
        /** @param {number} step */
        set: function (step) {
            this.foundation.setStep(step);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Slider.prototype, "disabled", {
        /** @return {boolean} */
        get: function () {
            return this.foundation.isDisabled();
        },
        /** @param {boolean} disabled */
        set: function (disabled) {
            this.foundation.setDisabled(disabled);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Slider.prototype, "discrete", {
        get: function () {
            return !!(this.foundation && this.foundation.isDiscrete_);
        },
        set: function (isDiscrete) {
            if (this.foundation) {
                this.foundation.isDiscrete_ = isDiscrete;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Slider.prototype, "displayMarkers", {
        get: function () {
            return !!this.foundation && this.foundation.hasTrackMarker_;
        },
        set: function (isDisplayMarkers) {
            if (this.foundation) {
                this.foundation.hasTrackMarker_ = isDisplayMarkers;
            }
        },
        enumerable: true,
        configurable: true
    });
    Slider.prototype.layout = function () {
        this.foundation.layout();
    };
    Slider.prototype.sync = function (props, prevProps) {
        var _this = this;
        // value
        if (props.value !== undefined && props.value !== this.value) {
            this.value = props.value !== undefined ? Number(props.value) : this.value;
        }
        // max
        if (props.max !== undefined && props.max !== this.max) {
            this.max = props.max !== undefined ? +props.max : this.max;
        }
        // min
        if (props.min !== undefined && props.min !== this.min) {
            this.min = props.min !== undefined ? +props.min : this.min;
        }
        // step
        if (props.step !== undefined && props.step !== this.step) {
            this.step = props.step !== undefined ? +props.step : this.step;
        }
        // disabled
        if (props.disabled !== undefined && props.disabled !== this.disabled) {
            this.disabled = !!props.disabled;
        }
        // discrete
        if (props.discrete !== undefined && props.discrete !== this.discrete) {
            this.discrete = !!props.discrete;
        }
        //eslint-disable-next-line eqeqeq
        if (this.discrete && this.foundation && this.foundation.getStep() == 0) {
            this.step = 1;
        }
        // displayMarkers
        if (props.displayMarkers !== undefined &&
            props.displayMarkers !== this.displayMarkers) {
            this.displayMarkers = !!props.displayMarkers;
            window.requestAnimationFrame(function () { return _this.foundation && _this.foundation.setupTrackMarker(); });
        }
    };
    Slider.prototype.getDefaultFoundation = function () {
        var _this = this;
        return new MDCSliderFoundation({
            hasClass: function (className) { return _this.root.hasClass(className); },
            addClass: function (className) { return _this.root.addClass(className); },
            removeClass: function (className) { return _this.root.removeClass(className); },
            getAttribute: function (name) {
                return _this.root.getProp(name);
            },
            setAttribute: debounce(function (name, value) { return _this.root.setProp(name, value); }, 300),
            removeAttribute: function (name) { return _this.root.removeProp(name); },
            computeBoundingRect: function () {
                return _this.root.ref
                    ? _this.root.ref.getBoundingClientRect()
                    : {};
            },
            getTabIndex: function () { return (_this.root.ref ? _this.root.ref.tabIndex : 0); },
            registerInteractionHandler: function (evtType, handler) {
                _this.root.addEventListener(evtType, handler);
            },
            deregisterInteractionHandler: function (evtType, handler) {
                _this.root.removeEventListener(evtType, handler);
            },
            registerThumbContainerInteractionHandler: function (evtType, handler) {
                _this.thumbContainer.addEventListener(evtType, handler);
            },
            deregisterThumbContainerInteractionHandler: function (evtType, handler) {
                _this.thumbContainer.removeEventListener(evtType, handler);
            },
            registerBodyInteractionHandler: function (evtType, handler) {
                document.body && document.body.addEventListener(evtType, handler);
            },
            deregisterBodyInteractionHandler: function (evtType, handler) {
                document.body && document.body.removeEventListener(evtType, handler);
            },
            registerResizeHandler: function (handler) {
                window.addEventListener('resize', handler);
            },
            deregisterResizeHandler: function (handler) {
                window.removeEventListener('resize', handler);
            },
            notifyInput: function () {
                _this.emit('onInput', { value: _this.value });
            },
            notifyChange: function () {
                _this.emit('onChange', { value: _this.value });
            },
            setThumbContainerStyleProperty: function (propertyName, value) {
                _this.thumbContainer.setStyle(propertyName, value);
            },
            setTrackStyleProperty: function (propertyName, value) {
                _this.track && _this.track.style.setProperty(propertyName, value);
            },
            setMarkerValue: function (value) {
                _this.sliderPin.setProp('value', value);
            },
            appendTrackMarkers: function (numMarkers) {
                _this.setState({ trackMarkersCount: numMarkers });
            },
            removeTrackMarkers: function () {
                _this.setState({ trackMarkersCount: 0 });
            },
            setLastTrackMarkersStyleProperty: function (propertyName, value) {
                if (_this.root.ref) {
                    // We remove and append new nodes, thus, the last track marker must be dynamically found.
                    var lastTrackMarker = _this.root.ref.querySelector(MDCSliderFoundation.strings.LAST_TRACK_MARKER_SELECTOR);
                    lastTrackMarker &&
                        lastTrackMarker.style.setProperty(propertyName, value);
                }
            },
            isRTL: function () {
                return !!_this.root.ref && getComputedStyle(_this.root.ref).direction === 'rtl';
            }
        });
    };
    Slider.prototype.render = function () {
        var _this = this;
        var _a = this.props, value = _a.value, min = _a.min, max = _a.max, discrete = _a.discrete, displayMarkers = _a.displayMarkers, step = _a.step, disabled = _a.disabled, onChange = _a.onChange, onInput = _a.onInput, children = _a.children, rest = __rest(_a, ["value", "min", "max", "discrete", "displayMarkers", "step", "disabled", "onChange", "onInput", "children"]);
        var dataStep = step ? { 'data-step': step } : {};
        var tsxValue = value;
        var tsxMax = max;
        if (displayMarkers && !discrete) {
            console.warn("The 'displayMarkers' prop on rmwc Slider will\n        only work in conjunction with the 'discrete' prop");
        }
        return (React.createElement(SliderRoot, __assign({ tabIndex: 0, 
            //eslint-disable-next-line jsx-a11y/role-has-required-aria-props
            role: "slider", "aria-valuemax": tsxMax, "aria-valuenow": tsxValue, "aria-label": "Select Value", ref: this.root.setRef, discrete: discrete, displayMarkers: displayMarkers }, (disabled ? { 'aria-disabled': disabled } : {}), dataStep, this.root.props(rest)),
            React.createElement("div", { className: "mdc-slider__track-container" },
                React.createElement(SliderTrack, { elementRef: function (ref) { return (_this.track = ref); } }),
                displayMarkers && (React.createElement(SliderTrackMarkerContainer, { markersCount: this.state.trackMarkersCount }))),
            React.createElement("div", __assign({}, this.thumbContainer.props({
                className: 'mdc-slider__thumb-container'
            })),
                discrete && React.createElement(SliderPin, __assign({}, this.sliderPin.props({}))),
                React.createElement(SliderThumb, null),
                React.createElement(SliderFocusRing, null)),
            children));
    };
    Slider.displayName = 'Slider';
    return Slider;
}(FoundationComponent));
export { Slider };
