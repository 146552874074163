var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) if (e.indexOf(p[i]) < 0)
            t[p[i]] = s[p[i]];
    return t;
};
import * as React from 'react';
import { MDCChipFoundation } from '@material/chips';
import { FoundationComponent, componentFactory, randomId, deprecationWarning } from '@rmwc/base';
import { withRipple } from '@rmwc/ripple';
import { Icon } from '@rmwc/icon';
var ChipRoot = withRipple({})(componentFactory({
    displayName: 'ChipRoot',
    classNames: function (props) { return [
        'mdc-chip',
        {
            'mdc-chip--selected': props.selected
        }
    ]; },
    consumeProps: ['selected']
}));
/** A Chip component. */
var Chip = /** @class */ (function (_super) {
    __extends(Chip, _super);
    function Chip(props) {
        var _this = _super.call(this, props) || this;
        _this.root = _this.createElement('root');
        _this.id = '';
        _this.checkmarkEl = null;
        _this.handleInteraction = _this.handleInteraction.bind(_this);
        _this.handleTransitionEnd = _this.handleTransitionEnd.bind(_this);
        _this.handleTrailingIconInteraction = _this.handleTrailingIconInteraction.bind(_this);
        return _this;
    }
    Chip.prototype.componentDidMount = function () {
        _super.prototype.componentDidMount.call(this);
        this.id =
            this.root.ref && this.root.ref.id
                ? this.root.ref.id
                : this._reactInternalFiber.key || randomId('chip');
    };
    Chip.prototype.getDefaultFoundation = function () {
        var _this = this;
        return new MDCChipFoundation(
        /** @type {!MDCChipAdapter} */ (Object.assign({
            addClass: function (className) {
                _this.root.addClass(className);
            },
            removeClass: function (className) { return _this.root.removeClass(className); },
            hasClass: function (className) { return _this.root.hasClass(className); },
            addClassToLeadingIcon: function (className) {
                // handled by props
            },
            removeClassFromLeadingIcon: function (className) {
                // handled by props
            },
            eventTargetHasClass: function (target, className) {
                return (_this.root.hasClass(className) ||
                    target.classList.contains(className));
            },
            notifyInteraction: function () {
                return _this.emit('onInteraction', { chipId: _this.id }, true /* shouldBubble */);
            },
            notifySelection: function (selected) {
                return _this.emit('onSelect', { chipId: _this.id, selected: selected }, true /* shouldBubble */);
            },
            notifyTrailingIconInteraction: function () {
                return _this.emit('onTrailingIconInteraction', { chipId: _this.id }, true /* shouldBubble */);
            },
            notifyRemoval: function () {
                return _this.emit('onRemove', { chipId: _this.id, root: _this.root.ref }, true /* shouldBubble */);
            },
            getComputedStyleValue: function (propertyName) {
                return _this.root.ref &&
                    window.getComputedStyle(_this.root.ref).getPropertyValue(propertyName);
            },
            setStyleProperty: function (propertyName, value) {
                _this.root.setStyle(propertyName, value);
            },
            hasLeadingIcon: function () { return !!_this.props.icon; },
            getRootBoundingClientRect: function () {
                return _this.root.ref && _this.root.ref.getBoundingClientRect();
            },
            getCheckmarkBoundingClientRect: function () {
                return _this.checkmarkEl && _this.checkmarkEl.getBoundingClientRect();
            }
        })));
    };
    Chip.prototype.handleInteraction = function (evt) {
        evt.type === 'click' && this.props.onClick && this.props.onClick(evt);
        evt.type === 'keydown' && this.props.onKeyDown && this.props.onKeyDown(evt);
        return this.foundation.handleInteraction(evt);
    };
    Chip.prototype.handleTransitionEnd = function (evt) {
        this.foundation.handleTransitionEnd(evt);
    };
    Chip.prototype.handleTrailingIconInteraction = function (evt) {
        return this.foundation.handleTrailingIconInteraction(evt);
    };
    Chip.prototype.render = function () {
        var _this = this;
        var _a = this.props, onInteraction = _a.onInteraction, onTrailingIconInteraction = _a.onTrailingIconInteraction, onRemove = _a.onRemove, onSelect = _a.onSelect, icon = _a.icon, trailingIcon = _a.trailingIcon, checkmark = _a.checkmark, text = _a.text, label = _a.label, children = _a.children, rest = __rest(_a, ["onInteraction", "onTrailingIconInteraction", "onRemove", "onSelect", "icon", "trailingIcon", "checkmark", "text", "label", "children"]);
        var labelToUse = label;
        if (text) {
            deprecationWarning('Chip `text` is now `label`');
            labelToUse = text;
        }
        return (React.createElement(ChipRoot, __assign({ tabIndex: 0 }, this.root.props(rest), { onClick: this.handleInteraction, onKeyDown: this.handleInteraction, onTransitionEnd: this.handleTransitionEnd, ref: this.root.setRef }),
            !!icon && (React.createElement(ChipIcon, { icon: icon, leading: true, hidden: rest.selected && checkmark })),
            !!checkmark && (React.createElement(ChipCheckmark, { elementRef: function (el) { return (_this.checkmarkEl = el); } })),
            React.createElement("div", { className: "mdc-chip__text" },
                labelToUse,
                children),
            !!trailingIcon && (React.createElement(ChipIcon, { icon: trailingIcon, trailing: true, onClick: this.handleTrailingIconInteraction, onKeyDown: this.handleTrailingIconInteraction }))));
    };
    Chip.displayName = 'Chip';
    return Chip;
}(FoundationComponent));
export { Chip };
/** A checkmark for chip selection and filtering. */
var ChipCheckmark = /** @class */ (function (_super) {
    __extends(ChipCheckmark, _super);
    function ChipCheckmark() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    ChipCheckmark.prototype.shouldComponentUpdate = function () {
        return false;
    };
    ChipCheckmark.prototype.render = function () {
        return (React.createElement("div", { ref: this.props.elementRef, className: "mdc-chip__checkmark" },
            React.createElement("svg", { className: "mdc-chip__checkmark-svg", viewBox: "-2 -3 30 30" },
                React.createElement("path", { className: "mdc-chip__checkmark-path", fill: "none", stroke: "black", d: "M1.73,12.91 8.1,19.28 22.79,4.59" }))));
    };
    return ChipCheckmark;
}(React.Component));
/** Icons inside of a chip. This is an instance of the Icon component. To make the icons interactive, add props tabIndex="0" and role="button". */
var ChipIconRoot = componentFactory({
    displayName: 'ChipIconRoot',
    tag: Icon,
    classNames: function (props) {
        return [
            'mdc-chip__icon',
            {
                'mdc-chip__icon--leading': props.leading,
                'mdc-chip__icon--leading-hidden': props.hidden,
                'mdc-chip__icon--trailing': props.trailing
            }
        ];
    },
    consumeProps: ['trailing', 'leading']
});
export var ChipIcon = function (props) {
    var hasInteractionHandler = Object.keys(props).some(function (p) {
        return p.startsWith('on');
    });
    var trailingProps = props.trailing || hasInteractionHandler
        ? { role: 'button', tabIndex: 0 }
        : {};
    return React.createElement(ChipIconRoot, __assign({}, trailingProps, props));
};
ChipIcon.displayName = 'ChipIcon';
/** A container for multiple chips. */
export var ChipSet = componentFactory({
    displayName: 'ChipSet',
    classNames: function (props) { return [
        'mdc-chip-set',
        {
            'mdc-chip-set--choice': props.choice,
            'mdc-chip-set--filter': props.filter
        }
    ]; },
    consumeProps: ['filter', 'choice']
});
