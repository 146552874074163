var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) if (e.indexOf(p[i]) < 0)
            t[p[i]] = s[p[i]];
    return t;
};
import * as React from 'react';
import { MDCRadioFoundation } from '@material/radio';
import { componentFactory } from '@rmwc/base';
import { withRipple } from '@rmwc/ripple';
import { ToggleableFoundationComponent } from '@rmwc/toggleable';
var RadioRoot = withRipple({ unbounded: true, accent: true })(componentFactory({
    displayName: 'RadioRoot',
    classNames: function (props) { return [
        'mdc-radio',
        { 'mdc-radio--disabled': props.disabled }
    ]; }
}));
var RadioNativeControl = componentFactory({
    displayName: 'RadioNativeControl',
    defaultProps: {
        type: 'radio'
    },
    tag: 'input',
    classNames: ['mdc-radio__native-control']
});
var RadioBackground = /** @class */ (function (_super) {
    __extends(RadioBackground, _super);
    function RadioBackground() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    RadioBackground.prototype.shouldComponentUpdate = function () {
        return false;
    };
    RadioBackground.prototype.render = function () {
        return (React.createElement("div", { className: "mdc-radio__background" },
            React.createElement("div", { className: "mdc-radio__outer-circle" }),
            React.createElement("div", { className: "mdc-radio__inner-circle" })));
    };
    RadioBackground.displayName = 'RadioBackground';
    return RadioBackground;
}(React.Component));
/** A Radio button component. */
var Radio = /** @class */ (function (_super) {
    __extends(Radio, _super);
    function Radio() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.root = _this.createElement('root');
        return _this;
    }
    Radio.prototype.getDefaultFoundation = function () {
        var _this = this;
        return new MDCRadioFoundation({
            addClass: function (className) { return _this.root.addClass(className); },
            removeClass: function (className) { return _this.root.removeClass(className); }
        });
    };
    Radio.prototype.render = function () {
        var _a = this.props, children = _a.children, className = _a.className, label = _a.label, style = _a.style, inputRef = _a.inputRef, rest = __rest(_a, ["children", "className", "label", "style", "inputRef"]);
        var radio = (React.createElement(RadioRoot, __assign({}, this.toggleRootProps, { ref: this.root.setRef }),
            React.createElement(RadioNativeControl, __assign({}, rest, { id: this.id, ref: inputRef })),
            React.createElement(RadioBackground, null)));
        return this.renderToggle(radio);
    };
    Radio.displayName = 'Radio';
    return Radio;
}(ToggleableFoundationComponent));
export { Radio };
