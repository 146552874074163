var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as React from 'react';
import { FoundationComponent, randomId } from '@rmwc/base';
import { FormField } from '@rmwc/formfield';
var ToggleableFoundationComponent = /** @class */ (function (_super) {
    __extends(ToggleableFoundationComponent, _super);
    function ToggleableFoundationComponent() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        // @ts-ignore
        _this.generatedId = randomId(_this.constructor.displayName);
        return _this;
    }
    Object.defineProperty(ToggleableFoundationComponent.prototype, "hasLabel", {
        /** @internal */
        get: function () {
            return this.props.label || this.props.children;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ToggleableFoundationComponent.prototype, "id", {
        /** @internal */
        get: function () {
            return this.props.id || this.generatedId;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ToggleableFoundationComponent.prototype, "toggleRootProps", {
        /** @internal */
        get: function () {
            var _a = this.props, className = _a.className, style = _a.style, disabled = _a.disabled, _b = _a.rootProps, rootProps = _b === void 0 ? {} : _b;
            if (this.hasLabel) {
                // @ts-ignore
                return this.root.props({ disabled: disabled });
            }
            return __assign({}, this.root.props(__assign({ className: className,
                style: style,
                disabled: disabled }, rootProps)));
        },
        enumerable: true,
        configurable: true
    });
    ToggleableFoundationComponent.prototype.renderToggle = function (toggle) {
        /**
         * We have to conditionally wrap our checkbox in a formfield
         * If we have a label
         */
        var _a = this.props, className = _a.className, style = _a.style, rootProps = _a.rootProps, label = _a.label, children = _a.children;
        if (this.hasLabel) {
            return (React.createElement(FormField, __assign({}, rootProps, { className: className, style: style }),
                toggle,
                React.createElement("label", { id: this.id + 'label', htmlFor: this.id },
                    label,
                    children)));
        }
        else {
            return toggle;
        }
    };
    return ToggleableFoundationComponent;
}(FoundationComponent));
export { ToggleableFoundationComponent };
