var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) if (e.indexOf(p[i]) < 0)
            t[p[i]] = s[p[i]];
    return t;
};
import * as React from 'react';
import { MDCMenuSurfaceFoundation, util } from '@material/menu-surface';
import { componentFactory, FoundationComponent, closest } from '@rmwc/base';
var ANCHOR_CORNER_MAP = {
    bottomEnd: 'BOTTOM_END',
    bottomLeft: 'BOTTOM_LEFT',
    bottomRight: 'BOTTOM_RIGHT',
    bottomStart: 'BOTTOM_START',
    topEnd: 'TOP_END',
    topLeft: 'TOP_LEFT',
    topRight: 'TOP_RIGHT',
    topStart: 'TOP_START'
};
var getAnchorCornerFromProp = function (anchorCorner) { return MDCMenuSurfaceFoundation.Corner[ANCHOR_CORNER_MAP[anchorCorner]]; };
/****************************************************************
 * MenuSurface
 ****************************************************************/
var MenuSurfaceRoot = componentFactory({
    displayName: 'MenuSurfaceRoot',
    classNames: function (props) { return [
        'mdc-menu-surface',
        {
            'mdc-menu-surface--fixed': props.fixed
        }
    ]; },
    consumeProps: ['fixed']
});
/** A generic menu component for displaying any type of content. */
var MenuSurface = /** @class */ (function (_super) {
    __extends(MenuSurface, _super);
    function MenuSurface(props) {
        var _this = _super.call(this, props) || this;
        _this.root = _this.createElement('root');
        _this.anchorElement = null;
        _this.previousFocus = null;
        _this.firstFocusableElement = null;
        _this.lastFocusableElement = null;
        _this.hoisted = false;
        _this.handleKeydown = _this.handleKeydown.bind(_this);
        _this.handleBodyClick = _this.handleBodyClick.bind(_this);
        return _this;
    }
    MenuSurface.prototype.componentDidMount = function () {
        if (this.root.ref) {
            var anchor = closest(this.root.ref, "." + MDCMenuSurfaceFoundation.cssClasses.ANCHOR);
            anchor && (this.anchorElement = anchor);
        }
        // this has to be run AFTER we try to get our anchor
        _super.prototype.componentDidMount.call(this);
    };
    MenuSurface.prototype.componentWillUnmount = function () {
        // if we are hoisted, unhoist the
        // component so it gets cleaned up properly
        if (this.hoisted) {
            this.unhoistMenuFromBody();
        }
        _super.prototype.componentWillUnmount.call(this);
    };
    Object.defineProperty(MenuSurface.prototype, "open", {
        get: function () {
            return this.foundation.isOpen();
        },
        set: function (value) {
            if (value && this.foundation && !this.foundation.isOpen()) {
                var focusableElements = this.root.ref
                    ? this.root.ref.querySelectorAll(MDCMenuSurfaceFoundation.strings.FOCUSABLE_ELEMENTS)
                    : [];
                this.firstFocusableElement =
                    focusableElements.length > 0 ? focusableElements[0] : null;
                this.lastFocusableElement =
                    focusableElements.length > 0
                        ? focusableElements[focusableElements.length - 1]
                        : null;
                this.foundation.open();
            }
            else {
                if (this.foundation && this.foundation.isOpen()) {
                    this.foundation.close();
                }
            }
        },
        enumerable: true,
        configurable: true
    });
    MenuSurface.prototype.getDefaultFoundation = function () {
        var _this = this;
        return new MDCMenuSurfaceFoundation(__assign({ addClass: function (className) {
                _this.root.addClass(className);
            }, removeClass: function (className) {
                _this.root.removeClass(className);
            }, hasClass: function (className) {
                return className === 'mdc-menu-surface' ? true : _this.root.hasClass(className);
            }, hasAnchor: function () { return !!_this.anchorElement; }, notifyClose: function () {
                _this.emit('onClose', {});
                _this.deregisterBodyClickListener();
                // an annoying hack... this is the only
                // place to catch the normal close and bodyClick handler
                // and correct it if we still want to be open.
                if (_this.props.open) {
                    _this.open = _this.props.open;
                }
            }, notifyOpen: function () {
                _this.emit('onOpen', {});
                _this.registerBodyClickListener();
            }, isElementInContainer: function (el) {
                return _this.root.ref === el || (!!_this.root.ref && _this.root.ref.contains(el));
            }, isRtl: function () {
                return !!_this.root.ref &&
                    getComputedStyle(_this.root.ref).getPropertyValue('direction') === 'rtl';
            }, setTransformOrigin: function (origin) {
                _this.root.setStyle(util.getTransformPropertyName(window) + "-origin", origin);
            } }, this.getFocusAdapterMethods(), this.getDimensionAdapterMethods()));
    };
    MenuSurface.prototype.getFocusAdapterMethods = function () {
        var _this = this;
        return {
            isFocused: function () { return document.activeElement === _this.root.ref; },
            saveFocus: function () {
                _this.previousFocus = document.activeElement;
            },
            restoreFocus: function () {
                if (_this.root.ref && _this.root.ref.contains(document.activeElement)) {
                    if (_this.previousFocus && _this.previousFocus.focus) {
                        _this.previousFocus.focus();
                    }
                }
            },
            isFirstElementFocused: function () {
                return !!_this.firstFocusableElement &&
                    _this.firstFocusableElement === document.activeElement;
            },
            isLastElementFocused: function () {
                return !!_this.firstFocusableElement &&
                    _this.firstFocusableElement === document.activeElement;
            },
            focusFirstElement: function () {
                return !!_this.firstFocusableElement &&
                    _this.firstFocusableElement.focus &&
                    _this.firstFocusableElement.focus();
            },
            focusLastElement: function () {
                return !!_this.firstFocusableElement &&
                    _this.firstFocusableElement.focus &&
                    _this.firstFocusableElement.focus();
            }
        };
    };
    MenuSurface.prototype.getDimensionAdapterMethods = function () {
        var _this = this;
        return {
            getInnerDimensions: function () {
                return {
                    width: _this.root.ref ? _this.root.ref.offsetWidth : 0,
                    height: _this.root.ref ? _this.root.ref.offsetHeight : 0
                };
            },
            getAnchorDimensions: function () {
                return _this.anchorElement && _this.anchorElement.getBoundingClientRect();
            },
            getWindowDimensions: function () {
                return { width: window.innerWidth, height: window.innerHeight };
            },
            getBodyDimensions: function () {
                return {
                    width: document.body.clientWidth,
                    height: document.body.clientHeight
                };
            },
            getWindowScroll: function () {
                return { x: window.pageXOffset, y: window.pageYOffset };
            },
            setPosition: function (position) {
                _this.root.setStyle('left', position.left !== undefined ? position.left : null);
                _this.root.setStyle('right', position.right !== undefined ? position.right : null);
                _this.root.setStyle('top', position.top !== undefined ? position.top : null);
                _this.root.setStyle('bottom', position.bottom !== undefined ? position.bottom : null);
            },
            setMaxHeight: function (height) {
                _this.root.setStyle('maxHeight', height);
            }
        };
    };
    MenuSurface.prototype.sync = function (props, prevProps) {
        var _this = this;
        // fixed
        this.syncProp(props.fixed, prevProps.fixed, function () {
            _this.foundation.setFixedPosition(!!props.fixed);
        });
        // hoistToBody
        this.syncProp(props.hoistToBody, prevProps.hoistToBody, function () {
            props.hoistToBody ? _this.hoistMenuToBody() : _this.unhoistMenuFromBody();
        });
        // anchorCorner
        var anchorCorner = props.anchorCorner && getAnchorCornerFromProp(props.anchorCorner);
        this.syncProp(anchorCorner, this.foundation.anchorCorner_, function () {
            if (anchorCorner) {
                _this.foundation.setAnchorCorner(anchorCorner);
                _this.foundation.dimensions_ = _this
                    .foundation.adapter_.getInnerDimensions();
                _this.foundation.autoPosition_();
            }
        });
        // open
        this.syncProp(props.open, prevProps.open, function () {
            _this.open = !!props.open;
        });
    };
    MenuSurface.prototype.hoistMenuToBody = function () {
        if (this.root.ref && this.root.ref.parentElement) {
            document.body.appendChild(this.root.ref.parentElement.removeChild(this.root.ref));
            this.hoisted = true;
            this.foundation.setIsHoisted(true);
            // correct layout for open menu
            if (this.props.open) {
                this.foundation.autoPosition_();
            }
        }
    };
    MenuSurface.prototype.unhoistMenuFromBody = function () {
        if (this.anchorElement && this.root.ref) {
            this.anchorElement.appendChild(this.root.ref);
            this.hoisted = false;
            this.foundation.setIsHoisted(false);
        }
    };
    MenuSurface.prototype.setAnchorCorner = function (corner) {
        this.foundation.setAnchorCorner(corner);
    };
    MenuSurface.prototype.registerBodyClickListener = function () {
        document.body.addEventListener('click', this.handleBodyClick);
        document.body.addEventListener('touchstart', this.handleBodyClick);
    };
    MenuSurface.prototype.deregisterBodyClickListener = function () {
        document.body.removeEventListener('click', this.handleBodyClick);
        document.body.removeEventListener('touchstart', this.handleBodyClick);
    };
    MenuSurface.prototype.handleBodyClick = function (evt) {
        this.foundation && this.foundation.handleBodyClick(evt);
    };
    MenuSurface.prototype.handleKeydown = function (evt) {
        this.props.onKeyDown && this.props.onKeyDown(evt);
        this.foundation.handleKeydown(evt);
    };
    MenuSurface.prototype.render = function () {
        var _a = this.props, children = _a.children, open = _a.open, anchorCorner = _a.anchorCorner, onOpen = _a.onOpen, onClose = _a.onClose, hoistToBody = _a.hoistToBody, rest = __rest(_a, ["children", "open", "anchorCorner", "onOpen", "onClose", "hoistToBody"]);
        return (React.createElement(MenuSurfaceRoot, __assign({}, this.root.props(rest), { ref: this.root.setRef, onKeyDown: this.handleKeydown }), children));
    };
    return MenuSurface;
}(FoundationComponent));
export { MenuSurface };
/****************************************************************
 * MenuSurfaceAnchor
 ****************************************************************/
/** A Menu Anchor. When using the anchorCorner prop of Menu, you must set MenuSurfaceAnchors css style position to absolute. */
export var MenuSurfaceAnchor = componentFactory({
    displayName: 'MenuSurfaceAnchor',
    classNames: ['mdc-menu-surface--anchor']
});
