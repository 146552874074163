var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) if (e.indexOf(p[i]) < 0)
            t[p[i]] = s[p[i]];
    return t;
};
import * as React from 'react';
import { MDCFloatingLabelFoundation } from '@material/floating-label';
import { FoundationComponent } from '@rmwc/base';
var FloatingLabel = /** @class */ (function (_super) {
    __extends(FloatingLabel, _super);
    function FloatingLabel() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.root = _this.createElement('root');
        return _this;
    }
    FloatingLabel.prototype.getDefaultFoundation = function () {
        var _this = this;
        return new MDCFloatingLabelFoundation({
            addClass: function (className) { return _this.root.addClass(className); },
            removeClass: function (className) { return _this.root.removeClass(className); },
            getWidth: function () { return (_this.root.ref ? _this.root.ref.scrollWidth : 0); },
            registerInteractionHandler: function (evtType, handler) { return _this.root.addEventListener(evtType, handler); },
            deregisterInteractionHandler: function (evtType, handler) { return _this.root.removeEventListener(evtType, handler); }
        });
    };
    FloatingLabel.prototype.sync = function (props, prevProps) {
        var _this = this;
        // shake
        this.syncProp(props.shake, prevProps.shake, function () {
            _this.foundation.shake(!!props.shake);
        });
        // float
        this.syncProp(props.float, prevProps.float, function () {
            _this.foundation.float(!!props.float);
        });
    };
    FloatingLabel.prototype.getWidth = function () {
        return this.foundation.getWidth();
    };
    FloatingLabel.prototype.render = function () {
        var _a = this.props, shake = _a.shake, float = _a.float, rest = __rest(_a, ["shake", "float"]);
        return (React.createElement("label", __assign({}, this.root.props(__assign({}, rest, { className: 'mdc-floating-label' })), { ref: this.root.setRef })));
    };
    FloatingLabel.displayName = 'FloatingLabel';
    return FloatingLabel;
}(FoundationComponent));
export { FloatingLabel };
