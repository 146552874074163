var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) if (e.indexOf(p[i]) < 0)
            t[p[i]] = s[p[i]];
    return t;
};
var _a;
import * as React from 'react';
import { MDCTabFoundation } from '@material/tab';
import { FoundationComponent, componentFactory, randomId } from '@rmwc/base';
import { Icon } from '@rmwc/icon';
import { withRipple, RippleSurface } from '@rmwc/ripple';
import { withTabBarContext } from './tab-bar-context';
import { TabIndicator } from './tab-indicator';
var TabRoot = withRipple({ surface: false })(componentFactory({
    displayName: 'TabRoot',
    tag: 'button',
    classNames: function (props) { return [
        'mdc-tab',
        {
            'mdc-tab--stacked': props.stacked
        }
    ]; },
    consumeProps: ['stacked']
}));
/** A Tab icon. This is an instance of the Icon component. */
var TabIcon = componentFactory({
    displayName: 'TabIcon',
    tag: Icon,
    classNames: ['mdc-tab__icon']
});
/** A Tab component */
export var Tab = withTabBarContext()((_a = /** @class */ (function (_super) {
        __extends(class_1, _super);
        function class_1(props) {
            var _this = _super.call(this, props) || this;
            _this._id = randomId('tab');
            _this.root = _this.createElement('root');
            _this.tabIndicator = null;
            _this.content = null;
            _this.props.contextApi && _this.props.contextApi.registerTab(_this);
            _this.handleClick = _this.handleClick.bind(_this);
            return _this;
        }
        class_1.prototype.componentWillUnmount = function () {
            this.props.contextApi && this.props.contextApi.unregisterTab(this);
        };
        Object.defineProperty(class_1.prototype, "id", {
            get: function () {
                return this.props.id
                    ? this.props.id
                    : this._reactInternalFiber.key || this._id;
            },
            enumerable: true,
            configurable: true
        });
        class_1.prototype.getDefaultFoundation = function () {
            var _this = this;
            return new MDCTabFoundation(
            /** @type {!MDCTabAdapter} */ ({
                setAttr: function (attr, value) {
                    return _this.root.setProp(attr, value);
                },
                addClass: function (className) { return _this.root.addClass(className); },
                removeClass: function (className) { return _this.root.removeClass(className); },
                hasClass: function (className) { return _this.root.hasClass(className); },
                activateIndicator: function (previousIndicatorClientRect) {
                    return _this.tabIndicator &&
                        _this.tabIndicator.activate(previousIndicatorClientRect);
                },
                deactivateIndicator: function () {
                    return _this.tabIndicator && _this.tabIndicator.deactivate();
                },
                notifyInteracted: function () {
                    var evt = _this.emit('onInteraction', { tabId: _this.id }, true /* bubble */);
                    _this.props.contextApi &&
                        _this.props.contextApi.onTabInteraction(evt);
                },
                getOffsetLeft: function () { return (_this.root.ref ? _this.root.ref.offsetLeft : 0); },
                getOffsetWidth: function () { return (_this.root.ref ? _this.root.ref.offsetWidth : 0); },
                getContentOffsetLeft: function () {
                    return _this.content ? _this.content.offsetLeft : 0;
                },
                getContentOffsetWidth: function () {
                    return _this.content ? _this.content.offsetWidth : 0;
                },
                focus: function () { return _this.root.ref && _this.root.ref.focus(); }
            }));
        };
        class_1.prototype.handleClick = function (evt) {
            this.props.onClick && this.props.onClick(evt);
            this.foundation.handleClick();
        };
        Object.defineProperty(class_1.prototype, "active", {
            get: function () {
                return this.foundation.isActive();
            },
            enumerable: true,
            configurable: true
        });
        Object.defineProperty(class_1.prototype, "focusOnActivate", {
            set: function (focusOnActivate) {
                this.foundation.setFocusOnActivate(focusOnActivate);
            },
            enumerable: true,
            configurable: true
        });
        class_1.prototype.activate = function (computeIndicatorClientRect) {
            this.foundation.activate(computeIndicatorClientRect);
        };
        class_1.prototype.deactivate = function () {
            this.foundation.deactivate();
        };
        class_1.prototype.computeIndicatorClientRect = function () {
            return this.tabIndicator && this.tabIndicator.computeContentClientRect();
        };
        class_1.prototype.computeDimensions = function () {
            return this.foundation.computeDimensions();
        };
        class_1.prototype.focus = function () {
            this.root.ref && this.root.ref.focus();
        };
        class_1.prototype.render = function () {
            var _this = this;
            var _a = this.props, children = _a.children, label = _a.label, icon = _a.icon, stacked = _a.stacked, restrictIndicator = _a.restrictIndicator, contextApi = _a.contextApi, rest = __rest(_a, ["children", "label", "icon", "stacked", "restrictIndicator", "contextApi"]);
            return (React.createElement(TabRoot, __assign({}, this.root.props(rest), { onClick: this.handleClick, stacked: stacked, ref: this.root.setRef, ripple: {
                    surface: false
                } }),
                React.createElement("div", { className: "mdc-tab__content", ref: function (el) { return (_this.content = el); } },
                    !!icon && React.createElement(TabIcon, { icon: icon }),
                    (children !== undefined || label !== undefined) && (React.createElement("span", { className: "mdc-tab__text-label" },
                        label,
                        children)),
                    !!restrictIndicator && (React.createElement(TabIndicator, { ref: function (api) { return (_this.tabIndicator = api); } }))),
                !restrictIndicator && (React.createElement(TabIndicator, { ref: function (api) { return (_this.tabIndicator = api); } })),
                React.createElement(RippleSurface, { className: "mdc-tab__ripple" })));
        };
        return class_1;
    }(FoundationComponent)),
    _a.displayName = 'TabFoundation',
    _a));
Tab.displayName = 'Tab';
