var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) if (e.indexOf(p[i]) < 0)
            t[p[i]] = s[p[i]];
    return t;
};
import * as React from 'react';
import { MDCSwitchFoundation } from '@material/switch';
import { componentFactory, classNames } from '@rmwc/base';
import { withRipple } from '@rmwc/ripple';
import { ToggleableFoundationComponent } from '@rmwc/toggleable';
var SwitchRoot = componentFactory({
    displayName: 'SwitchRoot',
    classNames: ['mdc-switch']
});
var SwitchTrack = /** @class */ (function (_super) {
    __extends(SwitchTrack, _super);
    function SwitchTrack() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    SwitchTrack.prototype.shouldComponentUpdate = function () {
        return false;
    };
    SwitchTrack.prototype.render = function () {
        return React.createElement("div", { className: "mdc-switch__track" });
    };
    SwitchTrack.displayName = 'SwitchTrack';
    return SwitchTrack;
}(React.Component));
var SwitchKnob = /** @class */ (function (_super) {
    __extends(SwitchKnob, _super);
    function SwitchKnob() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    SwitchKnob.prototype.shouldComponentUpdate = function () {
        return false;
    };
    SwitchKnob.prototype.render = function () {
        return React.createElement("div", { className: "mdc-switch__knob" });
    };
    SwitchKnob.displayName = 'SwitchKnob';
    return SwitchKnob;
}(React.Component));
var SwitchThumbUnderlay = withRipple({ unbounded: true, surface: false })(function (_a) {
    var className = _a.className, rest = __rest(_a, ["className"]);
    return (React.createElement("div", __assign({ className: classNames(className, 'mdc-switch__thumb-underlay') }, rest)));
});
var SwitchNativeControl = componentFactory({
    displayName: 'SwitchNativeControl',
    defaultProps: {
        type: 'checkbox'
    },
    tag: 'input',
    classNames: ['mdc-switch__native-control']
});
/** A Switch component. */
var Switch = /** @class */ (function (_super) {
    __extends(Switch, _super);
    function Switch(props) {
        var _this = _super.call(this, props) || this;
        _this.root = _this.createElement('root');
        _this.nativeControl = _this.createElement('nativeControl');
        _this.handleChange = _this.handleChange.bind(_this);
        return _this;
    }
    Switch.prototype.componentDidMount = function () {
        _super.prototype.componentDidMount.call(this);
        this.nativeControl.ref &&
            this.foundation.updateCheckedStyling_(this.nativeControl.ref.checked);
        this.nativeControl.ref &&
            this.foundation.setDisabled(this.nativeControl.ref.disabled);
    };
    Switch.prototype.getDefaultFoundation = function () {
        var _this = this;
        return new MDCSwitchFoundation({
            addClass: function (className) { return _this.root.addClass(className); },
            removeClass: function (className) { return _this.root.removeClass(className); },
            setNativeControlChecked: function (checked) {
                return _this.nativeControl.setProp('checked', checked);
            },
            setNativeControlDisabled: function (disabled) {
                return _this.nativeControl.setProp('disabled', disabled);
            }
        });
    };
    Switch.prototype.handleChange = function (evt) {
        this.foundation.handleChange(evt);
        this.props.onChange && this.props.onChange(evt);
    };
    Switch.prototype.sync = function (props, prevProps) {
        if (props.checked !== undefined &&
            !!prevProps &&
            props.checked !== prevProps.checked) {
            this.foundation.updateCheckedStyling_(props.checked);
        }
        if (props.disabled !== undefined &&
            !!prevProps &&
            props.disabled !== prevProps.disabled) {
            this.foundation.setDisabled(props.disabled);
        }
    };
    Switch.prototype.render = function () {
        var _this = this;
        var _a = this.props, children = _a.children, className = _a.className, label = _a.label, style = _a.style, inputRef = _a.inputRef, rest = __rest(_a, ["children", "className", "label", "style", "inputRef"]);
        var switchTag = (React.createElement(SwitchRoot, __assign({}, this.toggleRootProps),
            React.createElement(SwitchTrack, null),
            React.createElement(SwitchThumbUnderlay, null,
                React.createElement("div", { className: "mdc-switch__thumb" },
                    React.createElement(SwitchNativeControl, __assign({}, rest, { onChange: this.handleChange, id: this.id, ref: function (el) {
                            _this.nativeControl.setRef(el);
                            inputRef && inputRef(el);
                        } })))),
            React.createElement(SwitchKnob, null)));
        return this.renderToggle(switchTag);
    };
    Switch.displayName = 'Switch';
    return Switch;
}(ToggleableFoundationComponent));
export { Switch };
