var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) if (e.indexOf(p[i]) < 0)
            t[p[i]] = s[p[i]];
    return t;
};
import * as React from 'react';
import { MDCIconButtonToggleFoundation } from '@material/icon-button';
import { componentFactory, FoundationComponent, deprecationWarning } from '@rmwc/base';
import { Icon } from '@rmwc/icon';
import { withRipple } from '@rmwc/ripple';
var IconButtonRoot = withRipple({
    surface: false,
    unbounded: true
})(componentFactory({
    displayName: 'IconButtonRoot',
    tag: Icon,
    classNames: function (props) { return [
        'mdc-icon-button',
        {
            'mdc-icon-button--on': props.checked
        }
    ]; },
    defaultProps: {
        role: 'button',
        tabIndex: '0'
    },
    consumeProps: ['checked']
}));
var IconButtonToggleRoot = withRipple({
    surface: false,
    unbounded: true
})(componentFactory({
    displayName: 'IconButtonRoot',
    tag: 'button',
    classNames: function (props) { return [
        'mdc-icon-button',
        {
            'mdc-icon-button--on': props.checked
        }
    ]; },
    defaultProps: {
        role: 'button',
        tabIndex: '0'
    },
    consumeProps: ['checked']
}));
var IconButtonIcon = componentFactory({
    displayName: 'IconButtonIcon',
    tag: Icon,
    classNames: function (props) { return [
        'mdc-icon-button__icon',
        {
            'mdc-icon-button__icon--on': props.on
        }
    ]; },
    consumeProps: ['on']
});
var IconButtonToggle = /** @class */ (function (_super) {
    __extends(IconButtonToggle, _super);
    function IconButtonToggle(props) {
        var _this = _super.call(this, props) || this;
        _this.root = _this.createElement('root');
        _this.handleClick = _this.handleClick.bind(_this);
        return _this;
    }
    Object.defineProperty(IconButtonToggle.prototype, "on", {
        get: function () {
            return this.foundation && this.foundation.isOn();
        },
        set: function (isOn) {
            this.foundation.toggle(isOn);
        },
        enumerable: true,
        configurable: true
    });
    IconButtonToggle.prototype.getDefaultFoundation = function () {
        var _this = this;
        return new MDCIconButtonToggleFoundation({
            addClass: function (className) { return _this.root.addClass(className); },
            removeClass: function (className) { return _this.root.removeClass(className); },
            hasClass: function (className) { return _this.root.hasClass(className); },
            setAttr: function (attrName, attrValue) {
                return _this.root.setProp(attrName, attrValue);
            },
            notifyChange: function (evtData) {
                return _this.emit('onChange', evtData);
            }
        });
    };
    /** Takes into account our checked prop */
    IconButtonToggle.prototype.isOn = function () {
        if (this.props.checked !== undefined) {
            return this.props.checked;
        }
        return this.on;
    };
    IconButtonToggle.prototype.sync = function (nextProps) {
        // checked
        if (nextProps.checked !== undefined && this.on !== nextProps.checked) {
            this.on = !!nextProps.checked;
        }
    };
    IconButtonToggle.prototype.handleClick = function (evt) {
        this.props.onClick && this.props.onClick(evt);
        this.foundation.handleClick();
    };
    IconButtonToggle.prototype.render = function () {
        var _a = this.props, icon = _a.icon, iconOptions = _a.iconOptions, onIcon = _a.onIcon, onIconOptions = _a.onIconOptions, rest = __rest(_a, ["icon", "iconOptions", "onIcon", "onIconOptions"]);
        if (iconOptions || onIconOptions) {
            deprecationWarning('IconButton component props iconOptions and onIconOptions must be passed directly to the icon and onIcon prop. This issue has NOT been automatically fixed for you, please update your code.');
        }
        return (React.createElement(IconButtonToggleRoot, __assign({ "aria-pressed": this.isOn(), "aria-hidden": "true" }, this.root.props(rest), { tag: "button", onClick: this.handleClick }),
            React.createElement(IconButtonIcon, { icon: icon }),
            React.createElement(IconButtonIcon, { icon: onIcon, on: true })));
    };
    IconButtonToggle.displayName = 'IconButton';
    return IconButtonToggle;
}(FoundationComponent));
/** An IconButton component that can also be used as a toggle. */
export var IconButton = function (_a) {
    var rest = __rest(_a, []);
    if (rest.onIcon) {
        return React.createElement(IconButtonToggle, __assign({}, rest));
    }
    return React.createElement(IconButtonRoot, __assign({ "aria-hidden": "true", tag: "button" }, rest));
};
IconButton.displayName = 'IconButton';
