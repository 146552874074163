var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) if (e.indexOf(p[i]) < 0)
            t[p[i]] = s[p[i]];
    return t;
};
import * as React from 'react';
import { componentFactory } from '@rmwc/base';
import { Icon } from '@rmwc/icon';
/** The DataTable Component. */
export var DataTable = componentFactory({
    displayName: 'DataTable',
    classNames: function (props) { return [
        'rmwc-data-table',
        {
            'rmwc-data-table--sticky-columns': !!props.stickyColumns,
            'rmwc-data-table--sticky-columns-1': !!props.stickyColumns,
            'rmwc-data-table--sticky-rows': !!props.stickyRows,
            'rmwc-data-table--sticky-rows-1': !!props.stickyRows
        }
    ]; },
    consumeProps: ['stickyColumns', 'stickyRows']
});
/** The data table content. */
export var DataTableContent = componentFactory({
    displayName: 'DataTableContent',
    tag: 'table',
    classNames: ['rmwc-data-table__content']
});
/** A header for the data table. */
export var DataTableHead = componentFactory({
    displayName: 'DataTableHead',
    tag: 'thead',
    classNames: ['rmwc-data-table__head']
});
/** A body for the data table. */
export var DataTableBody = componentFactory({
    displayName: 'DataTableBody',
    tag: 'tbody',
    classNames: ['rmwc-data-table__body']
});
/** A row for the data table. */
export var DataTableRow = componentFactory({
    displayName: 'DataTableRow',
    tag: 'tr',
    classNames: function (props) { return [
        'rmwc-data-table__row',
        {
            'rmwc-data-table__row--selected': props.selected,
            'rmwc-data-table__row--activated': props.activated
        }
    ]; },
    consumeProps: ['activated', 'selected']
});
var DataTableSortIcon = function () { return (React.createElement(Icon, { className: "rmwc-data-table__sort-icon", icon: React.createElement("svg", { width: "18", height: "18", viewBox: "0 0 24 24" },
        React.createElement("path", { fill: "currentColor", d: "M20 12l-1.41-1.41L13 16.17V4h-2v12.17l-5.58-5.59L4 12l8 8 8-8z" })) })); };
var DataTableHeadCellRoot = componentFactory({
    displayName: 'DataTableHeadCellRoot',
    tag: 'th',
    classNames: function (props) { return [
        'rmwc-data-table__cell',
        'rmwc-data-table__head-cell',
        {
            'rmwc-data-table__head-cell--sortable': props.sort !== undefined,
            'rmwc-data-table__head-cell--sorted': !!props.sort,
            'rmwc-data-table__head-cell--sorted-ascending': props.sort === 1,
            'rmwc-data-table__head-cell--sorted-descending': props.sort === -1,
            'rmwc-data-table__cell--align-start': props.alignStart,
            'rmwc-data-table__cell--align-middle': props.alignMiddle,
            'rmwc-data-table__cell--align-end': props.alignEnd
        }
    ]; },
    consumeProps: [
        'alignStart',
        'alignMiddle',
        'alignEnd',
        'sort',
        'onSortChange'
    ]
});
/** A header cell for the data table. */
export var DataTableHeadCell = function (props) {
    var onClickProp = props.onSortChange && props.sort !== undefined
        ? {
            onClick: function (evt) {
                props.onSortChange &&
                    props.onSortChange(props.sort === null ? 1 : props.sort === 1 ? -1 : null);
                props.onClick && props.onClick(evt);
            }
        }
        : {};
    return (React.createElement(DataTableHeadCellRoot, __assign({}, props, onClickProp),
        props.sort !== undefined && React.createElement(DataTableSortIcon, null),
        props.children));
};
DataTableHeadCell.displayName = 'DataTableHeadCell';
/** A cell for the DataTable */
export var DataTableCell = componentFactory({
    displayName: 'DataTableCell',
    tag: 'td',
    classNames: function (props) { return [
        'rmwc-data-table__cell',
        {
            'rmwc-data-table__cell--align-start': props.alignStart,
            'rmwc-data-table__cell--align-middle': props.alignMiddle,
            'rmwc-data-table__cell--align-end': props.alignEnd
        }
    ]; },
    consumeProps: ['alignStart', 'alignMiddle', 'alignEnd']
});
/** A simple data table to render matrices. */
var SimpleDataTable = /** @class */ (function (_super) {
    __extends(SimpleDataTable, _super);
    function SimpleDataTable() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    SimpleDataTable.prototype.render = function () {
        var _a = this.props, data = _a.data, headers = _a.headers, _b = _a.getRowProps, getRowProps = _b === void 0 ? function (row, index, isHead) { return ({}); } : _b, _c = _a.getCellProps, getCellProps = _c === void 0 ? function (row, index, isHead) { return ({}); } : _c, rest = __rest(_a, ["data", "headers", "getRowProps", "getCellProps"]);
        return (React.createElement(DataTable, __assign({}, rest),
            React.createElement(DataTableContent, null,
                !!headers && (React.createElement(DataTableHead, null, headers.map(function (row, i) { return (React.createElement(DataTableRow, __assign({ key: i }, getRowProps(row, i, true)), row.map(function (cell, j) { return (React.createElement(DataTableHeadCell, __assign({ key: j }, getCellProps(cell, j, true)), cell)); }))); }))),
                React.createElement(DataTableBody, null, data.map(function (row, i) { return (React.createElement(DataTableRow, __assign({ key: i }, getRowProps(row, i, false)), row.map(function (cell, j) { return (React.createElement(DataTableCell, __assign({ key: j }, getCellProps(cell, j, false)), cell)); }))); })))));
    };
    return SimpleDataTable;
}(React.Component));
export { SimpleDataTable };
