import { componentFactory } from '@rmwc/base';
/** The Typography Component */
export var Typography = componentFactory({
    displayName: 'Typography',
    tag: 'span',
    classNames: function (props) {
        var _a;
        return [
            (_a = {},
                _a["mdc-typography--" + props.use] = props.use,
                _a)
        ];
    },
    consumeProps: ['use']
});
