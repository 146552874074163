var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) if (e.indexOf(p[i]) < 0)
            t[p[i]] = s[p[i]];
    return t;
};
import * as React from 'react';
import { Icon } from '@rmwc/icon';
import { componentFactory } from '@rmwc/base';
/** Grid List Component */
export var GridList = componentFactory({
    displayName: 'GridList',
    classNames: function (props) {
        var _a;
        return [
            'mdc-grid-list',
            (_a = {
                    'mdc-grid-list--tile-gutter-1': props.tileGutter1,
                    'mdc-grid-list--header-caption': props.headerCaption,
                    'mdc-grid-list--twoline-caption': props.twolineCaption,
                    'mdc-grid-list--with-icon-align-start': props.withIconAlignStart
                },
                _a["mdc-grid-list--tile-aspect-" + (props.tileAspect || '')] = props.tileAspect,
                _a)
        ];
    },
    defaultProps: {
        tileGutter1: false,
        headerCaption: false,
        twolineCaption: false,
        withIconAlignStart: false,
        tileAspect: '1x1'
    },
    consumeProps: [
        'tileGutter1',
        'headerCaption',
        'twolineCaption',
        'withIconAlignStart',
        'tileAspect'
    ],
    render: function (_a, ref, Tag) {
        var children = _a.children, rest = __rest(_a, ["children"]);
        return (React.createElement(Tag, __assign({}, rest, { ref: ref }),
            React.createElement("ul", { className: "mdc-grid-list__tiles" }, children)));
    }
});
/** The primary content for a Grid tile */
export var GridTilePrimary = componentFactory({
    displayName: 'GridTilePrimary',
    classNames: ['mdc-grid-tile__primary']
});
/** The inner primary content for a Grid tile */
export var GridTilePrimaryContent = componentFactory({
    displayName: 'GridTilePrimaryContent',
    tag: 'img',
    classNames: ['mdc-grid-tile__primary-content']
});
/** A grid tile */
export var GridTile = componentFactory({
    displayName: 'GridTile',
    tag: 'li',
    classNames: ['mdc-grid-tile']
});
/** The secondary content for a Grid tile */
export var GridTileSecondary = componentFactory({
    displayName: 'GridTileSecondary',
    tag: 'span',
    classNames: ['mdc-grid-tile__secondary']
});
/** The icon for a Grid tile. This is an instance of Icon component. */
export var GridTileIcon = componentFactory({
    displayName: 'GridTileIcon',
    tag: Icon,
    classNames: ['mdc-grid-tile__icon']
});
/** The title for a Grid tile */
export var GridTileTitle = componentFactory({
    displayName: 'GridTileTitle',
    tag: 'span',
    classNames: ['mdc-grid-tile__title']
});
/** Supporting Text for the Grid Tile */
export var GridTileTitleSupportText = componentFactory({
    displayName: 'GridTileTitleSupportText',
    tag: 'span',
    classNames: ['mdc-grid-tile__support-text']
});
