var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) if (e.indexOf(p[i]) < 0)
            t[p[i]] = s[p[i]];
    return t;
};
import * as React from 'react';
import { componentFactory, classNames } from '@rmwc/base';
import { withRipple } from '@rmwc/ripple';
import { Icon } from '@rmwc/icon';
/** A ListItem component. */
export var ListItem = withRipple({ surface: false })(componentFactory({
    displayName: 'ListItem',
    defaultProps: {
        tabIndex: 0
    },
    classNames: function (props) { return [
        'mdc-list-item',
        {
            'mdc-list-item--selected': props.selected,
            'mdc-list-item--activated': props.activated,
            'mdc-list-item--disabled': props.disabled
        }
    ]; },
    consumeProps: ['selected', 'activated', 'disabled', 'options'] //options is from the select element
}));
/** Text Wrapper for the ListItem */
export var ListItemText = componentFactory({
    displayName: 'ListItemText',
    tag: 'span',
    classNames: ['mdc-list-item__text']
});
/** Primary Text for the ListItem */
export var ListItemPrimaryText = componentFactory({
    displayName: 'ListItemPrimaryText',
    tag: 'span',
    classNames: ['mdc-list-item__primary-text']
});
/** Secondary text for the ListItem */
export var ListItemSecondaryText = componentFactory({
    displayName: 'ListItemSecondaryText',
    tag: 'span',
    classNames: ['mdc-list-item__secondary-text']
});
/** A graphic / icon for the ListItem */
export var ListItemGraphic = componentFactory({
    displayName: 'ListItemGraphic',
    classNames: ['mdc-list-item__graphic'],
    tag: Icon
});
/** Meta content for the ListItem. This can either by an icon by setting the `icon` prop, or any other kind of content. */
export var ListItemMeta = componentFactory({
    displayName: 'ListItemMeta',
    classNames: ['mdc-list-item__meta'],
    tag: 'div',
    render: function (props, ref, Tag) {
        if (!!props.icon) {
            return React.createElement(Icon, __assign({ ref: ref }, props));
        }
        if (React.isValidElement(props.children)) {
            var children = props.children, rest = __rest(props, ["children"]);
            return React.cloneElement(props.children, __assign({}, rest, props.children.props, { className: classNames(props.className, props.children.props.className) }));
        }
        return React.createElement(Tag, __assign({ ref: ref }, props));
    }
});
/** A container to group ListItems */
export var ListGroup = componentFactory({
    displayName: 'ListGroup',
    classNames: ['mdc-list-group']
});
/** A subheader for the ListGroup */
export var ListGroupSubheader = componentFactory({
    displayName: 'ListGroupSubheader',
    classNames: ['mdc-list-group__subheader']
});
/** A divider for the List */
export var ListDivider = componentFactory({
    displayName: 'ListDivider',
    classNames: ['mdc-list-divider']
});
/** A simple list item template. */
export var SimpleListItem = function (_a) {
    var text = _a.text, secondaryText = _a.secondaryText, graphic = _a.graphic, metaIcon = _a.metaIcon, meta = _a.meta, children = _a.children, rest = __rest(_a, ["text", "secondaryText", "graphic", "metaIcon", "meta", "children"]);
    var primaryTextToRender = text && secondaryText !== undefined ? (React.createElement(ListItemPrimaryText, null, text)) : (text);
    var secondaryTextToRender = secondaryText !== undefined ? (React.createElement(ListItemSecondaryText, null, secondaryText)) : null;
    return (React.createElement(ListItem, __assign({}, rest),
        graphic !== undefined && React.createElement(ListItemGraphic, { icon: graphic }),
        secondaryTextToRender !== null ? (React.createElement(ListItemText, null,
            primaryTextToRender,
            secondaryTextToRender)) : (primaryTextToRender),
        (!!meta || !!metaIcon) && (React.createElement(ListItemMeta, { icon: metaIcon }, meta)),
        children));
};
SimpleListItem.displayName = 'SimpleListItem';
