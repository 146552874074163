var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) if (e.indexOf(p[i]) < 0)
            t[p[i]] = s[p[i]];
    return t;
};
import * as React from 'react';
import { componentFactory } from '@rmwc/base';
var SIZE_MAP = {
    xsmall: 18,
    small: 20,
    medium: 24,
    large: 36,
    xlarge: 48
};
var CircularProgressRoot = componentFactory({
    displayName: 'CircularProgressRoot',
    classNames: function (props) {
        var _a;
        return [
            'rmwc-circular-progress',
            (_a = {},
                _a["rmwc-circular-progress--size-" + props._size] = typeof props._size === 'string',
                _a['rmwc-circular-progress--indeterminate'] = props._progress === undefined,
                _a['rmwc-circular-progress--thickerstroke'] = !!props._size && (SIZE_MAP[props._size] || Number(props._size)) > 36,
                _a)
        ];
    },
    consumeProps: ['size', 'progress']
});
/** A Circular Progress indicator. */
var CircularProgress = /** @class */ (function (_super) {
    __extends(CircularProgress, _super);
    function CircularProgress() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    CircularProgress.prototype.calculateRatio = function (value) {
        var _a = this.props, _b = _a.min, min = _b === void 0 ? 0 : _b, _c = _a.max, max = _c === void 0 ? 1 : _c;
        if (value < min)
            return 0;
        if (value > max)
            return 1;
        return (value - min) / (max - min);
    };
    CircularProgress.prototype.circularStyle = function (size) {
        return this.props.progress !== undefined
            ? {
                strokeDasharray: 2 *
                    Math.PI *
                    (size / 2.4) *
                    this.calculateRatio(this.props.progress) + ", 666.66%"
            }
            : undefined;
    };
    CircularProgress.prototype.render = function () {
        var _a = this.props, max = _a.max, min = _a.min, _b = _a.size, size = _b === void 0 ? 'medium' : _b, progress = _a.progress, rest = __rest(_a, ["max", "min", "size", "progress"]);
        var style = rest.style;
        var _size = SIZE_MAP[size] || Number(size);
        if (!SIZE_MAP[size]) {
            style = __assign({}, rest.style, { fontSize: Number(size) });
        }
        return (React.createElement(CircularProgressRoot, __assign({ "aria-valuenow": progress, "aria-valuemin": min, "aria-valuemax": max, _size: size, _progress: progress }, rest, { style: style }),
            React.createElement("svg", { className: "rmwc-circular-progress__circle", viewBox: "0 0 " + _size + " " + _size },
                React.createElement("circle", { className: "rmwc-circular-progress__path", style: this.circularStyle(_size), cx: _size / 2, cy: _size / 2, r: _size / 2.4 }))));
    };
    CircularProgress.displayName = 'CircularProgress';
    CircularProgress.defaultProps = {
        progress: undefined,
        size: 'medium'
    };
    return CircularProgress;
}(React.Component));
export { CircularProgress };
