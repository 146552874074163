var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) if (e.indexOf(p[i]) < 0)
            t[p[i]] = s[p[i]];
    return t;
};
import * as React from 'react';
import { deprecationWarning } from '@rmwc/base';
import { MDCSelectFoundation, MDCSelectIconFoundation } from '@material/select';
import { componentFactory, FoundationComponent, randomId } from '@rmwc/base';
import { FloatingLabel } from '@rmwc/floating-label';
import { LineRipple } from '@rmwc/line-ripple';
import { Icon } from '@rmwc/icon';
import { NotchedOutline } from '@rmwc/notched-outline';
import { Menu, MenuItem, MenuItems } from '@rmwc/menu';
import { ListGroup, ListGroupSubheader, ListDivider } from '@rmwc/list';
import { withRipple } from '@rmwc/ripple';
/**
 * Takes multiple structures for options and returns [{label: 'label', value: 'value', ...rest}]
 */
var createSelectOptions = function (options) {
    // preformatted array
    if (Array.isArray(options) && options[0] && typeof options[0] === 'object') {
        return options.map(function (opt) {
            if (typeof opt !== 'object') {
                throw new Error("Encountered non object for Select " + opt);
            }
            return __assign({}, opt, { options: createSelectOptions(opt.options) });
        });
    }
    // simple array
    if (Array.isArray(options)) {
        return options.map(function (value) { return ({ value: value, label: value }); });
    }
    // value => label objects
    if (typeof options === 'object') {
        return Object.keys(options).map(function (value) { return ({
            value: value,
            label: options[value]
        }); });
    }
    // default, just return
    return options;
};
var SelectRoot = withRipple()(componentFactory({
    displayName: 'SelectRoot',
    defaultProps: {
        role: 'listbox'
    },
    classNames: function (props) { return [
        'mdc-select',
        {
            'mdc-select--outlined': !!props.outlined,
            'mdc-select--required': !!props.required,
            'mdc-select--invalid': !!props.invalid,
            'mdc-select--with-leading-icon': !!props.icon
        }
    ]; },
    consumeProps: ['outlined', 'icon', 'required', 'invalid']
}));
var SelectDropdownArrow = /** @class */ (function (_super) {
    __extends(SelectDropdownArrow, _super);
    function SelectDropdownArrow() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    SelectDropdownArrow.prototype.shouldComponentUpdate = function () {
        return false;
    };
    SelectDropdownArrow.prototype.render = function () {
        return React.createElement("i", { className: "mdc-select__dropdown-icon" });
    };
    return SelectDropdownArrow;
}(React.Component));
var SelectNativeControl = /** @class */ (function (_super) {
    __extends(SelectNativeControl, _super);
    function SelectNativeControl() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    SelectNativeControl.prototype.render = function () {
        var _a = this.props, selectOptions = _a.selectOptions, _b = _a.placeholder, placeholder = _b === void 0 ? '' : _b, children = _a.children, elementRef = _a.elementRef, rest = __rest(_a, ["selectOptions", "placeholder", "children", "elementRef"]);
        return (React.createElement("select", __assign({ tabIndex: 0 }, rest, { ref: elementRef, className: "mdc-select__native-control " + (rest.className || '') }),
            !this.props.value && !this.props.defaultValue && (React.createElement("option", { value: "", disabled: placeholder === '' }, placeholder)),
            !!selectOptions &&
                selectOptions.map(function (_a, i) {
                    var label = _a.label, options = _a.options, option = __rest(_a, ["label", "options"]);
                    if (options) {
                        return (React.createElement("optgroup", { label: label, key: label }, options.map(function (_a, i) {
                            var label = _a.label, option = __rest(_a, ["label"]);
                            return (React.createElement("option", __assign({ key: label + "-" + option.value }, option, { value: option.value }), label));
                        })));
                    }
                    return (React.createElement("option", __assign({ key: label + "-" + option.value }, option, { value: option.value }), label));
                }),
            children));
    };
    SelectNativeControl.displayName = 'SelectNativeControl';
    return SelectNativeControl;
}(React.Component));
// eslint-disable-next-line
var SelectEnhancedControl = /** @class */ (function (_super) {
    __extends(SelectEnhancedControl, _super);
    function SelectEnhancedControl() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    SelectEnhancedControl.prototype.render = function () {
        var _a = this.props, selectOptions = _a.selectOptions, apiRef = _a.apiRef, selectedIndex = _a.selectedIndex, placeholder = _a.placeholder, children = _a.children, rest = __rest(_a, ["selectOptions", "apiRef", "selectedIndex", "placeholder", "children"]);
        var currentIndex = 0;
        var showPlaceholder = (placeholder !== undefined ||
            (this.props.value === undefined &&
                this.props.defaultValue === undefined)) &&
            currentIndex++ === 0;
        return (React.createElement(Menu, __assign({}, rest, { ref: apiRef, className: "mdc-select__menu" }),
            showPlaceholder && (React.createElement(MenuItem, { selected: currentIndex - 1 === selectedIndex, "data-value": "" }, placeholder)),
            selectOptions.map(function (_a, i) {
                var label = _a.label, options = _a.options, option = __rest(_a, ["label", "options"]);
                if (options) {
                    return (React.createElement(ListGroup, { key: label },
                        React.createElement(ListGroupSubheader, { theme: "textDisabledOnBackground" }, label),
                        React.createElement(MenuItems, null, options.map(function (_a, i) {
                            var label = _a.label, option = __rest(_a, ["label"]);
                            currentIndex += 1;
                            return (React.createElement(MenuItem, __assign({ key: label + "-" + option.value, activated: currentIndex - 1 === selectedIndex }, option, { "data-value": option.value }), label));
                        })),
                        i < selectOptions.length - 1 && React.createElement(ListDivider, null)));
                }
                currentIndex += 1;
                return (React.createElement(MenuItem, __assign({ key: label + "-" + option.value, activated: currentIndex - 1 === selectedIndex }, option, { "data-value": option.value }), label));
            }),
            children));
    };
    return SelectEnhancedControl;
}(React.Component));
var SelectBase = /** @class */ (function (_super) {
    __extends(SelectBase, _super);
    function SelectBase(props) {
        var _this = _super.call(this, props) || this;
        _this.root = _this.createElement('root');
        _this.lineRipple = _this.createElement('lineRipple');
        _this.outline = _this.createElement('outline');
        _this.label = _this.createElement('label');
        _this.id = _this.props.id || randomId('select');
        _this.nativeControl = null;
        _this.selectedText = null;
        _this.menuElement = null;
        _this.menu = null;
        _this.hiddenInput_ = null;
        _this.leadingIcon_ = null;
        _this.trailingIcon_ = null;
        _this.state = {
            selectedIndex: _this.props.placeholder !== undefined ? 0 : -1,
            menuOpen: false,
            selectedTextContent: ''
        };
        _this.handleChange = _this.handleChange.bind(_this);
        _this.handleFocus = _this.handleFocus.bind(_this);
        _this.handleBlur = _this.handleBlur.bind(_this);
        _this.handleClick = _this.handleClick.bind(_this);
        _this.handleKeydown = _this.handleKeydown.bind(_this);
        _this.handleMenuSelected = _this.handleMenuSelected.bind(_this);
        _this.handleMenuOpened = _this.handleMenuOpened.bind(_this);
        _this.handleMenuClosed = _this.handleMenuClosed.bind(_this);
        return _this;
    }
    SelectBase.prototype.componentDidMount = function () {
        _super.prototype.componentDidMount.call(this);
        var _a = this.props, enhanced = _a.enhanced, value = _a.value;
        if (this.menu) {
            this.menuElement =
                this.root.ref && this.root.ref.querySelector('.mdc-select__menu');
            // tried to replace this with the hoistToBody prop and it made the placeholders
            // disappear... likely a timing issue. Leaving this for now
            this.menu.hoistMenuToBody();
            this.root.ref && this.menu.setAnchorElement(this.root.ref);
            //this.menu.wrapFocus = false;
            if (this.hiddenInput_ && this.hiddenInput_.value) {
                // If the hidden input already has a value, use it to restore the select's value.
                // This can happen e.g. if the user goes back or (in some browsers) refreshes the page.
                var enhancedAdapterMethods = this.getEnhancedSelectAdapterMethods_();
                enhancedAdapterMethods.setValue(this.hiddenInput_ ? this.hiddenInput_.value : '');
            }
            else if (enhanced) {
                // If an element is selected, the select should set the initial selected text.
                this.getEnhancedSelectAdapterMethods_().setValue(value || '');
            }
        }
        // Initially sync floating label
        this.foundation.handleChange(false);
        if (this.props.disabled) {
            this.foundation.setDisabled(true);
        }
    };
    SelectBase.prototype.getDefaultFoundation = function () {
        return new MDCSelectFoundation(__assign({}, (this.props.enhanced
            ? this.getEnhancedSelectAdapterMethods_()
            : this.getNativeSelectAdapterMethods_()), this.getCommonAdapterMethods_(), this.getOutlineAdapterMethods_(), this.getLabelAdapterMethods_()), this.getFoundationMap_());
    };
    SelectBase.prototype.getNativeSelectAdapterMethods_ = function () {
        var _this = this;
        return {
            getValue: function () {
                var value = _this.nativeControl && _this.nativeControl.value;
                return value === '' && _this.props.placeholder ? ' ' : value || '';
            },
            setValue: function (value) {
                return _this.nativeControl && (_this.nativeControl.value = value);
            },
            openMenu: function () { },
            closeMenu: function () { },
            isMenuOpen: function () { return false; },
            setSelectedIndex: function (index) {
                _this.nativeControl && (_this.nativeControl.selectedIndex = index);
            },
            setDisabled: function (isDisabled) {
                return _this.nativeControl && (_this.nativeControl.disabled = isDisabled);
            },
            setValid: function (isValid) {
                isValid
                    ? _this.root.removeClass(MDCSelectFoundation.cssClasses.INVALID)
                    : _this.root.addClass(MDCSelectFoundation.cssClasses.INVALID);
            },
            checkValidity: function () {
                return !!_this.nativeControl && _this.nativeControl.checkValidity();
            }
        };
    };
    SelectBase.prototype.getEnhancedSelectAdapterMethods_ = function () {
        var _this = this;
        return {
            getValue: function () {
                var value = '';
                var listItem = _this.menuElement &&
                    _this.menuElement.querySelector('.mdc-list-item--activated');
                if (listItem &&
                    listItem.hasAttribute(MDCSelectFoundation.strings.ENHANCED_VALUE_ATTR)) {
                    value = listItem.getAttribute(MDCSelectFoundation.strings.ENHANCED_VALUE_ATTR);
                }
                return value === '' && _this.props.placeholder ? ' ' : value;
            },
            setValue: function (value) {
                if (_this.menuElement) {
                    var element = _this.menuElement.querySelector("[" + MDCSelectFoundation.strings.ENHANCED_VALUE_ATTR + "=\"" + value + "\"]");
                    var selectedIndex = element && _this.menu
                        ? _this.menu.items.indexOf(element)
                        : -1;
                    var selectedItem = _this.menu && _this.menu.items[selectedIndex];
                    var selectedTextContent_1 = selectedItem && selectedItem.textContent
                        ? selectedItem.textContent.trim()
                        : '';
                    _this.setState({
                        selectedIndex: selectedIndex,
                        selectedTextContent: selectedTextContent_1
                    }, function () {
                        _this.foundation.layout();
                        _this.foundation.adapter_.floatLabel(!!selectedTextContent_1);
                    });
                }
            },
            openMenu: function () {
                _this.setState({
                    menuOpen: true
                });
            },
            closeMenu: function () {
                _this.setState({
                    menuOpen: false
                });
            },
            isMenuOpen: function () { return _this.state.menuOpen; },
            setSelectedIndex: function (index) {
                _this.setState({ selectedIndex: index });
            },
            setDisabled: function (isDisabled) {
                // handled by props in render function
            },
            checkValidity: function () {
                var classList = _this.root.ref && _this.root.ref.classList;
                if (classList &&
                    classList.contains(MDCSelectFoundation.cssClasses.REQUIRED) &&
                    !classList.contains(MDCSelectFoundation.cssClasses.DISABLED)) {
                    // See notes for required attribute under https://www.w3.org/TR/html52/sec-forms.html#the-select-element
                    // TL;DR: Invalid if no index is selected, or if the first index is selected and has an empty value.
                    return (_this.state.selectedIndex !== -1 &&
                        (_this.state.selectedIndex !== 0 || !!_this.value));
                }
                else {
                    return true;
                }
            },
            setValid: function (isValid) {
                _this.selectedText &&
                    _this.selectedText.setAttribute('aria-invalid', (!isValid).toString());
                isValid
                    ? _this.root.removeClass(MDCSelectFoundation.cssClasses.INVALID)
                    : _this.root.addClass(MDCSelectFoundation.cssClasses.INVALID);
            }
        };
    };
    SelectBase.prototype.getCommonAdapterMethods_ = function () {
        var _this = this;
        return {
            addClass: function (className) { return _this.root.addClass(className); },
            removeClass: function (className) { return _this.root.removeClass(className); },
            hasClass: function (className) { return _this.root.hasClass(className); },
            isRtl: function () {
                return _this.root.ref &&
                    window.getComputedStyle(_this.root.ref).getPropertyValue('direction') ===
                        'rtl';
            },
            setRippleCenter: function (normalizedX) {
                return _this.lineRipple.setProp('center', normalizedX);
            },
            activateBottomLine: function () { return _this.lineRipple.setProp('active', true); },
            deactivateBottomLine: function () { return _this.lineRipple.setProp('active', false); },
            notifyChange: function (value) {
                // handled byt the onChange event
            }
        };
    };
    SelectBase.prototype.getOutlineAdapterMethods_ = function () {
        var _this = this;
        return {
            hasOutline: function () { return !!_this.props.outlined; },
            notchOutline: function (labelWidth) {
                _this.outline.setProp('notch', labelWidth);
            },
            closeOutline: function () {
                _this.outline.removeProp('notch');
            }
        };
    };
    SelectBase.prototype.getLabelAdapterMethods_ = function () {
        var _this = this;
        return {
            floatLabel: function (shouldFloat) {
                _this.label.setProp('float', shouldFloat);
            },
            getLabelWidth: function () {
                return _this.label.ref ? _this.label.ref.getWidth() : 0;
            }
        };
    };
    SelectBase.prototype.getFoundationMap_ = function () {
        return {
            leadingIcon: (this.leadingIcon_ && this.leadingIcon_.foundation) || undefined
            // helperText: this.helperText_ ? this.helperText_.foundation : undefined
        };
    };
    SelectBase.prototype.sync = function (props, prevProps) {
        // For controlled selects that are enhanced
        // we need to jump through some checks to see if we need to update the
        // value in our foundation
        if (props.value !== prevProps.value ||
            props.options !== prevProps.options ||
            JSON.stringify(props.options) !== JSON.stringify(prevProps.options)) {
            this.foundation.setValue(props.value || '');
        }
        if (props.disabled !== undefined &&
            (!prevProps || prevProps.disabled !== props.disabled)) {
            this.foundation.setDisabled(props.disabled);
        }
    };
    Object.defineProperty(SelectBase.prototype, "value", {
        get: function () {
            return this.foundation.getValue();
        },
        /**
         * @param {string} value The value to set on the select.
         */
        set: function (value) {
            this.foundation.setValue(value);
        },
        enumerable: true,
        configurable: true
    });
    SelectBase.prototype.handleChange = function (evt) {
        this.props.onChange && this.props.onChange(evt);
        this.foundation.handleChange(true);
    };
    SelectBase.prototype.handleFocus = function (evt) {
        this.props.onFocus && this.props.onFocus(evt);
        this.foundation.handleFocus();
    };
    SelectBase.prototype.handleBlur = function (evt) {
        this.props.onBlur && this.props.onBlur(evt);
        this.foundation.handleBlur();
    };
    SelectBase.prototype.handleClick = function (evt) {
        var _this = this;
        var _a = this.props, onMouseDown = _a.onMouseDown, onTouchStart = _a.onTouchStart;
        evt.type === 'mousedown' && onMouseDown && onMouseDown(evt);
        evt.type === 'touchstart' && onTouchStart && onTouchStart(evt);
        var getNormalizedXCoordinate = function (evt) {
            var targetClientRect = evt.target.getBoundingClientRect();
            var xCoordinate = evt.clientX;
            return xCoordinate - targetClientRect.left;
        };
        if (this.selectedText)
            this.selectedText.focus();
        // Timeout corrects an issue for firefox not changing the value
        // https://github.com/jamesmfriedman/rmwc/issues/412
        var coord = getNormalizedXCoordinate(evt);
        setTimeout(function () {
            _this.foundation.handleClick(coord);
        });
    };
    SelectBase.prototype.handleKeydown = function (evt) {
        this.props.onKeyDown && this.props.onKeyDown(evt);
        this.foundation.handleKeydown(evt);
    };
    SelectBase.prototype.handleMenuSelected = function (evt) {
        var value = evt.detail.item.dataset.value;
        this.emit('onChange', {
            index: evt.detail.index,
            value: value
        }, true);
        this.props.value === undefined &&
            this.getEnhancedSelectAdapterMethods_().setValue(value || '');
    };
    SelectBase.prototype.handleMenuOpened = function () {
        // Menu should open to the last selected element.
        if (this.menu && this.state.selectedIndex >= 0) {
            this.menu.items[this.state.selectedIndex].focus();
        }
    };
    SelectBase.prototype.handleMenuClosed = function () {
        // menuOpened_ is used to track the state of the menu opening or closing since the menu.open function
        // will return false if the menu is still closing and this method listens to the closed event which
        // occurs after the menu is already closed.
        this.setState({
            menuOpen: false
        });
        if (document.activeElement !== this.selectedText) {
            this.foundation.handleBlur();
        }
    };
    // handle leading and trailing icons
    SelectBase.prototype.renderIcon = function (iconNode, leadOrTrail) {
        var _this = this;
        if ((iconNode && typeof iconNode === 'string') ||
            (iconNode.type && iconNode.type.displayName !== SelectIcon.displayName)) {
            return (React.createElement(SelectIcon, { ref: function (ref) {
                    if (leadOrTrail === 'leadingIcon_') {
                        _this.leadingIcon_ = ref && ref.foundation;
                    }
                    else {
                        _this.trailingIcon_ = ref && ref.foundation;
                    }
                }, tabIndex: leadOrTrail === 'trailingIcon_' ? 0 : undefined, icon: iconNode }));
        }
        return iconNode;
    };
    SelectBase.prototype.renderHelpText = function () {
        var helpText = this.props.helpText;
        var shouldRender = !!helpText;
        if (!shouldRender) {
            return null;
        }
        var shouldSpread = typeof helpText === 'object' && !React.isValidElement(helpText);
        return helpText && shouldSpread ? (React.createElement(SelectHelperText, __assign({}, helpText))) : (React.createElement(SelectHelperText, null, helpText));
    };
    SelectBase.prototype.render = function () {
        var _this = this;
        var _a = this.props, placeholder = _a.placeholder, children = _a.children, value = _a.value, outlined = _a.outlined, _b = _a.label, label = _b === void 0 ? '' : _b, _c = _a.options, options = _c === void 0 ? [] : _c, _d = _a.rootProps, rootProps = _d === void 0 ? {} : _d, className = _a.className, enhanced = _a.enhanced, _icon = _a.icon, _withLeadingIcon = _a.withLeadingIcon, onChange = _a.onChange, onFocus = _a.onFocus, onBlur = _a.onBlur, onKeyDown = _a.onKeyDown, invalid = _a.invalid, inputRef = _a.inputRef, helpText = _a.helpText, rest = __rest(_a, ["placeholder", "children", "value", "outlined", "label", "options", "rootProps", "className", "enhanced", "icon", "withLeadingIcon", "onChange", "onFocus", "onBlur", "onKeyDown", "invalid", "inputRef", "helpText"]);
        var _e = this.props, icon = _e.icon, withLeadingIcon = _e.withLeadingIcon;
        if (withLeadingIcon !== undefined) {
            deprecationWarning("Select prop 'withLeadingIcon' is now 'icon'.");
            icon = withLeadingIcon;
        }
        var selectOptions = createSelectOptions(options);
        var defaultValue = value !== undefined ? undefined : this.props.defaultValue || '';
        var sharedEventProps = {
            onChange: this.handleChange,
            onFocus: this.handleFocus,
            onBlur: this.handleBlur,
            onTouchStart: this.handleClick,
            onMouseDown: this.handleClick
        };
        var sharedControlProps = {
            defaultValue: defaultValue,
            value: value,
            placeholder: placeholder,
            selectOptions: selectOptions
        };
        var renderedLabel = (React.createElement(FloatingLabel, __assign({}, this.label.props({}), { ref: this.label.setRef }), label));
        return (React.createElement(React.Fragment, null,
            React.createElement(SelectRoot, __assign({ ripple: !outlined }, this.root.props(__assign({ className: className }, rootProps)), { invalid: invalid, required: rest.required, icon: icon, outlined: outlined, ref: this.root.setRef }),
                !!icon && this.renderIcon(icon, 'leadingIcon_'),
                React.createElement(SelectDropdownArrow, null),
                enhanced ? (React.createElement(React.Fragment, null,
                    React.createElement("input", { type: "hidden", ref: function (el) { return (_this.hiddenInput_ = el); } }),
                    React.createElement("div", __assign({ ref: function (el) { return (_this.selectedText = el); }, className: "mdc-select__selected-text", tabIndex: this.props.disabled ? -1 : 0, "aria-disabled": this.props.disabled ? 'true' : 'false', "aria-expanded": this.state.menuOpen, onKeyDown: this.handleKeydown }, sharedEventProps), this.state.selectedTextContent),
                    React.createElement(SelectEnhancedControl, __assign({}, sharedControlProps, { selectedIndex: this.state.selectedIndex, apiRef: function (apiRef) {
                            _this.menu = apiRef;
                        }, open: this.state.menuOpen, onClose: this.handleMenuClosed, onOpen: this.handleMenuOpened, onSelect: this.handleMenuSelected }), children))) : (React.createElement(SelectNativeControl, __assign({}, rest, { elementRef: function (el) {
                        _this.nativeControl = el;
                        inputRef && inputRef(el);
                    } }, sharedControlProps, sharedEventProps), children)),
                !!outlined ? (React.createElement(NotchedOutline, __assign({}, this.outline.props({})), renderedLabel)) : (React.createElement(React.Fragment, null,
                    renderedLabel,
                    React.createElement(LineRipple, __assign({}, this.lineRipple.props({})))))),
            this.renderHelpText()));
    };
    return SelectBase;
}(FoundationComponent));
export { SelectBase };
var SelectIcon = /** @class */ (function (_super) {
    __extends(SelectIcon, _super);
    function SelectIcon() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.root = _this.createElement('root');
        return _this;
    }
    SelectIcon.prototype.getDefaultFoundation = function () {
        var _this = this;
        return new MDCSelectIconFoundation({
            getAttr: function (attr) {
                return _this.root.getProp(attr);
            },
            setAttr: function (attr, value) {
                return _this.root.setProp(attr, value);
            },
            removeAttr: function (attr) { return _this.root.removeProp(attr); },
            setContent: function (content) {
                _this.root.ref && (_this.root.ref.textContent = content);
            },
            registerInteractionHandler: function (evtType, handler) { return _this.root.addEventListener(evtType, handler); },
            deregisterInteractionHandler: function (evtType, handler) { return _this.root.removeEventListener(evtType, handler); },
            notifyIconAction: function () { return _this.emit('onClick', {}, true); }
        });
    };
    SelectIcon.prototype.render = function () {
        return (React.createElement(Icon, __assign({}, this.root.props(__assign({}, this.props, { className: 'mdc-select__icon' })))));
    };
    SelectIcon.displayName = 'SelectIcon';
    return SelectIcon;
}(FoundationComponent));
export { SelectIcon };
export var SelectHelperText = componentFactory({
    displayName: 'SelectHelperText',
    tag: 'p',
    classNames: function (props) { return [
        'mdc-select-helper-text',
        {
            'mdc-select-helper-text--persistent': props.persistent,
            'mdc-select-helper-text--validation-msg': props.validationMsg
        }
    ]; },
    consumeProps: ['persistent', 'validationMsg']
});
/** A Select Component */
export var Select = function (_a) {
    var enhanced = _a.enhanced, rest = __rest(_a, ["enhanced"]);
    return (React.createElement(SelectBase, __assign({ key: enhanced ? 'enhanced' : 'native', enhanced: enhanced }, rest)));
};
Select.displayName = 'Select';
