var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) if (e.indexOf(p[i]) < 0)
            t[p[i]] = s[p[i]];
    return t;
};
import * as React from 'react';
import { componentFactory } from '@rmwc/base';
import { Icon } from '@rmwc/icon';
import { withRipple } from '@rmwc/ripple';
var getInitialsForName = function (name) {
    if (name === void 0) { name = ''; }
    if (name) {
        var parts = name.split(' ');
        var letters = (parts[0] || '')[0];
        if (parts.length > 1) {
            var part = parts.pop();
            if (part) {
                letters += part[0];
            }
        }
        return letters;
    }
    return '';
};
var AvatarRoot = withRipple()(componentFactory({
    displayName: 'AvatarRoot',
    classNames: function (props) {
        var _a;
        return [
            'rmwc-avatar',
            (_a = {},
                _a["rmwc-avatar--" + props.size] = props.size,
                _a['rmwc-avatar--count'] = props.isCount,
                _a['rmwc-avatar--interactive'] = props.interactive,
                _a['rmwc-avatar--count-overflow'] = props.overflow,
                _a['rmwc-avatar--smaller-text'] = props.smallerText,
                _a['rmwc-avatar--square'] = props.square,
                _a['rmwc-avatar--has-image'] = props.hasImage,
                _a)
        ];
    },
    tag: Icon,
    consumeProps: [
        'isCount',
        'overflow',
        'smallerText',
        'square',
        'interactive',
        'hasImage'
    ]
}));
/** A container for groups of Avatars */
export var AvatarGroup = componentFactory({
    displayName: 'AvatarGroup',
    classNames: function (props) { return [
        'rmwc-avatar-group',
        {
            'rmwc-avatar-group--dense': props.dense
        }
    ]; },
    consumeProps: ['dense']
});
/** An Avatar component for displaying users in a system. */
export var Avatar = function (_a) {
    var src = _a.src, size = _a.size, _b = _a.name, name = _b === void 0 ? '' : _b, _c = _a.interactive, interactive = _c === void 0 ? false : _c, _d = _a.contain, contain = _d === void 0 ? false : _d, rest = __rest(_a, ["src", "size", "name", "interactive", "contain"]);
    var initials = getInitialsForName(name);
    var avatarStyle = src
        ? {
            backgroundImage: "url(" + src + ")",
            backgroundSize: contain ? 'contain' : 'cover'
        }
        : {};
    return (React.createElement(AvatarRoot, __assign({ ripple: interactive, interactive: interactive, hasImage: !!src, size: size, title: name, tag: 'span' }, rest, { icon: {
            icon: (React.createElement(React.Fragment, null,
                React.createElement("div", { className: "rmwc-avatar__icon", style: avatarStyle }),
                React.createElement("div", { className: "rmwc-avatar__text" },
                    React.createElement("div", { className: "rmwc-avatar__text-inner" }, initials))))
        } })));
};
Avatar.displayName = 'Avatar';
/** An Avatar count for displaying list overflow. */
export var AvatarCount = function (_a) {
    var value = _a.value, overflow = _a.overflow, size = _a.size, _b = _a.interactive, interactive = _b === void 0 ? false : _b, rest = __rest(_a, ["value", "overflow", "size", "interactive"]);
    var smallerText = String(value).length > 2;
    return (React.createElement(AvatarRoot, __assign({}, rest, { ripple: interactive, interactive: interactive, isCount: true, size: size, overflow: overflow, smallerText: smallerText, tag: 'span' }, rest, { icon: {
            icon: (React.createElement(React.Fragment, null,
                React.createElement("div", { className: "rmwc-avatar__text" },
                    React.createElement("div", { className: "rmwc-avatar__text-inner" }, value))))
        } })));
};
AvatarCount.displayName = 'AvatarCount';
