var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) if (e.indexOf(p[i]) < 0)
            t[p[i]] = s[p[i]];
    return t;
};
import * as React from 'react';
import { MDCLineRippleFoundation } from '@material/line-ripple';
import { FoundationComponent } from '@rmwc/base';
var LineRipple = /** @class */ (function (_super) {
    __extends(LineRipple, _super);
    function LineRipple() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.root = _this.createElement('root');
        return _this;
    }
    LineRipple.prototype.getDefaultFoundation = function () {
        var _this = this;
        return new MDCLineRippleFoundation({
            addClass: function (className) { return _this.root.addClass(className); },
            removeClass: function (className) { return _this.root.removeClass(className); },
            hasClass: function (className) { return _this.root.hasClass(className); },
            setStyle: function (propertyName, value) {
                return _this.root.setStyle(propertyName, value);
            },
            registerEventHandler: function (evtType, handler) { return _this.root.addEventListener(evtType, handler); },
            deregisterEventHandler: function (evtType, handler) { return _this.root.removeEventListener(evtType, handler); }
        });
    };
    LineRipple.prototype.sync = function (props, prevProps) {
        var _this = this;
        // active
        this.syncProp(props.active, prevProps.active, function () {
            props.active ? _this.foundation.activate() : _this.foundation.deactivate();
        });
        // center
        this.syncProp(props.center, prevProps.center, function () {
            typeof props.center === 'number' &&
                _this.foundation.setRippleCenter(props.center);
        });
    };
    LineRipple.prototype.render = function () {
        var _a = this.props, active = _a.active, center = _a.center, rest = __rest(_a, ["active", "center"]);
        return (React.createElement("div", __assign({}, this.root.props(__assign({}, rest, { className: "mdc-line-ripple " + (this.props.className || '') })), { ref: this.root.setRef })));
    };
    LineRipple.displayName = 'LineRipple';
    return LineRipple;
}(FoundationComponent));
export { LineRipple };
