var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) if (e.indexOf(p[i]) < 0)
            t[p[i]] = s[p[i]];
    return t;
};
import * as React from 'react';
import { componentFactory, wrapChild } from '@rmwc/base';
/** The Elevation Component */
export var Elevation = componentFactory({
    displayName: 'Elevation',
    defaultProps: {
        z: 0,
        transition: false
    },
    classNames: function (props) { return [
        "mdc-elevation--z" + props.z,
        { 'mdc-elevation-transition': props.transition }
    ]; },
    consumeProps: ['z', 'transition'],
    render: function (props, ref, Tag) {
        var wrap = props.wrap, rest = __rest(props, ["wrap"]);
        if (wrap) {
            return wrapChild(__assign({}, rest, { ref: ref }));
        }
        return React.createElement(Tag, __assign({}, rest, { ref: ref }));
    }
});
