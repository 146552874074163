var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) if (e.indexOf(p[i]) < 0)
            t[p[i]] = s[p[i]];
    return t;
};
import * as React from 'react';
import { deprecationWarning } from '@rmwc/base';
// Default provider options
var providerDefaults = {
    ripple: true,
    icon: {
        icon: '',
        basename: 'material-icons',
        prefix: '',
        strategy: 'auto',
        render: undefined
    }
};
export var ProviderContext = React.createContext(providerDefaults);
export var withProviderContext = function () { return function (Component) {
    var wrapped = React.forwardRef(function (props, ref) { return (React.createElement(ProviderContext.Consumer, null, function (providerContext) { return (React.createElement(Component, __assign({}, props, { providerContext: providerContext, ref: ref }))); })); });
    return wrapped;
}; };
/** A provider for setting global options in RMWC. */
export var RMWCProvider = function (_a) {
    var children = _a.children, iconClassNameBase = _a.iconClassNameBase, iconClassNamePrefix = _a.iconClassNamePrefix, iconStrategy = _a.iconStrategy, iconRender = _a.iconRender, rest = __rest(_a, ["children", "iconClassNameBase", "iconClassNamePrefix", "iconStrategy", "iconRender"]);
    var value = __assign({}, providerDefaults);
    var iconOptions = __assign({}, value.icon);
    /* istanbul ignore if */
    if (iconClassNameBase || iconClassNamePrefix || iconStrategy || iconRender) {
        deprecationWarning("RMWCProvider component no longer accepts iconClassNameBase, iconClassNamePrefix, iconStrategy, or iconRender props. Please use the 'icon' props instead. icon={{basename: 'material-icons', strategy: 'url'}}");
        iconOptions.basename = iconClassNameBase || iconOptions.basename;
        iconOptions.prefix = iconClassNamePrefix || iconOptions.prefix;
        iconOptions.strategy = iconStrategy || iconOptions.strategy;
        iconOptions.render = iconRender || iconOptions.render;
    }
    value.icon = iconOptions;
    return (React.createElement(ProviderContext.Provider, { value: __assign({}, value, rest) }, children));
};
