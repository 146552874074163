var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) if (e.indexOf(p[i]) < 0)
            t[p[i]] = s[p[i]];
    return t;
};
import * as React from 'react';
import { MDCLinearProgressFoundation } from '@material/linear-progress';
import { componentFactory, FoundationComponent, deprecationWarning } from '@rmwc/base';
var LinearProgressRoot = componentFactory({
    displayName: 'LinearProgressRoot',
    tag: 'nav',
    classNames: function (props) { return [
        'mdc-linear-progress',
        {
            'mdc-linear-progress--reversed': props.reversed,
            'mdc-linear-progress--closed': props.closed
        }
    ]; },
    defaultProps: {
        role: 'progressbar'
    },
    consumeProps: ['determinate', 'reversed', 'accent', 'closed']
});
var LinearProgressBufferingDots = componentFactory({
    displayName: 'LinearProgressBufferingDots',
    classNames: ['mdc-linear-progress__buffering-dots']
});
var LinearProgressBuffer = componentFactory({
    displayName: 'LinearProgressBuffer',
    classNames: ['mdc-linear-progress__buffer']
});
var LinearProgressPrimaryBar = componentFactory({
    displayName: 'LinearProgressPrimaryBar',
    classNames: ['mdc-linear-progress__bar mdc-linear-progress__primary-bar']
});
var LinearProgressSecondaryBar = componentFactory({
    displayName: 'LinearProgressSecondaryBar',
    classNames: ['mdc-linear-progress__bar mdc-linear-progress__secondary-bar']
});
var LinearProgressBarInner = componentFactory({
    displayName: 'LinearProgressBarInner',
    classNames: ['mdc-linear-progress__bar-inner']
});
/** A component to display linear progress. */
var LinearProgress = /** @class */ (function (_super) {
    __extends(LinearProgress, _super);
    function LinearProgress() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.root = _this.createElement('root');
        _this.determinate = null;
        return _this;
    }
    LinearProgress.prototype.getDefaultFoundation = function () {
        var _this = this;
        return new MDCLinearProgressFoundation({
            addClass: function (className) { return _this.root.addClass(className); },
            getPrimaryBar: function () {
                return _this.root.ref &&
                    _this.root.ref.querySelector(MDCLinearProgressFoundation.strings.PRIMARY_BAR_SELECTOR);
            },
            getBuffer: function () {
                return _this.root.ref &&
                    _this.root.ref.querySelector(MDCLinearProgressFoundation.strings.BUFFER_SELECTOR);
            },
            hasClass: function (className) { return _this.root.hasClass(className); },
            removeClass: function (className) { return _this.root.removeClass(className); },
            setStyle: function (el, styleProperty, value) { return (el.style[styleProperty] = value); }
        });
    };
    LinearProgress.prototype.sync = function (props, prevProps) {
        // progress
        if (props.progress !== prevProps.progress) {
            this.foundation.setProgress(props.progress || 0);
        }
        // buffer
        if (props.buffer !== prevProps.buffer) {
            this.foundation.setBuffer(props.buffer || 0);
        }
        // determinate
        // automatically derive this from progress
        if (props.progress !== undefined && !this.determinate) {
            // progress is passed but we are not currently determinate
            this.foundation.setDeterminate(true);
            this.determinate = true;
        }
        else if ((props.progress === undefined && this.determinate) ||
            this.determinate === null) {
            // progress is not passed and we are either determinate, or its our first syncing
            // indicated by this.determinate being null;
            this.foundation.setDeterminate(false);
            this.determinate = false;
        }
        // reversed
        if (props.reversed !== prevProps.reversed) {
            this.foundation.setReverse(!!props.reversed);
        }
        // closed
        if (props.closed !== prevProps.closed) {
            props.closed ? this.foundation.close() : this.foundation.open();
        }
    };
    LinearProgress.prototype.render = function () {
        var _a = this.props, progress = _a.progress, buffer = _a.buffer, determinate = _a.determinate, rest = __rest(_a, ["progress", "buffer", "determinate"]);
        if (determinate !== undefined) {
            deprecationWarning('LinearProgress determinate is no longer a valid prop. Determinate is set automatically be the presence of the progress prop.');
        }
        return (React.createElement(LinearProgressRoot, __assign({}, this.root.props(rest), { ref: this.root.setRef }),
            React.createElement(LinearProgressBufferingDots, null),
            React.createElement(LinearProgressBuffer, null),
            React.createElement(LinearProgressPrimaryBar, null,
                React.createElement(LinearProgressBarInner, null)),
            React.createElement(LinearProgressSecondaryBar, null,
                React.createElement(LinearProgressBarInner, null))));
    };
    LinearProgress.displayName = 'LinearProgress';
    LinearProgress.defaultProps = {
        progress: undefined,
        buffer: undefined,
        reversed: false
    };
    return LinearProgress;
}(FoundationComponent));
export { LinearProgress };
