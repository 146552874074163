var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) if (e.indexOf(p[i]) < 0)
            t[p[i]] = s[p[i]];
    return t;
};
import * as React from 'react';
import { MDCCheckboxFoundation } from '@material/checkbox';
import { componentFactory } from '@rmwc/base';
import { withRipple } from '@rmwc/ripple';
import { ToggleableFoundationComponent } from '@rmwc/toggleable';
/**
 * This is an awful freaking bugfix
 * Basically, MDC decided that patching the native getter and setter
 * on a checkbox would be fun which consequently kills Reacts ability
 * to do the same.
 */
// @ts-ignore
MDCCheckboxFoundation.prototype.installPropertyChangeHooks_ = function () { };
var CheckboxRoot = withRipple({
    surface: false,
    unbounded: true
})(componentFactory({
    displayName: 'CheckboxRoot',
    classNames: function (props) { return [
        'mdc-checkbox',
        {
            'mdc-checkbox--disabled': props.disabled
        }
    ]; },
    consumeProps: ['disabled']
}));
var CheckboxNativeControl = componentFactory({
    displayName: 'CheckboxNativeControl',
    defaultProps: {
        type: 'checkbox'
    },
    tag: 'input',
    classNames: ['mdc-checkbox__native-control']
});
var CheckboxBackground = /** @class */ (function (_super) {
    __extends(CheckboxBackground, _super);
    function CheckboxBackground() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    CheckboxBackground.prototype.shouldComponentUpdate = function () {
        return false;
    };
    CheckboxBackground.prototype.render = function () {
        return (React.createElement("div", { className: "mdc-checkbox__background" },
            React.createElement("svg", { className: "mdc-checkbox__checkmark", viewBox: "0 0 24 24" },
                React.createElement("path", { className: "mdc-checkbox__checkmark-path", fill: "none", stroke: "white", d: "M1.73,12.91 8.1,19.28 22.79,4.59" })),
            React.createElement("div", { className: "mdc-checkbox__mixedmark" })));
    };
    CheckboxBackground.displayName = 'CheckboxBackground';
    return CheckboxBackground;
}(React.Component));
/** A Checkbox component. */
var Checkbox = /** @class */ (function (_super) {
    __extends(Checkbox, _super);
    function Checkbox(props) {
        var _this = _super.call(this, props) || this;
        _this.nativeCb = _this.createElement('nativeCb');
        _this.root = _this.createElement('root');
        _this.handleAnimationEnd = _this.handleAnimationEnd.bind(_this);
        _this.handleOnChange = _this.handleOnChange.bind(_this);
        return _this;
    }
    Checkbox.prototype.sync = function (nextProps) {
        this.foundation && this.foundation.handleChange();
        if (this.nativeCb.ref &&
            nextProps.indeterminate !== this.nativeCb.ref.indeterminate) {
            this.nativeCb.ref.indeterminate = !!nextProps.indeterminate;
        }
    };
    Checkbox.prototype.getDefaultFoundation = function () {
        var _this = this;
        return new MDCCheckboxFoundation({
            addClass: function (className) { return _this.root.addClass(className); },
            removeClass: function (className) { return _this.root.removeClass(className); },
            setNativeControlAttr: function (attr, value) {
                return _this.nativeCb.setProp(attr, value);
            },
            removeNativeControlAttr: function (attr) {
                return _this.nativeCb.removeProp(attr);
            },
            isIndeterminate: function () { return !!_this.props.indeterminate; },
            isChecked: function () {
                return _this.props.checked !== undefined
                    ? !!_this.props.checked
                    : !!_this.nativeCb.ref && _this.nativeCb.ref.checked;
            },
            hasNativeControl: function () { return !!_this.nativeCb.ref; },
            setNativeControlDisabled: function (disabled) {
                return _this.nativeCb.setProp('disabled', disabled);
            },
            forceLayout: function () { return _this.root.ref && _this.root.ref.offsetWidth; },
            isAttachedToDOM: function () { return true; }
        });
    };
    Checkbox.prototype.handleAnimationEnd = function (evt) {
        this.props.onAnimationEnd && this.props.onAnimationEnd(evt);
        this.foundation && this.foundation.handleAnimationEnd();
    };
    Checkbox.prototype.handleOnChange = function (evt) {
        this.props.onChange && this.props.onChange(evt);
        this.sync(this.props);
    };
    Checkbox.prototype.render = function () {
        var _this = this;
        var _a = this.props, children = _a.children, className = _a.className, label = _a.label, style = _a.style, indeterminate = _a.indeterminate, inputRef = _a.inputRef, rest = __rest(_a, ["children", "className", "label", "style", "indeterminate", "inputRef"]);
        var checkbox = (React.createElement(CheckboxRoot, __assign({}, this.toggleRootProps, { ref: this.root.setRef, onAnimationEnd: this.handleAnimationEnd }),
            React.createElement(CheckboxNativeControl, __assign({}, this.nativeCb.props(rest), { ref: function (el) {
                    _this.nativeCb.setRef(el);
                    inputRef && inputRef(el);
                }, id: this.id, onChange: this.handleOnChange })),
            React.createElement(CheckboxBackground, null)));
        return this.renderToggle(checkbox);
    };
    Checkbox.displayName = 'Checkbox';
    return Checkbox;
}(ToggleableFoundationComponent));
export { Checkbox };
