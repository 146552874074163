var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) if (e.indexOf(p[i]) < 0)
            t[p[i]] = s[p[i]];
    return t;
};
import * as React from 'react';
import { FoundationComponent } from '@rmwc/base';
import { MDCNotchedOutlineFoundation } from '@material/notched-outline';
var NotchedOutlineLeading = /** @class */ (function (_super) {
    __extends(NotchedOutlineLeading, _super);
    function NotchedOutlineLeading() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    NotchedOutlineLeading.prototype.shouldComponentUpdate = function () {
        return false;
    };
    NotchedOutlineLeading.prototype.render = function () {
        return React.createElement("div", { className: "mdc-notched-outline__leading" });
    };
    return NotchedOutlineLeading;
}(React.Component));
var NotchedOutlineTrailing = /** @class */ (function (_super) {
    __extends(NotchedOutlineTrailing, _super);
    function NotchedOutlineTrailing() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    NotchedOutlineTrailing.prototype.shouldComponentUpdate = function () {
        return false;
    };
    NotchedOutlineTrailing.prototype.render = function () {
        return React.createElement("div", { className: "mdc-notched-outline__trailing" });
    };
    return NotchedOutlineTrailing;
}(React.Component));
var NotchedOutline = /** @class */ (function (_super) {
    __extends(NotchedOutline, _super);
    function NotchedOutline() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.root = _this.createElement('root');
        _this.notchElement = _this.createElement('root');
        _this.label = null;
        return _this;
    }
    NotchedOutline.prototype.componentDidMount = function () {
        var _this = this;
        _super.prototype.componentDidMount.call(this);
        this.label = this.root.ref && this.root.ref.querySelector('label');
        if (this.label) {
            this.label.style.transitionDuration = '0s';
            this.root.addClass(MDCNotchedOutlineFoundation.cssClasses.OUTLINE_UPGRADED);
            requestAnimationFrame(function () {
                _this.label && (_this.label.style.transitionDuration = '');
            });
        }
        else {
            this.root.addClass(MDCNotchedOutlineFoundation.cssClasses.NO_LABEL);
        }
    };
    NotchedOutline.prototype.getDefaultFoundation = function () {
        var _this = this;
        return new MDCNotchedOutlineFoundation({
            addClass: function (className) { return _this.root.addClass(className); },
            removeClass: function (className) { return _this.root.removeClass(className); },
            setNotchWidthProperty: function (width) {
                return _this.notchElement.setStyle('width', width + 'px');
            },
            removeNotchWidthProperty: function () { return _this.notchElement.setStyle('width', ''); }
        });
    };
    NotchedOutline.prototype.sync = function (props, prevProps) {
        var _this = this;
        this.syncProp(props.notch, prevProps.notch, function () {
            !!props.notch
                ? _this.foundation.notch(props.notch)
                : _this.foundation.closeNotch();
        });
    };
    NotchedOutline.prototype.render = function () {
        var _a = this.props, children = _a.children, rest = __rest(_a, ["children"]);
        return (React.createElement("div", __assign({}, this.root.props(__assign({}, rest, { className: 'mdc-notched-outline' })), { ref: this.root.setRef }),
            React.createElement(NotchedOutlineLeading, null),
            React.createElement("div", __assign({}, this.notchElement.props({}), { className: "mdc-notched-outline__notch", ref: this.notchElement.setRef }), children),
            React.createElement(NotchedOutlineTrailing, null)));
    };
    NotchedOutline.displayName = 'NotchedOutline';
    return NotchedOutline;
}(FoundationComponent));
export { NotchedOutline };
