var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as React from 'react';
import { MDCFormFieldFoundation } from '@material/form-field';
import { FoundationComponent } from '@rmwc/base';
import { componentFactory } from '@rmwc/base';
export var FormFieldRoot = componentFactory({
    displayName: 'FormFieldRoot',
    defaultProps: {
        alignEnd: undefined
    },
    classNames: function (props) { return [
        'mdc-form-field',
        {
            'mdc-form-field--align-end': props.alignEnd
        }
    ]; },
    consumeProps: ['alignEnd']
});
/** A FormField component. */
var FormField = /** @class */ (function (_super) {
    __extends(FormField, _super);
    function FormField() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    FormField.prototype.getDefaultFoundation = function () {
        // For RMWC, the entire foundation is a noop. Interactions and ripples are controlled
        // on the components themselves
        return new MDCFormFieldFoundation({
            registerInteractionHandler: function (evtType, handler) { },
            deregisterInteractionHandler: function (evtType, handler) { },
            activateInputRipple: function () { },
            deactivateInputRipple: function () { }
        });
    };
    FormField.prototype.render = function () {
        return React.createElement(FormFieldRoot, __assign({}, this.props));
    };
    FormField.displayName = 'FormField';
    return FormField;
}(FoundationComponent));
export { FormField };
