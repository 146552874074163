var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) if (e.indexOf(p[i]) < 0)
            t[p[i]] = s[p[i]];
    return t;
};
import * as React from 'react';
import { MDCSnackbarFoundation, util } from '@material/snackbar';
import { Button } from '@rmwc/button';
import { componentFactory, FoundationComponent, handleDeprecations, closest } from '@rmwc/base';
import { IconButton } from '@rmwc/icon-button';
var SnackbarRoot = componentFactory({
    displayName: 'SnackbarRoot',
    classNames: function (props) { return [
        'mdc-snackbar',
        {
            'mdc-snackbar--leading': props.leading,
            'mdc-snackbar--stacked': props.stacked
        }
    ]; },
    defaultProps: {
        leading: false,
        'aria-live': 'assertive',
        'aria-atomic': true,
        'aria-hidden': true
    },
    consumeProps: ['leading', 'stacked']
});
var SnackbarLabel = componentFactory({
    displayName: 'SnackbarText',
    classNames: ['mdc-snackbar__label'],
    defaultProps: {
        role: 'status',
        'aria-live': 'polite'
    }
});
var SnackbarActions = componentFactory({
    displayName: 'SnackbarActions',
    classNames: ['mdc-snackbar__actions']
});
/** A button for a snackbar action. */
export var SnackbarAction = componentFactory({
    displayName: 'SnackbarAction',
    tag: Button,
    classNames: ['mdc-snackbar__action']
});
var SnackbarDismiss = componentFactory({
    displayName: 'SnackbarDismiss',
    tag: IconButton,
    classNames: ['mdc-snackbar__dismiss']
});
/** A Snackbar component for notifications. */
var Snackbar = /** @class */ (function (_super) {
    __extends(Snackbar, _super);
    function Snackbar(props) {
        var _this = _super.call(this, props) || this;
        _this.root = _this.createElement('root');
        _this.isShowing_ = false;
        _this.labelEl = null;
        _this.announce = util.announce;
        _this.handleKeyDown = _this.handleKeyDown.bind(_this);
        _this.handleSurfaceClick = _this.handleSurfaceClick.bind(_this);
        return _this;
    }
    Snackbar.prototype.getDefaultFoundation = function () {
        var _this = this;
        /* eslint brace-style: "off" */
        return new MDCSnackbarFoundation({
            addClass: function (className) { return _this.root.addClass(className); },
            removeClass: function (className) { return _this.root.removeClass(className); },
            announce: function () { return _this.labelEl && _this.announce(_this.labelEl); },
            notifyOpening: function () { return _this.emit('onOpen', {}); },
            notifyOpened: function () { return _this.emit('onOpened', {}); },
            notifyClosing: function (reason) {
                return _this.emit('onClose', reason ? { reason: reason } : {});
            },
            notifyClosed: function (reason) {
                return _this.emit('onClosed', reason ? { reason: reason } : {});
            }
        });
    };
    Snackbar.prototype.sync = function (props, prevProps) {
        props = this.getPropsWithDeprecations(props);
        prevProps = this.getPropsWithDeprecations(prevProps);
        // open
        if (props.open !== prevProps.open && props.open) {
            this.foundation.open();
        }
        // timeout
        if (props.timeout !== prevProps.timeout) {
            // dont tell me what I can cant set my timeout too...
            // directly patch over using setTimeoutMs
            this.foundation.autoDismissTimeoutMs_ = props.timeout;
        }
    };
    Snackbar.prototype.getPropsWithDeprecations = function (props) {
        return handleDeprecations(props, {
            show: 'open',
            onShow: 'onOpen',
            onHide: 'onClose',
            alignStart: 'leading',
            multiline: '',
            actionOnBottom: 'stacked',
            actionHandler: '',
            actionText: ''
        }, 'Snackbar');
    };
    Snackbar.prototype.handleKeyDown = function (evt) {
        this.props.onKeyDown && this.props.onKeyDown(evt);
        this.foundation.handleKeyDown(evt);
    };
    Snackbar.prototype.handleSurfaceClick = function (evt) {
        if (evt.target instanceof Element) {
            var el = evt.target;
            // corrects an issue where they were clicking on
            // the inside a button
            // TODO, improve this to be more scalable to any element
            if (el.classList.contains('mdc-button__label')) {
                el = closest(el, '.mdc-button');
            }
            if (this.props.dismissesOnAction &&
                el.classList.contains('mdc-snackbar__action')) {
                this.foundation.handleActionButtonClick(evt);
            }
            else if (el.classList.contains('mdc-snackbar__dismiss')) {
                this.foundation.handleActionIconClick(evt);
            }
        }
    };
    Snackbar.prototype.render = function () {
        var _this = this;
        // grab these before we try to correct them in the deprecation
        var _a = this.props, actionText = _a.actionText, actionHandler = _a.actionHandler;
        var _b = this.getPropsWithDeprecations(this.props), open = _b.open, message = _b.message, timeout = _b.timeout, dismissIcon = _b.dismissIcon, onOpen = _b.onOpen, onClose = _b.onClose, children = _b.children, action = _b.action, dismissesOnAction = _b.dismissesOnAction, rest = __rest(_b, ["open", "message", "timeout", "dismissIcon", "onOpen", "onClose", "children", "action", "dismissesOnAction"]);
        var actions = Array.isArray(action) ? action : action ? [action] : [];
        return (React.createElement(SnackbarRoot, __assign({}, this.root.props(rest), { ref: this.root.setRef, onKeyDown: this.handleKeyDown }),
            React.createElement("div", { className: "mdc-snackbar__surface", onClick: this.handleSurfaceClick },
                React.createElement(SnackbarLabel, null,
                    message,
                    React.createElement("div", { style: { display: 'none' }, ref: function (el) { return (_this.labelEl = el); } })),
                React.createElement(SnackbarActions, null,
                    !!actionText && (React.createElement(SnackbarAction, { onClick: actionHandler }, actionText)),
                    actions.map(function (a, i) { return (React.createElement(React.Fragment, { key: i }, a)); }),
                    dismissIcon && (React.createElement(SnackbarDismiss, { icon: dismissIcon === true ? 'close' : dismissIcon }))),
                children)));
    };
    Snackbar.displayName = 'Snackbar';
    Snackbar.defaultProps = {
        dismissesOnAction: true
    };
    return Snackbar;
}(FoundationComponent));
export { Snackbar };
