var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) if (e.indexOf(p[i]) < 0)
            t[p[i]] = s[p[i]];
    return t;
};
import * as React from 'react';
import { componentFactory } from '@rmwc/base';
import { withRipple } from '@rmwc/ripple';
import { Icon } from '@rmwc/icon';
/**
 * The Button component.
 */
export var Button = withRipple({
    surface: false
})(componentFactory({
    displayName: 'Button',
    tag: 'button',
    classNames: function (props) { return [
        'mdc-button',
        {
            'mdc-button--dense': props.dense,
            'mdc-button--raised': props.raised,
            'mdc-button--unelevated': props.unelevated,
            'mdc-button--outlined': props.outlined
        }
    ]; },
    consumeProps: [
        'dense',
        'raised',
        'unelevated',
        'outlined',
        'primary',
        'accent',
        'unbounded'
    ],
    render: function (_a, ref, Tag) {
        var icon = _a.icon, trailingIcon = _a.trailingIcon, label = _a.label, children = _a.children, rest = __rest(_a, ["icon", "trailingIcon", "label", "children"]);
        return (React.createElement(Tag, __assign({}, rest, { ref: ref }),
            !!icon && React.createElement(ButtonIcon, { icon: icon }),
            React.createElement("span", { className: "mdc-button__label" },
                label,
                children),
            !!trailingIcon && React.createElement(ButtonIcon, { icon: trailingIcon })));
    }
}));
/** An icon that goes inside of buttons. This is an instance of the Icon component. */
export var ButtonIcon = componentFactory({
    displayName: 'ButtonIcon',
    tag: Icon,
    classNames: ['mdc-button__icon']
});
