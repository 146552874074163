var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) if (e.indexOf(p[i]) < 0)
            t[p[i]] = s[p[i]];
    return t;
};
import * as React from 'react';
import { MDCToolbarFoundation } from '@material/toolbar';
import { componentFactory, FoundationComponent } from '@rmwc/base';
import { Icon } from '@rmwc/icon';
import { withRipple } from '@rmwc/ripple';
var ToolbarRoot = componentFactory({
    displayName: 'TabBarRoot',
    tag: 'header',
    classNames: function (props) { return [
        'mdc-toolbar',
        {
            'mdc-toolbar--fixed': props.fixed,
            'mdc-toolbar--waterfall': props.waterfall,
            'mdc-toolbar--fixed-lastrow-only': props.fixedLastrowOnly,
            'mdc-toolbar--flexible': props.flexible,
            'mdc-toolbar--flexible-default-behavior': props.flexibleDefaultBehavior
        }
    ]; },
    defaultProps: {
        fixed: false,
        waterfall: false,
        fixedLastrowOnly: false,
        flexible: false,
        flexibleDefaultBehavior: false
    },
    consumeProps: [
        'fixed',
        'waterfall',
        'fixedLastrowOnly',
        'flexible',
        'flexibleDefaultBehavior'
    ]
});
/** A Toolbar title  */
export var ToolbarTitle = componentFactory({
    displayName: 'ToolbarTitle',
    classNames: ['mdc-toolbar__title']
});
/** A section of the Toolbar */
export var ToolbarSection = componentFactory({
    displayName: 'ToolbarSection',
    tag: 'section',
    classNames: function (props) { return [
        'mdc-toolbar__section',
        {
            'mdc-toolbar__section--align-start': props.alignStart,
            'mdc-toolbar__section--align-end': props.alignEnd,
            'mdc-toolbar__section--shrink-to-fit': props.shrinkToFit
        }
    ]; },
    defaultProps: {
        alignStart: false,
        alignEnd: false,
        shrinkToFit: false
    },
    consumeProps: ['alignStart', 'alignEnd', 'shrinkToFit']
});
/** A Toolbar row  */
export var ToolbarRow = componentFactory({
    displayName: 'ToolbarRow',
    classNames: ['mdc-toolbar__row']
});
/** This component can be placed after a fixed Toolbar component to fill in the space. */
export var ToolbarFixedAdjust = componentFactory({
    displayName: 'ToolbarFixedAdjust',
    classNames: ['mdc-toolbar-fixed-adjust']
});
/** A Menu Icon For the Toolbar. This is an instance of the Icon component. */
export var ToolbarMenuIcon = withRipple({ unbounded: true, surface: false })(componentFactory({
    displayName: 'ToolbarMenuIcon',
    tag: Icon,
    classNames: ['mdc-toolbar__menu-icon']
}));
/** A standard Icon for toolbar actions. This is an instance of the Icon component. */
export var ToolbarIcon = withRipple({
    unbounded: true
})(componentFactory({
    displayName: 'ToolbarIcon',
    tag: Icon,
    classNames: ['mdc-toolbar__icon']
}));
/** A Toolbar component. */
export var Toolbar = function (props) {
    /** Generate a key that will force a re-init if props change */
    var key = (props.fixed ? 'fixed' : '') + " " + (props.fixedLastrowOnly ? 'fixed-last-row' : '') + " " + (props.flexible ? 'flexible' : '');
    return React.createElement(ToolbarBase, __assign({ key: key }, props));
};
Toolbar.displayName = 'Toolbar';
var ToolbarBase = /** @class */ (function (_super) {
    __extends(ToolbarBase, _super);
    function ToolbarBase() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.root = _this.createElement('root');
        _this.fixedAdjustElement_ = null;
        return _this;
    }
    ToolbarBase.prototype.componentDidMount = function () {
        _super.prototype.componentDidMount.call(this);
        // loop through and get the fixed adjust element
        if (this.root.ref && this.root.ref.parentNode) {
            for (var i = 0; i < this.root.ref.parentNode.children.length; i++) {
                var el = this.root.ref.parentNode.children[i];
                if ((el.getAttribute('class') || '').includes('mdc-toolbar-fixed-adjust')) {
                    this.fixedAdjustElement = el;
                    break;
                }
            }
        }
    };
    Object.defineProperty(ToolbarBase.prototype, "firstRowElement", {
        get: function () {
            return (this.root.ref &&
                this.root.ref.querySelector(MDCToolbarFoundation.strings.FIRST_ROW_SELECTOR));
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ToolbarBase.prototype, "titleElement", {
        get: function () {
            return (this.root.ref &&
                this.root.ref.querySelector(MDCToolbarFoundation.strings.TITLE_SELECTOR));
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ToolbarBase.prototype, "fixedAdjustElement", {
        get: function () {
            return this.fixedAdjustElement_;
        },
        set: function (fixedAdjustElement) {
            this.fixedAdjustElement_ = fixedAdjustElement;
            this.foundation.updateAdjustElementStyles();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ToolbarBase.prototype, "window", {
        get: function () {
            if (this.root.ref &&
                this.root.ref.ownerDocument &&
                this.root.ref.ownerDocument.defaultView) {
                return this.root.ref.ownerDocument.defaultView;
            }
            return window;
        },
        enumerable: true,
        configurable: true
    });
    ToolbarBase.prototype.getDefaultFoundation = function () {
        var _this = this;
        return new MDCToolbarFoundation({
            hasClass: function (className) {
                return !!_this.root.ref && _this.root.ref.classList.contains(className);
            },
            addClass: function (className) { return _this.root.addClass(className); },
            removeClass: function (className) { return _this.root.removeClass(className); },
            registerScrollHandler: function (handler) {
                _this.window.addEventListener('scroll', handler);
            },
            deregisterScrollHandler: function (handler) {
                return _this.window.removeEventListener('scroll', handler);
            },
            registerResizeHandler: function (handler) {
                return _this.window.addEventListener('resize', handler);
            },
            deregisterResizeHandler: function (handler) {
                return _this.window.removeEventListener('resize', handler);
            },
            getViewportWidth: function () { return _this.window.innerWidth; },
            getViewportScrollY: function () { return _this.window.pageYOffset; },
            getOffsetHeight: function () { return (_this.root.ref ? _this.root.ref.offsetHeight : 0); },
            getFirstRowElementOffsetHeight: function () {
                return _this.firstRowElement ? _this.firstRowElement.offsetHeight : 0;
            },
            notifyChange: function (evtData) {
                return _this.emit('onChange', evtData);
            },
            setStyle: function (property, value) {
                return _this.root.ref && _this.root.setStyle(property, value);
            },
            setStyleForTitleElement: function (property, value) {
                return _this.titleElement &&
                    _this.titleElement.style.setProperty(property, value);
            },
            setStyleForFlexibleRowElement: function (property, value) {
                return _this.firstRowElement &&
                    _this.firstRowElement.style.setProperty(property, value);
            },
            setStyleForFixedAdjustElement: function (property, value) {
                if (_this.fixedAdjustElement) {
                    _this.fixedAdjustElement.style.setProperty(property, value);
                }
            }
        });
    };
    ToolbarBase.prototype.render = function () {
        var rest = __rest(this.props, []);
        return React.createElement(ToolbarRoot, __assign({}, this.root.props(rest), { ref: this.root.setRef }));
    };
    ToolbarBase.displayName = 'Toolbar';
    return ToolbarBase;
}(FoundationComponent));
