var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) if (e.indexOf(p[i]) < 0)
            t[p[i]] = s[p[i]];
    return t;
};
import * as React from 'react';
import { componentFactory, FoundationComponent } from '@rmwc/base';
import { MDCModalDrawerFoundation, MDCDismissibleDrawerFoundation } from '@material/drawer';
import { createFocusTrap } from '@rmwc/base';
/** An optional header for the Drawer. */
export var DrawerHeader = componentFactory({
    displayName: 'DrawerHeader',
    classNames: ['mdc-drawer__header']
});
/** An title for the DrawerHeader. */
export var DrawerTitle = componentFactory({
    displayName: 'DrawerTitle',
    classNames: ['mdc-drawer__title']
});
/** A subtitle for the DrawerHeader. */
export var DrawerSubtitle = componentFactory({
    displayName: 'DrawerSubtitle',
    classNames: ['mdc-drawer__subtitle']
});
/** Content for Drawers. */
export var DrawerContent = componentFactory({
    displayName: 'DrawerContent',
    classNames: ['mdc-drawer__content']
});
/***************************************************************************************
 * Drawer Scrim
 ***************************************************************************************/
/** Protects the app's UI from interactions while a modal drawer is open. */
var DrawerScrim = function (_a) {
    var onClick = _a.onClick;
    return React.createElement("div", { className: "mdc-drawer-scrim", onClick: onClick });
};
/** For the Dismissible variant only. Sibling element that is resized when the drawer opens/closes. */
export var DrawerAppContent = componentFactory({
    displayName: 'DrawerAppContent',
    classNames: ['mdc-drawer-app-content']
});
export var DrawerRoot = componentFactory({
    displayName: 'DrawerRoot',
    tag: 'aside',
    classNames: function (props) { return [
        'mdc-drawer',
        {
            'mdc-drawer--dismissible': props.dismissible,
            'mdc-drawer--modal': props.modal
        }
    ]; },
    consumeProps: ['dismissible', 'modal']
});
var slidableDrawerFactory = function (MDCConstructor, displayName) { var _a; return _a = /** @class */ (function (_super) {
        __extends(class_1, _super);
        function class_1(props) {
            var _this = _super.call(this, props) || this;
            _this.root = _this.createElement('root');
            _this.previousFocus = null;
            _this.focusTrap = null;
            ['handleScrimClick', 'handleTransitionEnd', 'handleKeyDown'].forEach(function (k) {
                _this[k] = _this[k].bind(_this);
            });
            return _this;
        }
        class_1.prototype.componentDidMount = function () {
            _super.prototype.componentDidMount.call(this);
            this.root.ref &&
                (this.focusTrap = createFocusTrap(this.root.ref, {
                    clickOutsideDeactivates: true,
                    initialFocus: undefined,
                    escapeDeactivates: false,
                    returnFocusOnDeactivate: false
                }));
        };
        class_1.prototype.getDefaultFoundation = function () {
            var _this = this;
            /** @type {!MDCDrawerAdapter} */
            var adapter = /** @type {!MDCDrawerAdapter} */ {
                addClass: function (className) { return _this.root.addClass(className); },
                removeClass: function (className) { return _this.root.removeClass(className); },
                hasClass: function (className) { return _this.root.hasClass(className); },
                elementHasClass: function (element, className) {
                    return element.classList.contains(className);
                },
                saveFocus: function () {
                    _this.previousFocus = document.activeElement;
                },
                restoreFocus: function () {
                    var previousFocus = _this.previousFocus && _this.previousFocus.focus;
                    if (_this.root.ref &&
                        _this.root.ref.contains(document.activeElement) &&
                        previousFocus) {
                        _this.previousFocus && _this.previousFocus.focus();
                    }
                },
                focusActiveNavigationItem: function () {
                    var activeNavItemEl = _this.root.ref &&
                        _this.root.ref.querySelector(".mdc-list-item--activated");
                    if (activeNavItemEl) {
                        activeNavItemEl.focus();
                    }
                },
                notifyClose: function () { return _this.emit('onClose', {}, true /* shouldBubble */); },
                notifyOpen: function () { return _this.emit('onOpen', {}, true /* shouldBubble */); },
                trapFocus: function () {
                    try {
                        _this.focusTrap && _this.focusTrap.activate();
                    }
                    catch (err) { }
                },
                releaseFocus: function () {
                    try {
                        _this.focusTrap && _this.focusTrap.deactivate();
                    }
                    catch (err) { }
                }
            };
            return new MDCConstructor(adapter);
        };
        class_1.prototype.handleScrimClick = function () {
            'handleScrimClick' in this.foundation &&
                this.foundation.handleScrimClick();
        };
        class_1.prototype.handleKeyDown = function (evt) {
            this.props.onKeyDown && this.props.onKeyDown(evt);
            this.foundation.handleKeydown(evt);
        };
        class_1.prototype.handleTransitionEnd = function (evt) {
            this.props.onTransitionEnd && this.props.onTransitionEnd(evt);
            this.foundation.handleTransitionEnd(evt);
        };
        class_1.prototype.sync = function (props, prevProps) {
            if (props.open !== prevProps.open) {
                props.open ? this.foundation.open() : this.foundation.close();
            }
        };
        class_1.prototype.render = function () {
            var _a = this.props, onOpen = _a.onOpen, onClose = _a.onClose, open = _a.open, rest = __rest(_a, ["onOpen", "onClose", "open"]);
            return (React.createElement(React.Fragment, null,
                React.createElement(DrawerRoot, __assign({ ref: this.root.setRef }, this.root.props(rest), { onKeyDown: this.handleKeyDown, onTransitionEnd: this.handleTransitionEnd })),
                rest.modal && React.createElement(DrawerScrim, { onClick: this.handleScrimClick })));
        };
        return class_1;
    }(FoundationComponent)),
    _a.displayName = displayName,
    _a.defaultProps = {
        open: false
    },
    _a; };
var ModalDrawer = slidableDrawerFactory(MDCModalDrawerFoundation, 'ModalDrawer');
var DismissibleDrawer = slidableDrawerFactory(MDCDismissibleDrawerFoundation, 'dismissibleDrawer');
/** A Drawer component. */
export var Drawer = function (props) {
    if (props.dismissible) {
        return React.createElement(DismissibleDrawer, __assign({}, props));
    }
    if (props.modal) {
        return React.createElement(ModalDrawer, __assign({}, props));
    }
    return React.createElement(DrawerRoot, __assign({}, props));
};
Drawer.displayName = 'Drawer';
