var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) if (e.indexOf(p[i]) < 0)
            t[p[i]] = s[p[i]];
    return t;
};
import * as React from 'react';
import { MDCTabScrollerFoundation, util as scrollerUtil } from '@material/tab-scroller';
import { FoundationComponent, componentFactory, matches } from '@rmwc/base';
export var TabScrollerRoot = componentFactory({
    displayName: 'TabScroller',
    classNames: ['mdc-tab-scroller']
});
export var TabScrollerScrollArea = componentFactory({
    displayName: 'TabScrollerScrollArea',
    classNames: ['mdc-tab-scroller__scroll-area']
});
export var TabScrollerScrollContent = componentFactory({
    displayName: 'TabScrollerScrollContent',
    classNames: ['mdc-tab-scroller__scroll-content']
});
var TabScroller = /** @class */ (function (_super) {
    __extends(TabScroller, _super);
    function TabScroller(props) {
        var _this = _super.call(this, props) || this;
        _this.root = _this.createElement('root');
        _this.area = _this.createElement('area');
        _this.content = _this.createElement('content');
        _this.handleInteraction = _this.handleInteraction.bind(_this);
        _this.handleTransitionEnd = _this.handleTransitionEnd.bind(_this);
        return _this;
    }
    TabScroller.prototype.getDefaultFoundation = function () {
        var _this = this;
        return new MDCTabScrollerFoundation({
            eventTargetMatchesSelector: function (evtTarget, selector) {
                return matches(evtTarget, selector);
            },
            addClass: function (className) { return _this.root.addClass(className); },
            removeClass: function (className) { return _this.root.removeClass(className); },
            addScrollAreaClass: function (className) { return _this.area.addClass(className); },
            setScrollAreaStyleProperty: function (prop, value) {
                return _this.area.setStyle(prop, value);
            },
            setScrollContentStyleProperty: function (prop, value) {
                return _this.content.setStyle(prop, value);
            },
            getScrollContentStyleValue: function (propName) {
                var val = _this.content.ref &&
                    window.getComputedStyle(_this.content.ref).getPropertyValue(propName);
                return val || 'none';
            },
            setScrollAreaScrollLeft: function (scrollX) {
                return _this.area.ref && (_this.area.ref.scrollLeft = scrollX);
            },
            getScrollAreaScrollLeft: function () {
                return _this.area.ref ? _this.area.ref.scrollLeft : 0;
            },
            getScrollContentOffsetWidth: function () {
                return _this.content.ref ? _this.content.ref.offsetWidth : 0;
            },
            getScrollAreaOffsetWidth: function () {
                return _this.area.ref ? _this.area.ref.offsetWidth : 0;
            },
            computeScrollAreaClientRect: function () {
                return _this.area.ref
                    ? _this.area.ref.getBoundingClientRect()
                    : {};
            },
            computeScrollContentClientRect: function () {
                return _this.content.ref
                    ? _this.content.ref.getBoundingClientRect()
                    : {};
            },
            computeHorizontalScrollbarHeight: function () {
                return scrollerUtil.computeHorizontalScrollbarHeight(document);
            }
        });
    };
    TabScroller.prototype.getScrollPosition = function () {
        return this.foundation.getScrollPosition();
    };
    TabScroller.prototype.getScrollContentWidth = function () {
        return this.content.ref ? this.content.ref.offsetWidth : 0;
    };
    TabScroller.prototype.incrementScroll = function (scrollXIncrement) {
        this.foundation.incrementScroll(scrollXIncrement);
    };
    TabScroller.prototype.scrollTo = function (scrollX) {
        this.foundation.scrollTo(scrollX);
    };
    TabScroller.prototype.handleInteraction = function () {
        this.foundation.handleInteraction();
    };
    TabScroller.prototype.handleTransitionEnd = function (evt) {
        this.foundation.handleTransitionEnd(evt);
    };
    TabScroller.prototype.render = function () {
        var _a = this.props, children = _a.children, rest = __rest(_a, ["children"]);
        return (React.createElement(TabScrollerRoot, __assign({}, this.root.props(rest), { ref: this.root.setRef }),
            React.createElement(TabScrollerScrollArea, __assign({}, this.area.props({}), { ref: this.area.setRef, onWheel: this.handleInteraction, onTouchStart: this.handleInteraction, onPointerDown: this.handleInteraction, onMouseDown: this.handleInteraction, onKeyDown: this.handleInteraction }),
                React.createElement(TabScrollerScrollContent, __assign({}, this.content.props({}), { ref: this.content.setRef, onTransitionEnd: this.handleTransitionEnd }), children))));
    };
    return TabScroller;
}(FoundationComponent));
export { TabScroller };
