var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) if (e.indexOf(p[i]) < 0)
            t[p[i]] = s[p[i]];
    return t;
};
import { componentFactory, FoundationComponent } from '@rmwc/base';
import * as React from 'react';
import { MDCTopAppBarFoundation, MDCFixedTopAppBarFoundation, MDCShortTopAppBarFoundation } from '@material/top-app-bar';
import { Icon } from '@rmwc/icon';
import { withRipple } from '@rmwc/ripple';
var TopAppBarRoot = componentFactory({
    displayName: 'TopAppBarRoot',
    tag: 'header',
    classNames: function (props) { return [
        'mdc-top-app-bar',
        {
            'mdc-top-app-bar--fixed': props.fixed,
            'mdc-top-app-bar--prominent': props.prominent,
            'mdc-top-app-bar--short': props.short || props.shortCollapsed,
            'mdc-top-app-bar--short-collapsed': props.shortCollapsed,
            'mdc-top-app-bar--dense': props.dense
        }
    ]; },
    consumeProps: ['fixed', 'prominent', 'short', 'shortCollapsed', 'dense']
});
/** A row for the app bar. */
export var TopAppBarRow = componentFactory({
    displayName: 'TopAppBarRow',
    classNames: ['mdc-top-app-bar__row']
});
/** A section for the app bar. */
export var TopAppBarSection = componentFactory({
    displayName: 'TopAppBarSection',
    tag: 'section',
    classNames: function (props) { return [
        'mdc-top-app-bar__section',
        {
            'mdc-top-app-bar__section--align-start': props.alignStart,
            'mdc-top-app-bar__section--align-end': props.alignEnd
        }
    ]; },
    consumeProps: ['alignStart', 'alignEnd']
});
/** A navigation icon for the top app bar. This is an instance of the Icon component. */
export var TopAppBarNavigationIcon = withRipple({
    unbounded: true,
    surface: false
})(componentFactory({
    displayName: 'TopAppBarNavigationIcon',
    classNames: ['mdc-top-app-bar__navigation-icon'],
    tag: Icon
}));
/** Action items for the top app bar. This is an instance of the Icon component.*/
export var TopAppBarActionItem = withRipple({
    unbounded: true,
    surface: false
})(componentFactory({
    displayName: 'TopAppBarActionItem',
    classNames: ['mdc-top-app-bar__action-item'],
    tag: Icon
}));
/** A title for the top app bar. */
export var TopAppBarTitle = componentFactory({
    displayName: 'TopAppBarTitle',
    classNames: ['mdc-top-app-bar__title']
});
/** An optional component to fill the space when the TopAppBar is fixed. Place it directly after the TopAppBar. */
export var TopAppBarFixedAdjust = componentFactory({
    displayName: 'TopAppBarFixedAdjust',
    classNames: function (props) { return [
        'mdc-top-app-bar--fixed-adjust',
        {
            'mdc-top-app-bar--dense-fixed-adjust': props.dense,
            'mdc-top-app-bar--prominent-fixed-adjust': props.prominent,
            'mdc-top-app-bar--dense-prominent-fixed-adjust': props.denseProminent,
            'mdc-top-app-bar--short-fixed-adjust': props.short
        }
    ]; },
    consumeProps: ['dense', 'denseProminent', 'prominent', 'short']
});
/** A TopAppBar component */
var TopAppBarBase = /** @class */ (function (_super) {
    __extends(TopAppBarBase, _super);
    function TopAppBarBase() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.root = _this.createElement('root');
        _this.navIcon = null;
        _this.scrollTarget = null;
        return _this;
    }
    Object.defineProperty(TopAppBarBase.prototype, "window", {
        get: function () {
            return this.root.ref &&
                this.root.ref.ownerDocument &&
                this.root.ref.ownerDocument.defaultView
                ? this.root.ref.ownerDocument.defaultView
                : window;
        },
        enumerable: true,
        configurable: true
    });
    TopAppBarBase.prototype.componentDidMount = function () {
        _super.prototype.componentDidMount.call(this);
        if (!this.props.scrollTarget) {
            this.setScrollHandler(this.window);
        }
        this.navIcon =
            this.root.ref &&
                this.root.ref.querySelector(MDCTopAppBarFoundation.strings.NAVIGATION_ICON_SELECTOR);
    };
    TopAppBarBase.prototype.getDefaultFoundation = function () {
        var _this = this;
        var adapter = {
            hasClass: function (className) { return _this.root.hasClass(className); },
            addClass: function (className) { return _this.root.addClass(className); },
            removeClass: function (className) { return _this.root.removeClass(className); },
            setStyle: function (property, value) {
                return _this.root.ref && _this.root.ref.style.setProperty(property, value);
            },
            getTopAppBarHeight: function () {
                return _this.root.ref ? _this.root.ref.clientHeight : 0;
            },
            registerNavigationIconInteractionHandler: function (evtType, handler) {
                if (_this.navIcon) {
                    _this.navIcon.addEventListener(evtType, handler);
                }
            },
            deregisterNavigationIconInteractionHandler: function (evtType, handler) {
                if (_this.navIcon) {
                    _this.navIcon.removeEventListener(evtType, handler);
                }
            },
            notifyNavigationIconClicked: function () {
                _this.emit(MDCTopAppBarFoundation.strings.NAVIGATION_EVENT, {});
            },
            registerScrollHandler: function (handler) {
                return _this.scrollTarget &&
                    _this.scrollTarget.addEventListener('scroll', handler);
            },
            deregisterScrollHandler: function (handler) {
                return _this.scrollTarget &&
                    _this.scrollTarget.removeEventListener('scroll', handler);
            },
            registerResizeHandler: function (handler) {
                return _this.scrollTarget &&
                    _this.scrollTarget.addEventListener('resize', handler);
            },
            deregisterResizeHandler: function (handler) {
                return _this.scrollTarget &&
                    _this.scrollTarget.removeEventListener('resize', handler);
            },
            getViewportScrollY: function () {
                return _this.scrollTarget &&
                    _this.scrollTarget['pageYOffset' in _this.scrollTarget ? 'pageYOffset' : 'scrollTop'];
            },
            getTotalActionItems: function () {
                return _this.root.ref
                    ? _this.root.ref.querySelectorAll(MDCTopAppBarFoundation.strings.ACTION_ITEM_SELECTOR).length
                    : 0;
            }
        };
        var foundation;
        if (this.props.short) {
            foundation = new MDCShortTopAppBarFoundation(adapter);
        }
        else if (this.props.fixed) {
            foundation = new MDCFixedTopAppBarFoundation(adapter);
        }
        else {
            foundation = new MDCTopAppBarFoundation(adapter);
        }
        return foundation;
    };
    TopAppBarBase.prototype.setScrollHandler = function (target) {
        if (!this.foundation)
            return;
        this.foundation.destroyScrollHandler();
        this.scrollTarget = target;
        this.foundation.initScrollHandler();
    };
    TopAppBarBase.prototype.sync = function (props) {
        var _this = this;
        this.syncProp(props.scrollTarget, this.scrollTarget, function () {
            _this.scrollTarget = props.scrollTarget || _this.window;
            _this.setScrollHandler(_this.scrollTarget);
        });
    };
    TopAppBarBase.prototype.render = function () {
        var _a = this.props, onNav = _a.onNav, scrollTarget = _a.scrollTarget, rest = __rest(_a, ["onNav", "scrollTarget"]);
        return React.createElement(TopAppBarRoot, __assign({}, this.root.props(rest), { ref: this.root.setRef }));
    };
    TopAppBarBase.displayName = 'TopAppBar';
    return TopAppBarBase;
}(FoundationComponent));
export var TopAppBar = function (props) { return (React.createElement(TopAppBarBase, __assign({ key: props.short ? 'short' : props.fixed ? 'fixed' : 'top-app-bar' }, props))); };
/** A simplified syntax for creating an AppBar. */
var SimpleTopAppBar = /** @class */ (function (_super) {
    __extends(SimpleTopAppBar, _super);
    function SimpleTopAppBar() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    SimpleTopAppBar.prototype.render = function () {
        var _a = this.props, title = _a.title, actionItems = _a.actionItems, navigationIcon = _a.navigationIcon, startContent = _a.startContent, endContent = _a.endContent, rest = __rest(_a, ["title", "actionItems", "navigationIcon", "startContent", "endContent"]);
        return (React.createElement(TopAppBar, __assign({}, rest),
            React.createElement(TopAppBarRow, null,
                React.createElement(TopAppBarSection, { alignStart: true },
                    !!navigationIcon && (React.createElement(TopAppBarNavigationIcon, __assign({ icon: "menu" }, (typeof navigationIcon === 'boolean' ? {} : navigationIcon)))),
                    !!title && React.createElement(TopAppBarTitle, null, title),
                    startContent),
                (!!actionItems || endContent) && (React.createElement(TopAppBarSection, { alignEnd: true },
                    endContent,
                    !!actionItems &&
                        actionItems.map(function (actionItemProps, index) { return (React.createElement(TopAppBarActionItem, __assign({}, actionItemProps, { key: index }))); }))))));
    };
    SimpleTopAppBar.displayName = 'SimpleTopAppBar';
    return SimpleTopAppBar;
}(React.Component));
export { SimpleTopAppBar };
