var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) if (e.indexOf(p[i]) < 0)
            t[p[i]] = s[p[i]];
    return t;
};
import * as React from 'react';
import { withProviderContext } from '@rmwc/provider';
import { componentFactory, classNames, deprecationWarning } from '@rmwc/base';
/**
 * Given content, tries to figure out an appropriate strategy for it
 */
var processAutoStrategy = function (content) {
    // check for URLS
    if (typeof content === 'string' && content.includes('/')) {
        return 'url';
    }
    // handle JSX components
    if (React.isValidElement(content)) {
        return 'component';
    }
    // we dont know what it is, default to ligature for compat with material icons
    return 'ligature';
};
/**
 * Get the actual icon strategy to use
 */
export var getIconStrategy = function (content, strategy, providerStrategy) {
    strategy = strategy || providerStrategy || 'auto';
    if (strategy === 'auto') {
        return processAutoStrategy(content);
    }
    return strategy;
};
var renderLigature = function (_a) {
    var content = _a.content, rest = __rest(_a, ["content"]);
    return (React.createElement(IconRoot, __assign({}, rest), content));
};
var renderClassName = function (_a) {
    var content = _a.content, rest = __rest(_a, ["content"]);
    return React.createElement(IconRoot, __assign({}, rest));
};
var renderUrl = function (_a) {
    var content = _a.content, rest = __rest(_a, ["content"]);
    return (React.createElement(IconRoot, __assign({}, rest, { className: classNames(rest.className, 'rmwc-icon--image'), style: __assign({}, rest.style, { backgroundImage: "url(" + content + ")" }) })));
};
var renderComponent = function (_a) {
    var content = _a.content, rest = __rest(_a, ["content"]);
    return React.createElement(IconRoot, __assign({}, rest), content);
};
var iconRenderMap = {
    ligature: renderLigature,
    className: renderClassName,
    url: renderUrl,
    component: renderComponent,
    auto: undefined
};
var buildIconOptions = function (icon) {
    if (React.isValidElement(icon) || (icon && typeof icon !== 'object')) {
        return {
            icon: icon
        };
    }
    return icon;
};
var IconRoot = componentFactory({ displayName: 'IconRoot', tag: 'i' });
/** An Icon component. Most of these options can be set once globally, read the documentation on Provider for more info. */
export var Icon = withProviderContext()(function (_a) {
    var _b;
    var icon = _a.icon, deprecatedIconOption = _a.iconOptions, providerContext = _a.providerContext, rest = __rest(_a, ["icon", "iconOptions", "providerContext"]);
    // handle deprecation
    if (!!deprecatedIconOption) {
        var converted = __assign({ content: typeof icon === 'string' ? icon : "<MyComponent {...}/>" }, deprecatedIconOption);
        deprecationWarning("Icon component prop 'iconOptions' is deprecated. You options should now be passed directly to the 'icon' prop. I.E. icon={" + JSON.stringify(converted) + "}");
    }
    // Build icon options object
    var _c = __assign({}, buildIconOptions(icon), deprecatedIconOption), content = _c.icon, strategy = _c.strategy, prefix = _c.prefix, basename = _c.basename, render = _c.render, size = _c.size, optionsRest = __rest(_c, ["icon", "strategy", "prefix", "basename", "render", "size"]);
    // Get provider options
    var _d = providerContext.icon || {}, _e = _d.basename, providerBasename = _e === void 0 ? null : _e, _f = _d.prefix, providerPrefix = _f === void 0 ? null : _f, _g = _d.strategy, providerStrategy = _g === void 0 ? null : _g, _h = _d.render, providerRender = _h === void 0 ? null : _h;
    var contentToUse = content;
    var strategyToUse = getIconStrategy(contentToUse, strategy || null, providerStrategy || null);
    var prefixToUse = prefix || providerPrefix;
    var basenameToUse = basename === undefined ? providerBasename : basename;
    var iconClassName = strategyToUse === 'className' && typeof content === 'string'
        ? "" + String(prefixToUse) + content
        : null;
    var rendererFromMap = !!strategyToUse && iconRenderMap[strategyToUse];
    // For some reason TS thinks the render method will return undefined...
    var renderToUse = strategyToUse === 'custom'
        ? render || providerRender
        : rendererFromMap || null;
    if (!renderToUse) {
        console.error("Icon: rendering not implemented for " + String(strategyToUse) + ".");
        return null;
    }
    var rendered = renderToUse(__assign({}, rest, optionsRest, { content: contentToUse, className: classNames('rmwc-icon', basenameToUse, rest.className, optionsRest.className, iconClassName, (_b = {},
            _b["rmwc-icon--size-" + (size || '')] = !!size,
            _b)) }));
    // Unwrap double layered icons...
    if (rendered.props.children &&
        rendered.props.children.type &&
        ['Avatar', 'Icon'].includes(rendered.props.children.type.displayName)) {
        return React.cloneElement(rendered.props.children, __assign({}, rendered.props.children.props, rendered.props, { 
            // prevents an infinite loop
            children: rendered.props.children.props.children, className: classNames(rendered.props.className, rendered.props.children.props.className) }));
    }
    return rendered;
});
Icon.displayName = 'Icon';
