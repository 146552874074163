var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) if (e.indexOf(p[i]) < 0)
            t[p[i]] = s[p[i]];
    return t;
};
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { MDCRippleFoundation, util } from '@material/ripple';
import { classNames, FoundationComponent, deprecationWarning, matches } from '@rmwc/base';
import { withProviderContext } from '@rmwc/provider';
var RippleSurfaceContext = React.createContext({});
/** A component for adding Ripples to other components. */
var Ripple = /** @class */ (function (_super) {
    __extends(Ripple, _super);
    function Ripple(props) {
        var _this = _super.call(this, props) || this;
        _this.root = _this.createElement('root');
        _this.surface = _this.createElement('surface');
        _this.handleFocus = _this.handleFocus.bind(_this);
        _this.handleBlur = _this.handleBlur.bind(_this);
        _this.handleMouseDown = _this.handleMouseDown.bind(_this);
        _this.handleMouseUp = _this.handleMouseUp.bind(_this);
        _this.handleTouchStart = _this.handleTouchStart.bind(_this);
        _this.handleTouchEnd = _this.handleTouchEnd.bind(_this);
        _this.handleKeyDown = _this.handleKeyDown.bind(_this);
        _this.handleKeyUp = _this.handleKeyUp.bind(_this);
        return _this;
    }
    Ripple.prototype.getDefaultFoundation = function () {
        var _this = this;
        return new MDCRippleFoundation({
            browserSupportsCssVars: function () { return util.supportsCssVariables(window); },
            isUnbounded: function () { return !!_this.props.unbounded; },
            isSurfaceActive: function () {
                if (_this.root.ref) {
                    return matches(_this.root.ref, ':active');
                }
                return false;
            },
            isSurfaceDisabled: function () { return !!_this.props.disabled; },
            addClass: function (className) { return _this.surface.addClass(className); },
            removeClass: function (className) { return _this.surface.removeClass(className); },
            containsEventTarget: function (target) {
                return !!_this.root.ref && _this.root.ref.contains(target);
            },
            registerInteractionHandler: function (evtType, handler) { return _this.root.addEventListener(evtType, handler); },
            deregisterInteractionHandler: function (evtType, handler) { return _this.root.removeEventListener(evtType, handler); },
            registerDocumentInteractionHandler: function (evtType, handler) {
                return document.documentElement.addEventListener(evtType, handler, util.applyPassive());
            },
            deregisterDocumentInteractionHandler: function (evtType, handler) {
                return document.documentElement.removeEventListener(evtType, handler, util.applyPassive());
            },
            registerResizeHandler: function (handler) {
                return window.addEventListener('resize', handler);
            },
            deregisterResizeHandler: function (handler) { return window.removeEventListener('resize', handler); },
            updateCssVariable: function (varName, value) {
                return _this.surface.setStyle(varName, value);
            },
            computeBoundingRect: function () {
                return _this.root.ref
                    ? _this.root.ref.getBoundingClientRect()
                    : { width: 0, height: 0 };
            },
            getWindowPageOffset: function () { return ({
                x: window.pageXOffset,
                y: window.pageYOffset
            }); }
        });
    };
    Ripple.prototype.sync = function (props, prevProps) {
        this.root.setRef(ReactDOM.findDOMNode(this));
        if (props.unbounded !== prevProps.unbounded) {
            this.foundation.setUnbounded(!!props.unbounded);
        }
    };
    Ripple.prototype.handleFocus = function (evt) {
        this.props.onFocus && this.props.onFocus(evt);
        this.foundation.handleFocus();
    };
    Ripple.prototype.handleBlur = function (evt) {
        this.props.onBlur && this.props.onBlur(evt);
        this.foundation.handleBlur();
    };
    Ripple.prototype.handleMouseDown = function (evt) {
        this.props.onMouseDown && this.props.onMouseDown(evt);
        this.activateRipple(evt);
    };
    Ripple.prototype.handleMouseUp = function (evt) {
        this.props.onMouseUp && this.props.onMouseUp(evt);
        this.deactivateRipple(evt);
    };
    Ripple.prototype.handleTouchStart = function (evt) {
        this.props.onTouchStart && this.props.onTouchStart(evt);
        this.activateRipple(evt);
    };
    Ripple.prototype.handleTouchEnd = function (evt) {
        this.props.onTouchEnd && this.props.onTouchEnd(evt);
        this.deactivateRipple(evt);
    };
    Ripple.prototype.handleKeyDown = function (evt) {
        this.props.onKeyDown && this.props.onKeyDown(evt);
        this.activateRipple(evt);
    };
    Ripple.prototype.handleKeyUp = function (evt) {
        this.props.onKeyUp && this.props.onKeyUp(evt);
        this.deactivateRipple(evt);
    };
    Ripple.prototype.activateRipple = function (evt) {
        // https://reactjs.org/docs/events.html#event-pooling
        evt.persist();
        this.foundation.activate(evt);
    };
    Ripple.prototype.deactivateRipple = function (evt) {
        // https://reactjs.org/docs/events.html#event-pooling
        evt.persist();
        this.foundation.deactivate();
    };
    Ripple.prototype.render = function () {
        var _a = this.props, children = _a.children, className = _a.className, primary = _a.primary, accent = _a.accent, unbounded = _a.unbounded, surface = _a.surface, rest = __rest(_a, ["children", "className", "primary", "accent", "unbounded", "surface"]);
        var child = React.Children.only(children);
        if (!React.isValidElement(child)) {
            return null;
        }
        // This flag really determines a lot
        // is surfaceIsRoot is true, then the surface props are spread
        // to the underlying component, otherwise the only place they
        // can be picked up is by the context consumer
        var surfaceIsRoot = !surface || !unbounded;
        var unboundedProp = unbounded
            ? { 'data-mdc-ripple-is-unbounded': true }
            : {};
        var rippleSurfaceProps = surfaceIsRoot
            ? this.surface.props({ style: child.props.style })
            : {};
        var finalClassNames = classNames(className, rippleSurfaceProps.className, child.props.className, {
            'mdc-ripple-surface': typeof surface === 'boolean' ? surface : surface === undefined,
            'mdc-ripple-surface--primary': primary,
            'mdc-ripple-surface--accent': accent
        });
        // Fixes a ripple artifact issue
        // that is caused when clicking a button disables it
        // https://codesandbox.io/s/842vo56019
        if (rest.disabled) {
            finalClassNames = finalClassNames.replace('mdc-ripple-upgraded--background-focused', '');
        }
        // do some crazy props merging...
        var content = React.cloneElement(child, __assign({}, child.props, unboundedProp, this.root.props(__assign({}, rest, { style: child.props.style }, rippleSurfaceProps, { className: finalClassNames })), { onFocus: this.handleFocus, onBlur: this.handleBlur, onMouseDown: this.handleMouseDown, onMouseUp: this.handleMouseUp, onTouchStart: this.handleTouchStart, onTouchEnd: this.handleTouchEnd, onKeyDown: this.handleKeyDown, onKeyUp: this.handleKeyUp }));
        return (React.createElement(RippleSurfaceContext.Provider, { value: this.surface.props({ style: child.props.style }) }, content));
    };
    Ripple.shouldDebounce = true;
    Ripple.displayName = 'Ripple';
    return Ripple;
}(FoundationComponent));
export { Ripple };
export var RippleSurface = function (_a) {
    var className = _a.className, rest = __rest(_a, ["className"]);
    return (React.createElement(RippleSurfaceContext.Consumer, null, function (rippleSurfaceProps) { return (React.createElement("div", __assign({}, rest, rippleSurfaceProps, { className: className + " " + (rippleSurfaceProps.className || '') }))); }));
};
/**
 * HOC that adds ripples to any component
 */
export var withRipple = function (_a) {
    var _b = _a === void 0 ? {} : _a, defaultUnbounded = _b.unbounded, defaultAccent = _b.accent, defaultSurface = _b.surface;
    return function (Component) {
        var WithRippleComponent = withProviderContext()(React.forwardRef(function (_a, ref) {
            var providerContext = _a.providerContext, _b = _a.ripple, ripple = _b === void 0 ? providerContext.ripple : _b, rest = __rest(_a, ["providerContext", "ripple"]);
            var rippleOptions = typeof ripple !== 'object' ? {} : ripple;
            if (rest.accent || rest.unbounded || rest.surface) {
                deprecationWarning("'accent', 'unbounded', and 'surface' have been deprecated as indiviudal props. Please pass an options object to the ripple prop directly. ripple={{accent: true, unbounded: true}} ");
                rippleOptions.accent = rest.accent || rippleOptions.accent;
                rippleOptions.unbounded = rest.unbounded || rippleOptions.unbounded;
                rippleOptions.surface = rest.surface || rippleOptions.surface;
            }
            if (ripple) {
                return (React.createElement(Ripple, __assign({}, rest, { accent: rippleOptions.accent || defaultAccent, unbounded: rippleOptions.unbounded || defaultUnbounded, surface: rippleOptions.surface || defaultSurface }),
                    React.createElement(Component, __assign({}, rest, { ref: ref }))));
            }
            return React.createElement(Component, __assign({}, rest, { ref: ref }));
        }));
        WithRippleComponent.displayName = "withRipple(" + (Component.displayName ||
            'Unknown') + ")";
        return WithRippleComponent;
    };
};
