var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) if (e.indexOf(p[i]) < 0)
            t[p[i]] = s[p[i]];
    return t;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import * as React from 'react';
import { componentFactory, FoundationComponent } from '@rmwc/base';
import { MDCTabBarFoundation } from '@material/tab-bar';
import { TabScroller } from './tab-scroller';
import { TabBarContext } from './tab-bar-context';
export var TabBarRoot = componentFactory({
    displayName: 'TabBarRoot',
    tag: 'nav',
    classNames: function (props) { return [
        'mdc-tab-bar',
        {
            'mdc-tab-scroller__scroll-frame__tabs': props.isTabScroller
        }
    ]; },
    consumeProps: ['isTabScroller']
});
/** The TabBar component */
var TabBar = /** @class */ (function (_super) {
    __extends(TabBar, _super);
    function TabBar(props) {
        var _this = _super.call(this, props) || this;
        _this.root = _this.createElement('root');
        _this.currentActiveTabIndex = _this.props.activeTabIndex || 0;
        _this.tabScroller = null;
        _this.tabList = [];
        _this.contextApi = {
            onTabInteraction: function (evt) {
                return _this.handleTabInteraction(evt);
            },
            registerTab: function (tab) { return _this.tabList.push(tab); },
            unregisterTab: function (tab) {
                return _this.tabList.splice(_this.tabList.indexOf(tab), 1);
            }
        };
        _this.handleKeyDown = _this.handleKeyDown.bind(_this);
        _this.handleTabInteraction = _this.handleTabInteraction.bind(_this);
        return _this;
    }
    TabBar.prototype.componentDidMount = function () {
        _super.prototype.componentDidMount.call(this);
        // This corrects an issue where passing in 0 or no activeTabIndex
        // causes the first tab of the set to not be active
        // to make this even more annoying, Tabs focus by default
        // restore the focus and scroll position after we activate the tab
        var activeElement = window.document.activeElement;
        var _a = __read([window.scrollX, window.scrollY], 2), scrollX = _a[0], scrollY = _a[1];
        //activate the tab
        this.foundation.adapter_.activateTabAtIndex(this.props.activeTabIndex || 0, this.foundation.adapter_.getTabIndicatorClientRectAtIndex(undefined));
        this.foundation.scrollIntoView(this.props.activeTabIndex || 0);
        // restore focus and scroll
        activeElement && activeElement.focus();
        window.scrollTo(scrollX, scrollY);
    };
    TabBar.prototype.activateTab = function (index) {
        var _this = this;
        var foundation = this.foundation;
        this.currentActiveTabIndex = index;
        var previousActiveIndex = foundation.adapter_.getPreviousActiveTabIndex();
        if (!foundation.indexIsInRange_(index) || index === previousActiveIndex) {
            return;
        }
        foundation.adapter_.notifyTabActivated(index);
        setTimeout(function () {
            if (_this.props.activeTabIndex === index ||
                _this.props.activeTabIndex === undefined) {
                foundation.adapter_.deactivateTabAtIndex(previousActiveIndex);
                foundation.adapter_.activateTabAtIndex(index, foundation.adapter_.getTabIndicatorClientRectAtIndex(previousActiveIndex));
                foundation.scrollIntoView(index);
            }
        });
    };
    TabBar.prototype.getDefaultFoundation = function () {
        var _this = this;
        return new MDCTabBarFoundation(
        /** @type {!MDCTabBarAdapter} */ ({
            scrollTo: function (scrollX) {
                _this.tabScroller && _this.tabScroller.scrollTo(scrollX);
            },
            incrementScroll: function (scrollXIncrement) {
                return _this.tabScroller &&
                    _this.tabScroller.incrementScroll(scrollXIncrement);
            },
            getScrollPosition: function () {
                return _this.tabScroller ? _this.tabScroller.getScrollPosition() : 0;
            },
            getScrollContentWidth: function () {
                return _this.tabScroller ? _this.tabScroller.getScrollContentWidth() : 0;
            },
            getOffsetWidth: function () { return (_this.root.ref ? _this.root.ref.offsetWidth : 0); },
            isRTL: function () {
                return !!_this.root.ref &&
                    window
                        .getComputedStyle(_this.root.ref)
                        .getPropertyValue('direction') === 'rtl';
            },
            setActiveTab: function (index) { return _this.activateTab(index); },
            activateTabAtIndex: function (index, clientRect) {
                _this.tabList[index] && _this.tabList[index].activate(clientRect);
            },
            deactivateTabAtIndex: function (index) {
                return _this.tabList[index] && _this.tabList[index].deactivate();
            },
            focusTabAtIndex: function (index) { return _this.tabList[index].focus(); },
            getTabIndicatorClientRectAtIndex: function (index) {
                return _this.tabList[index] &&
                    _this.tabList[index].computeIndicatorClientRect();
            },
            getTabDimensionsAtIndex: function (index) {
                return _this.tabList[index] && _this.tabList[index].computeDimensions();
            },
            getPreviousActiveTabIndex: function () {
                for (var i = 0; i < _this.tabList.length; i++) {
                    if (_this.tabList[i].active) {
                        return i;
                    }
                }
                return -1;
            },
            getFocusedTabIndex: function () {
                var tabElements = _this.getTabElements();
                var activeElement = document.activeElement;
                return tabElements ? tabElements.indexOf(activeElement) : -1;
            },
            getIndexOfTabById: function (id) {
                for (var i = 0; i < _this.tabList.length; i++) {
                    if (_this.tabList[i].id === id) {
                        return i;
                    }
                }
                return -1;
            },
            getTabListLength: function () { return _this.tabList.length; },
            notifyTabActivated: function (index) {
                return _this.emit('onActivate', { index: index }, true);
            }
        }));
    };
    TabBar.prototype.sync = function (props, prevProps) {
        // this will re-activate the appropriate tabs if they get-rendered
        if (props.activeTabIndex !== prevProps.activeTabIndex &&
            props.activeTabIndex !== this.currentActiveTabIndex) {
            typeof props.activeTabIndex === 'number' &&
                this.activateTab(props.activeTabIndex);
        }
    };
    TabBar.prototype.getTabElements = function () {
        return [].slice.call(this.root.ref &&
            this.root.ref.querySelectorAll(MDCTabBarFoundation.strings.TAB_SELECTOR));
    };
    TabBar.prototype.handleTabInteraction = function (evt) {
        this.foundation.handleTabInteraction(evt);
    };
    TabBar.prototype.handleKeyDown = function (evt) {
        this.props.onKeyDown && this.props.onKeyDown(evt);
        this.foundation.handleKeyDown(evt);
    };
    TabBar.prototype.render = function () {
        var _this = this;
        var _a = this.props, children = _a.children, activeTabIndex = _a.activeTabIndex, onActivate = _a.onActivate, rest = __rest(_a, ["children", "activeTabIndex", "onActivate"]);
        return (React.createElement(TabBarContext.Provider, { value: this.contextApi },
            React.createElement(TabBarRoot, __assign({}, rest, { ref: this.root.setRef, onKeyDown: this.handleKeyDown }),
                React.createElement(TabScroller, { ref: function (api) { return (_this.tabScroller = api); } }, children))));
    };
    TabBar.displayName = 'TabBar';
    return TabBar;
}(FoundationComponent));
export { TabBar };
