var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) if (e.indexOf(p[i]) < 0)
            t[p[i]] = s[p[i]];
    return t;
};
import * as React from 'react';
import { MDCListFoundation } from '@material/list';
import { FoundationComponent, componentFactory, matches } from '@rmwc/base';
/** A List Component */
var ListRoot = componentFactory({
    displayName: 'ListRoot',
    defaultProps: {
        dense: undefined,
        twoLine: undefined,
        avatarList: undefined,
        nonInteractive: undefined
    },
    classNames: function (props) { return [
        'mdc-list',
        {
            'mdc-list--dense': props.dense,
            'mdc-list--two-line': props.twoLine,
            'mdc-list--avatar-list': props.avatarList,
            'mdc-list--non-interactive': props.nonInteractive
        }
    ]; },
    consumeProps: ['dense', 'twoLine', 'avatarList', 'nonInteractive', 'onAction']
});
/** A List Component */
var List = /** @class */ (function (_super) {
    __extends(List, _super);
    function List(props) {
        var _this = _super.call(this, props) || this;
        _this.root = _this.createElement('root');
        _this.handleClick = _this.handleClick.bind(_this);
        _this.handleKeydown = _this.handleKeydown.bind(_this);
        _this.handleFocusIn = _this.handleFocusIn.bind(_this);
        _this.handleFocusOut = _this.handleFocusOut.bind(_this);
        return _this;
    }
    Object.defineProperty(List, "cssClasses", {
        get: function () {
            return MDCListFoundation.cssClasses;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(List.prototype, "listElements", {
        get: function () {
            if (this.root.ref) {
                return [].slice.call(this.root.ref.querySelectorAll(MDCListFoundation.strings.ENABLED_ITEMS_SELECTOR));
            }
            return [];
        },
        enumerable: true,
        configurable: true
    });
    List.prototype.componentDidMount = function () {
        _super.prototype.componentDidMount.call(this);
        this.foundation.layout();
    };
    List.prototype.focusItemAtIndex = function (index) {
        this.foundation.adapter_.focusItemAtIndex(index);
    };
    List.prototype.getDefaultFoundation = function () {
        var _this = this;
        return new MDCListFoundation(
        /** @type {!MDCListAdapter} */ (Object.assign({
            getListItemCount: function () { return _this.listElements.length; },
            getFocusedElementIndex: function () {
                return _this.listElements.indexOf(document.activeElement);
            },
            setAttributeForElementIndex: function (index, attr, value) {
                // This value is getting set and never getting set back
                // This is causing list items to be un-tabbable
                if (attr === 'tabindex' && value === -1) {
                    return;
                }
                var element = _this.listElements[index];
                if (element) {
                    element.setAttribute(attr, String(value));
                }
            },
            removeAttributeForElementIndex: function (index, attr) {
                var element = _this.listElements[index];
                if (element) {
                    element.removeAttribute(attr);
                }
            },
            addClassForElementIndex: function (index, className) {
                var element = _this.listElements[index];
                if (element) {
                    element.classList.add(className);
                }
            },
            removeClassForElementIndex: function (index, className) {
                var element = _this.listElements[index];
                if (element) {
                    element.classList.remove(className);
                }
            },
            focusItemAtIndex: function (index) {
                var element = _this.listElements[index];
                if (element) {
                    element.focus();
                }
            },
            setTabIndexForListItemChildren: function (listItemIndex, tabIndexValue) {
                var element = _this.listElements[listItemIndex];
                var listItemChildren = [].slice.call(element.querySelectorAll(MDCListFoundation.strings.CHILD_ELEMENTS_TO_TOGGLE_TABINDEX));
                listItemChildren.forEach(function (ele) {
                    return ele.setAttribute('tabindex', String(tabIndexValue));
                });
            },
            hasCheckboxAtIndex: function (index) {
                var listItem = _this.listElements[index];
                return !!listItem.querySelector(MDCListFoundation.strings.CHECKBOX_SELECTOR);
            },
            hasRadioAtIndex: function (index) {
                var listItem = _this.listElements[index];
                return !!listItem.querySelector(MDCListFoundation.strings.RADIO_SELECTOR);
            },
            isCheckboxCheckedAtIndex: function (index) {
                var listItem = _this.listElements[index];
                var toggleEl = listItem.querySelector(MDCListFoundation.strings.CHECKBOX_SELECTOR);
                return toggleEl ? toggleEl.checked : false;
            },
            setCheckedCheckboxOrRadioAtIndex: function (index, isChecked) {
                var listItem = _this.listElements[index];
                var toggleEl = listItem.querySelector(MDCListFoundation.strings.CHECKBOX_RADIO_SELECTOR);
                if (toggleEl) {
                    toggleEl.checked = isChecked;
                    var event_1 = document.createEvent('Event');
                    event_1.initEvent('change', true, true);
                    toggleEl.dispatchEvent(event_1);
                }
            },
            notifyAction: function (index) {
                _this.emit('onAction', index);
            },
            isFocusInsideList: function () {
                return (_this.root.ref && _this.root.ref.contains(document.activeElement));
            }
        })));
    };
    /**
     * Used to figure out which list item this event is targetting. Or returns -1 if
     * there is no list item
     */
    List.prototype.getListItemIndex = function (evt) {
        var eventTarget = evt.target;
        var index = -1;
        // Find the first ancestor that is a list item or the list.
        while (eventTarget &&
            !eventTarget.classList.contains(MDCListFoundation.cssClasses.LIST_ITEM_CLASS) &&
            !eventTarget.classList.contains(MDCListFoundation.cssClasses.ROOT)) {
            eventTarget = eventTarget.parentElement;
        }
        // Get the index of the element if it is a list item.
        if (eventTarget &&
            eventTarget.classList.contains(MDCListFoundation.cssClasses.LIST_ITEM_CLASS)) {
            index = this.listElements.indexOf(eventTarget);
        }
        return index;
    };
    List.prototype.handleClick = function (evt) {
        this.props.onClick && this.props.onClick(evt);
        var index = this.getListItemIndex(evt);
        // Toggle the checkbox only if it's not the target of the event, or the checkbox will have 2 change events.
        var toggleCheckbox = !matches(evt.target, MDCListFoundation.strings.CHECKBOX_RADIO_SELECTOR);
        this.foundation.handleClick(index, toggleCheckbox);
    };
    List.prototype.handleKeydown = function (evt) {
        this.props.onKeyDown && this.props.onKeyDown(evt);
        var index = this.getListItemIndex(evt);
        if (index >= 0) {
            this.foundation.handleKeydown(evt, evt.target instanceof Element &&
                evt.target.classList.contains(MDCListFoundation.cssClasses.LIST_ITEM_CLASS), index);
        }
    };
    List.prototype.handleFocusIn = function (evt) {
        this.props.onFocus && this.props.onFocus(evt);
        this.foundation.handleFocusIn(evt, this.getListItemIndex(evt));
    };
    List.prototype.handleFocusOut = function (evt) {
        this.props.onBlur && this.props.onBlur(evt);
        this.foundation.handleFocusOut(evt, this.getListItemIndex(evt));
    };
    List.prototype.render = function () {
        var rest = __rest(this.props, []);
        return (React.createElement(ListRoot, __assign({}, rest, { ref: this.root.setRef, onClick: this.handleClick, onKeyDown: this.handleKeydown, onFocus: this.handleFocusIn, onBlur: this.handleFocusOut })));
    };
    return List;
}(FoundationComponent));
export { List };
