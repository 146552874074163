var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as React from 'react';
import classNames from 'classnames';
export var wrapChild = function (props) {
    var child = React.Children.only(props.children);
    return React.cloneElement(child, __assign({}, props, child.props, { 
        // @ts-ignore
        className: classNames(props.className, child.props.className) }));
};
