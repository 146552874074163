var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) if (e.indexOf(p[i]) < 0)
            t[p[i]] = s[p[i]];
    return t;
};
import * as React from 'react';
import { componentFactory, classNames } from '@rmwc/base';
var CollapsibleRoot = componentFactory({
    displayName: 'CollapsibleRoot',
    classNames: ['rmwc-collapsible-list']
});
var possiblyFocusElement = function (el) {
    if (!el)
        return false;
    var tabIndex = el.getAttribute('tabindex');
    if (tabIndex && Number(tabIndex) >= 0) {
        el.focus();
        return true;
    }
    return false;
};
var getNextSibling = function (el, isBack) {
    if (!el)
        return null;
    var next = isBack ? el.previousElementSibling : el.nextElementSibling;
    if (next === null) {
        return getNextSibling(el.parentElement, isBack);
    }
    return next;
};
/** A collapsible list component. */
var CollapsibleList = /** @class */ (function (_super) {
    __extends(CollapsibleList, _super);
    function CollapsibleList(props) {
        var _this = _super.call(this, props) || this;
        _this.childContainer = null;
        _this.root = null;
        _this.state = {
            open: !!_this.props.startOpen || !!_this.props.open,
            childrenStyle: {}
        };
        _this.handleClick = _this.handleClick.bind(_this);
        _this.handleKeydown = _this.handleKeydown.bind(_this);
        _this.handleFocus = _this.handleFocus.bind(_this);
        return _this;
    }
    CollapsibleList.getDerivedStateFromProps = function (props, state) {
        if (props.open !== undefined && props.open !== state.open) {
            return __assign({}, state, { open: props.open });
        }
        return state;
    };
    CollapsibleList.prototype.componentDidMount = function () {
        this.state.open && this.toggleOpen(this.state.open);
    };
    CollapsibleList.prototype.correctFocus = function (back) {
        var _this = this;
        window.requestAnimationFrame(function () {
            if (!_this.state.open &&
                _this.root &&
                _this.root.contains(document.activeElement)) {
                var sibling = getNextSibling(_this.root, back);
                if (possiblyFocusElement(sibling)) {
                    return;
                }
                if (sibling) {
                    var els = sibling.querySelectorAll('[tabindex]');
                    for (var i = 0; i < els.length; i++) {
                        if (possiblyFocusElement(els[i])) {
                            break;
                        }
                    }
                }
            }
        });
    };
    CollapsibleList.prototype.toggleOpen = function (isOpen) {
        var _this = this;
        var _a = this.props, onOpen = _a.onOpen, onClose = _a.onClose;
        var childrenStyle = {
            maxHeight: this.childContainer
                ? this.childContainer.offsetHeight + "px"
                : '0px'
        };
        this.setState({ open: isOpen, childrenStyle: childrenStyle }, function () {
            if (_this.state.open) {
                onOpen && onOpen();
                setTimeout(function () {
                    if (_this.state.open) {
                        _this.setState({
                            childrenStyle: {
                                maxHeight: 'none'
                            }
                        });
                    }
                }, 300);
            }
            else {
                onClose && onClose();
                window.requestAnimationFrame(function () {
                    _this.setState({
                        childrenStyle: {}
                    });
                });
            }
        });
    };
    CollapsibleList.prototype.handleClick = function (evt) {
        // call events that might have been on the handle
        var handle = this.props.handle;
        handle.props.onClick && handle.props.onClick(evt);
        this.toggleOpen(!this.state.open);
    };
    CollapsibleList.prototype.handleKeydown = function (evt) {
        // call events that might have been on the handle
        var handle = this.props.handle;
        handle.props.onKeyDown && handle.props.onKeyDown(evt);
        switch (evt.which) {
            case 13:
                this.toggleOpen(!this.state.open);
                return;
            case 39:
                this.toggleOpen(true);
                return;
            case 38:
            case 40:
            case 9:
                var isBack = evt.shiftKey || evt.which === 38;
                this.correctFocus(isBack);
                return;
            case 37:
                this.toggleOpen(false);
                return;
            default:
                break;
        }
    };
    CollapsibleList.prototype.handleFocus = function (evt) {
        if (!this.state.open &&
            this.root &&
            this.childContainer &&
            this.childContainer.contains(document.activeElement)) {
            var el = this.root.querySelector('.rmwc-collapsible-list__handle .mdc-list-item');
            el && el.focus();
        }
    };
    CollapsibleList.prototype.render = function () {
        var _this = this;
        var _a;
        var _b = this.props, children = _b.children, handle = _b.handle, onOpen = _b.onOpen, onClose = _b.onClose, openProp = _b.open, startOpen = _b.startOpen, rest = __rest(_b, ["children", "handle", "onOpen", "onClose", "open", "startOpen"]);
        var _c = this.state, open = _c.open, childrenStyle = _c.childrenStyle;
        return (React.createElement(CollapsibleRoot, __assign({}, rest, { onFocus: this.handleFocus, ref: function (el) { return (_this.root = el); }, className: classNames('rmwc-collapsible-list', (_a = {},
                _a['rmwc-collapsible-list--open'] = open,
                _a)) }),
            React.createElement("div", { className: "rmwc-collapsible-list__handle" }, React.cloneElement(handle, __assign({}, handle.props, { onClick: this.handleClick, onKeyDown: this.handleKeydown }))),
            React.createElement("div", { className: "rmwc-collapsible-list__children", style: childrenStyle },
                React.createElement("div", { className: "rmwc-collapsible-list__children-inner", ref: function (el) { return (_this.childContainer = el); } }, children))));
    };
    CollapsibleList.displayName = 'CollapsibleList';
    return CollapsibleList;
}(React.Component));
export { CollapsibleList };
