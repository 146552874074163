var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) if (e.indexOf(p[i]) < 0)
            t[p[i]] = s[p[i]];
    return t;
};
import * as React from 'react';
import { componentFactory, toDashCase, parseThemeOptions, wrapChild } from '@rmwc/base';
import { getAutoColorsForTheme } from './utils';
var ThemeRoot = componentFactory({
    displayName: 'ThemeRoot',
    tag: 'span'
});
/** A Theme Component. */
export var Theme = function (_a) {
    var use = _a.use, wrap = _a.wrap, rest = __rest(_a, ["use", "wrap"]);
    if (wrap) {
        return wrapChild(__assign({}, rest, { className: parseThemeOptions(use).join(' ') }));
    }
    return React.createElement(ThemeRoot, __assign({ theme: use }, rest));
};
Theme.displayName = 'Theme';
/** A ThemeProvider. This sets theme colors for its child tree. */
var ThemeProvider = /** @class */ (function (_super) {
    __extends(ThemeProvider, _super);
    function ThemeProvider() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.prevOpts_ = '';
        _this.colors_ = {};
        return _this;
    }
    Object.defineProperty(ThemeProvider.prototype, "colors", {
        get: function () {
            var _this = this;
            // implement some caching to prevent the color checking from being called over and over again.
            var parsed = JSON.stringify(this.props.options);
            if (parsed !== this.prevOpts_) {
                this.prevOpts_ = parsed;
                var processedColors = Object.keys(this.props.options).reduce(function (acc, key) {
                    var val = _this.props.options[key];
                    key = key.startsWith('--') ? key : "--mdc-theme-" + toDashCase(key);
                    acc[key] = val;
                    return acc;
                }, {});
                this.colors_ = getAutoColorsForTheme(processedColors);
            }
            return this.colors_;
        },
        enumerable: true,
        configurable: true
    });
    ThemeProvider.prototype.render = function () {
        var _a = this.props, options = _a.options, _b = _a.style, style = _b === void 0 ? {} : _b, wrap = _a.wrap, rest = __rest(_a, ["options", "style", "wrap"]);
        // Casting styles to avoid TSX error
        var tsxSafeStyle = style;
        var themeStyles = __assign({}, tsxSafeStyle, this.colors);
        if (wrap && rest.children) {
            var child = React.Children.only(rest.children);
            if (!React.isValidElement(child)) {
                return;
            }
            var childStyle = child.props.style || {};
            return React.cloneElement(child, __assign({}, child.props, rest, { style: __assign({}, themeStyles, childStyle) }));
        }
        return React.createElement("div", __assign({}, rest, { style: themeStyles }));
    };
    ThemeProvider.displayName = 'ThemeProvider';
    return ThemeProvider;
}(React.Component));
export { ThemeProvider };
