var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as React from 'react';
import { MDCSlidingTabIndicatorFoundation } from '@material/tab-indicator';
import { FoundationComponent } from '@rmwc/base';
var TabIndicator = /** @class */ (function (_super) {
    __extends(TabIndicator, _super);
    function TabIndicator() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.root = _this.createElement('root');
        _this.content = _this.createElement('content');
        return _this;
    }
    TabIndicator.prototype.getDefaultFoundation = function () {
        var _this = this;
        return new MDCSlidingTabIndicatorFoundation({
            addClass: function (className) {
                _this.root.addClass(className);
            },
            removeClass: function (className) {
                _this.root.removeClass(className);
            },
            computeContentClientRect: function () {
                return _this.content.ref
                    ? _this.content.ref.getBoundingClientRect()
                    : {};
            },
            setContentStyleProperty: function (prop, value) {
                _this.content.setStyle(prop, value);
            }
        });
    };
    TabIndicator.prototype.activate = function (previousIndicatorClientRect) {
        this.foundation.activate(previousIndicatorClientRect);
    };
    TabIndicator.prototype.deactivate = function () {
        this.foundation.deactivate();
    };
    TabIndicator.prototype.computeContentClientRect = function () {
        return this.foundation.computeContentClientRect();
    };
    TabIndicator.prototype.render = function () {
        return (React.createElement("span", __assign({}, this.root.props({ className: 'mdc-tab-indicator' })),
            React.createElement("span", __assign({ ref: this.content.setRef }, this.content.props({}), { className: "mdc-tab-indicator__content mdc-tab-indicator__content--underline" }))));
    };
    return TabIndicator;
}(FoundationComponent));
export { TabIndicator };
