var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) if (e.indexOf(p[i]) < 0)
            t[p[i]] = s[p[i]];
    return t;
};
import * as React from 'react';
import { componentFactory } from '@rmwc/base';
var GridRoot = componentFactory({
    displayName: 'GridRoot',
    classNames: function (props) {
        var _a;
        return [
            'mdc-layout-grid',
            (_a = {},
                _a["mdc-layout-grid__cell--align-" + (props.align || '')] = props.align !== undefined,
                _a['mdc-layout-grid--fixed-column-width'] = props.fixedColumnWidth,
                _a)
        ];
    },
    consumeProps: ['fixedColumnWidth', 'align']
});
/** A Grid component */
export var Grid = function (_a) {
    var children = _a.children, rest = __rest(_a, ["children"]);
    var child = children;
    var needsInnerGrid = !(child &&
        typeof child === 'object' &&
        (child.type || {}).displayName === 'GridInner');
    return (React.createElement(GridRoot, __assign({}, rest), !!needsInnerGrid ? React.createElement(GridInner, null, children) : children));
};
Grid.displayName = 'Grid';
/** A Grid cell */
export var GridCell = componentFactory({
    displayName: 'GridCell',
    classNames: function (props) {
        var _a;
        return [
            'mdc-layout-grid__cell',
            (_a = {},
                _a["mdc-layout-grid__cell--order-" + (props.order || '')] = props.order !== undefined,
                _a["mdc-layout-grid__cell--align-" + (props.align || '')] = props.align !== undefined,
                _a["mdc-layout-grid__cell--span-" + (props.span || '')] = props.span !== undefined,
                _a["mdc-layout-grid__cell--span-" + (props.phone || '') + "-phone"] = props.phone !== undefined,
                _a["mdc-layout-grid__cell--span-" + (props.tablet || '') + "-tablet"] = props.tablet !== undefined,
                _a["mdc-layout-grid__cell--span-" + (props.desktop || '') + "-desktop"] = props.desktop !== undefined,
                _a)
        ];
    },
    consumeProps: ['span', 'phone', 'tablet', 'desktop', 'order', 'align']
});
/** By default, an inner grid component is included inside of <Grid>. Use GridInner when doing nested Grids. */
export var GridInner = componentFactory({
    displayName: 'GridInner',
    classNames: ['mdc-layout-grid__inner']
});
