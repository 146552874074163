import { componentFactory } from '@rmwc/base';
/** The root of the Image List. */
export var ImageList = componentFactory({
    displayName: 'ImageList',
    tag: 'ul',
    classNames: function (props) { return [
        'mdc-image-list',
        {
            'mdc-image-list--masonry': props.masonry,
            'mdc-image-list--with-text-protection': props.withTextProtection
        }
    ]; },
    consumeProps: ['masonry', 'withTextProtection']
});
/** Indicates each item in an Image List. */
export var ImageListItem = componentFactory({
    displayName: 'ImageListItem',
    tag: 'li',
    classNames: ['mdc-image-list__item']
});
/** Optional. Parent of each item’s image element, responsible for constraining aspect ratio. This element may be omitted entirely if images are already sized to the correct aspect ratio. */
export var ImageListImageAspectContainer = componentFactory({
    displayName: 'ImageListImageAspectContainer',
    classNames: ['mdc-image-list__image-aspect-container']
});
/** Indicates the image element in each item. */
export var ImageListImage = componentFactory({
    displayName: 'ImageListImage',
    tag: 'img',
    classNames: ['mdc-image-list__image']
});
/** Optional. Indicates the area within each item containing the supporting text label, if the Image List contains text labels. */
export var ImageListSupporting = componentFactory({
    displayName: 'ImageListSupporting',
    classNames: ['mdc-image-list__supporting']
});
/** Optional. Indicates the text label in each item, if the Image List contains text labels. */
export var ImageListLabel = componentFactory({
    displayName: 'ImageListLabel',
    classNames: ['mdc-image-list__label']
});
