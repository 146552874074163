var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as React from 'react';
import { componentFactory, deprecationWarning } from '@rmwc/base';
import { withRipple } from '@rmwc/ripple';
import { Button } from '@rmwc/button';
import { IconButton } from '@rmwc/icon-button';
/** A Card Component */
export var Card = componentFactory({
    displayName: 'Card',
    classNames: function (props) { return [
        'mdc-card',
        {
            'mdc-card--outlined': props.outlined
        }
    ]; },
    consumeProps: ['outlined']
});
/** Media area that displays a custom background-image with background-size: cover */
export var CardMedia = componentFactory({
    displayName: 'CardMedia',
    tag: 'section',
    classNames: function (props) { return [
        'mdc-card__media',
        {
            'mdc-card__media--square': props.square,
            'mdc-card__media--16-9': props.sixteenByNine
        }
    ]; },
    consumeProps: ['square', 'sixteenByNine']
});
/** An absolutely-positioned box the same size as the media area, for displaying a title or icon on top of the background-image */
export var CardMediaContent = componentFactory({
    displayName: 'CardMediaContent',
    classNames: ['mdc-card__media-content']
});
/** The main clickable area for the primary content of the card */
export var CardPrimaryAction = withRipple({
    surface: false
})(componentFactory({
    displayName: 'CardPrimaryAction',
    classNames: ['mdc-card__primary-action']
}));
/** Row containing action buttons and/or icons */
export var CardActions = componentFactory({
    displayName: 'CardActions',
    tag: 'section',
    classNames: function (props) { return [
        'mdc-card__actions',
        { 'mdc-card__actions--full-bleed': props.fullBleed }
    ]; },
    consumeProps: ['fullBleed']
});
/** A group of action buttons, displayed on the left side of the card (in LTR), adjacent to CardActionIcons */
export var CardActionButtons = componentFactory({
    displayName: 'CardActionButtons',
    classNames: ['mdc-card__action-buttons']
});
/** A group of supplemental action icons, displayed on the right side of the card (in LTR), adjacent to CardActionButtons */
export var CardActionIcons = componentFactory({
    displayName: 'CardActionIcons',
    classNames: ['mdc-card__action-icons']
});
/** A card action Icon */
export var CardActionIcon = componentFactory({
    displayName: 'CardActionIcon',
    tag: IconButton,
    classNames: ['mdc-card__action', 'mdc-card__action--icon']
});
/** A card action Button */
export var CardActionButton = componentFactory({
    displayName: 'CardActionButton',
    tag: Button,
    classNames: ['mdc-card__action', 'mdc-card__action--button']
});
/** DEPRECATED: A Card action Button. Depending on the props that are passed, this will either render an instance of the Button component, or the IconButton component. */
export var CardAction = function (props) {
    /* istanbul ignore next */
    deprecationWarning('The CardAction component has been removed in favor of CardActionIcon and CardActionButton due to impossible type intersections of IconButtonProps and ButtonProps');
    return props.icon ? (
    // @ts-ignore
    React.createElement(CardActionIcon, __assign({}, props))) : (React.createElement(CardActionButton, __assign({}, props)));
};
CardAction.displayName = 'CardAction';
