var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) if (e.indexOf(p[i]) < 0)
            t[p[i]] = s[p[i]];
    return t;
};
import * as React from 'react';
import { MDCDialogFoundation } from '@material/dialog';
import { FoundationComponent, componentFactory, createFocusTrap, closest, matches } from '@rmwc/base';
import { Button } from '@rmwc/button';
var isScrollable = function (el) {
    return el.scrollHeight > el.offsetHeight;
};
var areTopsMisaligned = function (els) {
    var tops = new Set();
    [].forEach.call(els, function (el) { return tops.add(el.offsetTop); });
    return tops.size > 1;
};
var DialogRoot = componentFactory({
    displayName: 'DialogRoot',
    defaultProps: {
        role: 'alertdialog',
        'aria-modal': true
    },
    classNames: ['mdc-dialog']
});
var DialogScrim = /** @class */ (function (_super) {
    __extends(DialogScrim, _super);
    function DialogScrim() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    DialogScrim.prototype.shouldComponentUpdate = function (nextProps) {
        return this.props.disableInteraction !== nextProps.disableInteraction;
    };
    DialogScrim.prototype.render = function () {
        var style = this.props.disableInteraction
            ? { pointerEvents: 'none' }
            : {};
        return React.createElement("div", { className: "mdc-dialog__scrim", style: style });
    };
    return DialogScrim;
}(React.Component));
/** The Dialog title. */
export var DialogTitle = componentFactory({
    displayName: 'DialogTitle',
    tag: 'h2',
    classNames: ['mdc-dialog__title']
});
/** The Dialog content. */
export var DialogContent = componentFactory({
    displayName: 'DialogContent',
    classNames: ['mdc-dialog__content']
});
/** Actions container for the Dialog. */
export var DialogActions = componentFactory({
    displayName: 'DialogActions',
    classNames: ['mdc-dialog__actions']
});
/** Action buttons for the Dialog. */
var DialogButton = /** @class */ (function (_super) {
    __extends(DialogButton, _super);
    function DialogButton() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    DialogButton.prototype.render = function () {
        var _a = this.props, _b = _a.action, action = _b === void 0 ? '' : _b, className = _a.className, isDefaultAction = _a.isDefaultAction, rest = __rest(_a, ["action", "className", "isDefaultAction"]);
        return (React.createElement(Button, __assign({}, rest, { "data-mdc-dialog-action": action, className: [
                className,
                'mdc-dialog__button',
                isDefaultAction && 'mdc-dialog__button--default'
            ]
                .filter(Boolean)
                .join(' ') })));
    };
    DialogButton.displayName = 'DialogButton';
    return DialogButton;
}(React.Component));
export { DialogButton };
/** A Dialog component. */
var Dialog = /** @class */ (function (_super) {
    __extends(Dialog, _super);
    function Dialog(props) {
        var _this = _super.call(this, props) || this;
        _this.root = _this.createElement('root');
        _this.container = null;
        _this.content = null;
        _this.buttons = null;
        _this.defaultButton = null;
        _this.focusTrap = null;
        _this.handleDocumentKeydown = function () { };
        _this.handleInteraction = _this.handleInteraction.bind(_this);
        return _this;
    }
    Dialog.prototype.open = function () {
        if (!this.foundation.isOpen()) {
            document.addEventListener('keydown', this.handleDocumentKeydown);
            this.foundation.open();
        }
    };
    Dialog.prototype.close = function () {
        if (this.foundation.isOpen()) {
            document.removeEventListener('keydown', this.handleDocumentKeydown);
            this.foundation.close();
        }
    };
    Dialog.prototype.componentDidMount = function () {
        _super.prototype.componentDidMount.call(this);
        this.container =
            this.root.ref &&
                this.root.ref.querySelector(MDCDialogFoundation.strings.CONTAINER_SELECTOR);
        this.content =
            this.root.ref &&
                this.root.ref.querySelector(MDCDialogFoundation.strings.CONTENT_SELECTOR);
        this.buttons =
            this.root.ref &&
                [].slice.call(this.root.ref.querySelectorAll(MDCDialogFoundation.strings.BUTTON_SELECTOR));
        this.defaultButton =
            this.root.ref &&
                this.root.ref.querySelector(MDCDialogFoundation.strings.DEFAULT_BUTTON_SELECTOR);
        this.container &&
            (this.focusTrap = createFocusTrap(this.container, {
                initialFocus: this.defaultButton || undefined,
                escapeDeactivates: false,
                clickOutsideDeactivates: true
            }));
        this.handleDocumentKeydown = this.foundation.handleDocumentKeydown.bind(this.foundation);
        document.addEventListener('keydown', this.handleDocumentKeydown);
    };
    Dialog.prototype.componentWillUnmount = function () {
        _super.prototype.componentWillUnmount.call(this);
        document.removeEventListener('keydown', this.handleDocumentKeydown);
    };
    Dialog.prototype.sync = function (props) {
        if (this.props.open) {
            this.open();
        }
        else {
            this.close();
        }
    };
    Dialog.prototype.getDefaultFoundation = function () {
        var _this = this;
        return new MDCDialogFoundation({
            addClass: function (className) { return _this.root.addClass(className); },
            removeClass: function (className) { return _this.root.removeClass(className); },
            hasClass: function (className) { return _this.root.hasClass(className); },
            addBodyClass: function (className) {
                return document.body && document.body.classList.add(className);
            },
            removeBodyClass: function (className) {
                return document.body && document.body.classList.remove(className);
            },
            eventTargetMatches: function (target, selector) {
                return matches(target, selector);
            },
            trapFocus: function () { return _this.focusTrap && _this.focusTrap.activate(); },
            releaseFocus: function () { return _this.focusTrap && _this.focusTrap.deactivate(); },
            isContentScrollable: function () { return !!_this.content && isScrollable(_this.content); },
            areButtonsStacked: function () { return areTopsMisaligned(_this.buttons); },
            getActionFromEvent: function (evt) {
                var element = closest(evt.target, "[" + MDCDialogFoundation.strings.ACTION_ATTRIBUTE + "]");
                return (element &&
                    element.getAttribute(MDCDialogFoundation.strings.ACTION_ATTRIBUTE));
            },
            clickDefaultButton: function () {
                if (_this.defaultButton) {
                    _this.defaultButton.click();
                }
            },
            reverseButtons: function () {
                _this.buttons && _this.buttons.reverse();
                _this.buttons &&
                    _this.buttons.forEach(function (button) {
                        return button.parentElement && button.parentElement.appendChild(button);
                    });
            },
            notifyOpening: function () {
                _this.emit('onOpen', {});
                _this.props.onStateChange && _this.props.onStateChange('opening');
            },
            notifyOpened: function () {
                _this.emit('onOpened', {});
                _this.props.onStateChange && _this.props.onStateChange('opened');
            },
            notifyClosing: function (action) {
                _this.emit('onClose', action ? { action: action } : {});
                _this.props.onStateChange && _this.props.onStateChange('closing');
            },
            notifyClosed: function (action) {
                _this.emit('onClosed', action ? { action: action } : {});
                _this.props.onStateChange && _this.props.onStateChange('closed');
            }
        });
    };
    Dialog.prototype.handleInteraction = function (evt) {
        evt.type === 'click' && this.props.onClick && this.props.onClick(evt);
        evt.type === 'keydown' && this.props.onKeyDown && this.props.onKeyDown(evt);
        return this.foundation.handleInteraction(evt);
    };
    Dialog.prototype.render = function () {
        var _a = this.props, children = _a.children, open = _a.open, onOpen = _a.onOpen, onClose = _a.onClose, onStateChange = _a.onStateChange, preventOutsideDismiss = _a.preventOutsideDismiss, rest = __rest(_a, ["children", "open", "onOpen", "onClose", "onStateChange", "preventOutsideDismiss"]);
        return (React.createElement(DialogRoot, __assign({}, this.root.props(rest), { ref: this.root.setRef, onClick: this.handleInteraction, onKeyDown: this.handleInteraction }),
            React.createElement("div", { className: "mdc-dialog__container" },
                React.createElement("div", { className: "mdc-dialog__surface" }, children)),
            React.createElement(DialogScrim, { disableInteraction: preventOutsideDismiss })));
    };
    Dialog.displayName = 'Dialog';
    return Dialog;
}(FoundationComponent));
export { Dialog };
/** A SimpleDialog component for ease of use. */
var SimpleDialog = /** @class */ (function (_super) {
    __extends(SimpleDialog, _super);
    function SimpleDialog() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    SimpleDialog.prototype.render = function () {
        var _a = this.props, title = _a.title, header = _a.header, body = _a.body, footer = _a.footer, acceptLabel = _a.acceptLabel, cancelLabel = _a.cancelLabel, children = _a.children, open = _a.open, rest = __rest(_a, ["title", "header", "body", "footer", "acceptLabel", "cancelLabel", "children", "open"]);
        return (React.createElement(Dialog, __assign({ open: open }, rest),
            (!!title || !!header) && (React.createElement(DialogTitle, null,
                !!title && title,
                !!header && header)),
            (!!body || children) && (React.createElement(DialogContent, null,
                body,
                children)),
            (!!cancelLabel || !!acceptLabel || !!footer) && (React.createElement(DialogActions, null,
                !!footer && footer,
                !!cancelLabel && (React.createElement(DialogButton, { action: "close" }, cancelLabel)),
                !!acceptLabel && (React.createElement(DialogButton, { action: "accept", isDefaultAction: true }, acceptLabel))))));
    };
    SimpleDialog.defaultProps = {
        title: undefined,
        header: undefined,
        body: undefined,
        footer: undefined,
        acceptLabel: 'Accept',
        cancelLabel: 'Cancel',
        open: false,
        children: undefined
    };
    return SimpleDialog;
}(React.Component));
export { SimpleDialog };
