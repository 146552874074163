var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) if (e.indexOf(p[i]) < 0)
            t[p[i]] = s[p[i]];
    return t;
};
import * as React from 'react';
import { MDCMenuFoundation } from '@material/menu';
import { List, ListItem } from '@rmwc/list';
import { componentFactory, FoundationComponent, closest } from '@rmwc/base';
import { MenuSurface, MenuSurfaceAnchor } from './menu-surface';
/** A wrapper for menu items */
export var MenuItems = componentFactory({
    displayName: 'MenuItems',
    tag: List,
    classNames: ['mdc-list mdc-menu__items'],
    defaultProps: {
        role: 'menu'
    }
});
/** This is just the ListItem component exported from the Menu module for convenience. You can use `ListItem` or `SimpleListItem` components from the List section as long as you add `role="menuitem"` and `tabIndex="0"` to the components for accessibility. */
export var MenuItem = componentFactory({
    displayName: 'MenuItem',
    tag: ListItem,
    defaultProps: {
        role: 'menuitem',
        tabIndex: 0
    }
});
/** A menu component for displaying lists items. */
var Menu = /** @class */ (function (_super) {
    __extends(Menu, _super);
    function Menu(props) {
        var _this = _super.call(this, props) || this;
        _this.list = null;
        _this.menuSurface = null;
        _this.handleKeydown = _this.handleKeydown.bind(_this);
        _this.handleClick = _this.handleClick.bind(_this);
        _this.handleOpen = _this.handleOpen.bind(_this);
        return _this;
    }
    Object.defineProperty(Menu.prototype, "items", {
        get: function () {
            return this.list ? this.list.listElements : [];
        },
        enumerable: true,
        configurable: true
    });
    Menu.prototype.hoistMenuToBody = function () {
        this.menuSurface && this.menuSurface.hoistMenuToBody();
    };
    Menu.prototype.setAnchorCorner = function (corner) {
        this.menuSurface && this.menuSurface.setAnchorCorner(corner);
    };
    Menu.prototype.setAnchorElement = function (element) {
        this.menuSurface && (this.menuSurface.anchorElement = element);
    };
    Menu.prototype.getDefaultFoundation = function () {
        var _this = this;
        return new MDCMenuFoundation({
            addClassToElementAtIndex: function (index, className) {
                var list = _this.items;
                list[index].classList.add(className);
            },
            removeClassFromElementAtIndex: function (index, className) {
                var list = _this.items;
                list[index].classList.remove(className);
            },
            addAttributeToElementAtIndex: function (index, attr, value) {
                var list = _this.items;
                list[index].setAttribute(attr, value);
            },
            removeAttributeFromElementAtIndex: function (index, attr) {
                var list = _this.items;
                list[index].removeAttribute(attr);
            },
            elementContainsClass: function (element, className) {
                return element.classList.contains(className);
            },
            closeSurface: function () {
                _this.menuSurface && (_this.menuSurface.open = false);
            },
            getElementIndex: function (element) {
                return _this.items.indexOf(element);
            },
            getParentElement: function (element) { return element.parentElement; },
            getSelectedElementIndex: function (selectionGroup) {
                return _this.items.indexOf(selectionGroup.querySelector("." + MDCMenuFoundation.cssClasses.MENU_SELECTED_LIST_ITEM));
            },
            notifySelected: function (evtData) {
                return _this.emit('onSelect', {
                    index: evtData.index,
                    item: _this.items[evtData.index]
                });
            }
        });
    };
    Menu.prototype.handleClick = function (evt) {
        this.props.onClick && this.props.onClick(evt);
        // fixes an issue with nested span element on list items
        var el = closest(evt.target, '.mdc-list-item');
        el && this.foundation.handleItemAction(el);
    };
    Menu.prototype.handleKeydown = function (evt) {
        this.props.onKeyDown && this.props.onKeyDown(evt);
        this.foundation.handleKeydown(evt);
        // Jump through some hoops to find out
        // that we are selecting the list item
        // This is instead of trying to listen to an event on the list item
        // which is what MDC does
        if (evt.which === 13 &&
            evt.target instanceof Element &&
            evt.target.classList.contains(List.cssClasses.LIST_ITEM_CLASS)) {
            this.foundation.handleItemAction(evt.target);
        }
    };
    Menu.prototype.handleOpen = function (evt) {
        this.props.onOpen && this.props.onOpen(evt);
        var list = this.items;
        if (list.length > 0 && !list.some(function (el) { return el === document.activeElement; })) {
            list[0].focus();
        }
    };
    Menu.prototype.render = function () {
        var _this = this;
        var _a = this.props, children = _a.children, rest = __rest(_a, ["children"]);
        return (React.createElement(MenuSurface, __assign({}, rest, { "aria-hidden": !rest.open, className: "mdc-menu " + (rest.className || ''), onKeyDown: this.handleKeydown, onClick: this.handleClick, onOpen: this.handleOpen, ref: function (menuSurfaceApi) {
                return (_this.menuSurface = menuSurfaceApi);
            } }),
            React.createElement(MenuItems, { ref: function (listApi) { return (_this.list = listApi); } }, children)));
    };
    Menu.displayName = 'Menu';
    return Menu;
}(FoundationComponent));
export { Menu };
var simpleMenuFactory = function (MenuComponent) { var _a; return _a = /** @class */ (function (_super) {
        __extends(class_1, _super);
        function class_1() {
            var _this = _super !== null && _super.apply(this, arguments) || this;
            _this.state = {
                open: !!_this.props.open
            };
            return _this;
        }
        class_1.prototype.componentDidMount = function () {
            this.syncWithOpenProp(this.props.open);
        };
        class_1.prototype.componentDidUpdate = function (nextProps) {
            this.syncWithOpenProp(nextProps.open);
        };
        class_1.prototype.syncWithOpenProp = function (open) {
            if (open !== undefined && this.state.open !== open) {
                this.setState({ open: open });
            }
        };
        class_1.prototype.render = function () {
            var _this = this;
            var _a = this.props, handle = _a.handle, onClose = _a.onClose, children = _a.children, _b = _a.rootProps, rootProps = _b === void 0 ? {} : _b, open = _a.open, rest = __rest(_a, ["handle", "onClose", "children", "rootProps", "open"]);
            var wrappedHandle = React.cloneElement(handle, __assign({}, handle.props, { onClick: function (evt) {
                    _this.setState({ open: !_this.state.open });
                    if (handle.props.onClick) {
                        handle.props.onClick(evt);
                    }
                } }));
            var wrappedOnClose = function (evt) {
                _this.setState({ open: !!open || false });
                if (onClose) {
                    onClose(evt);
                }
            };
            return (React.createElement(MenuSurfaceAnchor, __assign({}, rootProps),
                React.createElement(MenuComponent, __assign({}, rest, { onClose: wrappedOnClose, open: this.state.open }), children),
                wrappedHandle));
        };
        return class_1;
    }(React.Component)),
    _a.displayName = 'SimpleMenu',
    _a; };
/** A Simplified menu component that allows you to pass a handle element and will automatically control the open state and add a MenuSurfaceAnchor */
export var SimpleMenu = simpleMenuFactory(Menu);
/** The same as SimpleMenu, but a generic surface. */
export var SimpleMenuSurface = simpleMenuFactory(MenuSurface);
