var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) if (e.indexOf(p[i]) < 0)
            t[p[i]] = s[p[i]];
    return t;
};
import * as React from 'react';
import { componentFactory } from '@rmwc/base';
import { withRipple } from '@rmwc/ripple';
import { Icon } from '@rmwc/icon';
var FabRoot = withRipple({ surface: false })(componentFactory({
    displayName: 'FabRoot',
    tag: 'button',
    classNames: function (props) { return [
        'mdc-fab',
        {
            'mdc-fab--mini': props.mini,
            'mdc-fab--exited': props.exited,
            'mdc-fab--extended': props.label
        }
    ]; },
    defaultProps: {
        cssOnly: false,
        mini: false
    },
    consumeProps: ['mini', 'cssOnly', 'exited', 'label', 'icon']
}));
export var FabIcon = componentFactory({
    displayName: 'FabIcon',
    tag: Icon,
    classNames: ['mdc-fab__icon']
});
var FabLabel = componentFactory({
    displayName: 'FabLabel',
    classNames: ['mdc-fab__label']
});
/** A floating action button component */
export var Fab = React.forwardRef(function (_a, ref) {
    var children = _a.children, label = _a.label, icon = _a.icon, trailingIcon = _a.trailingIcon, rest = __rest(_a, ["children", "label", "icon", "trailingIcon"]);
    if (trailingIcon && !label) {
        console.warn("FAB 'trailingIcon' prop should only be used in conjunction with 'label'");
    }
    return (React.createElement(FabRoot, __assign({ label: label }, rest, { ref: ref }),
        !!icon && React.createElement(FabIcon, { icon: icon }),
        !!label && React.createElement(FabLabel, null, label),
        children,
        !!trailingIcon && React.createElement(FabIcon, { icon: trailingIcon })));
});
Fab.displayName = 'Fab';
